import { Icon, Stack, SvgIcon, SxProps, Theme } from './mui-index';
import Tooltip from './Tooltip';
import { Size, TooltipWrapperProps } from './types';

export type IconBadgeSize = 'extraSmall' | Size;
export interface IconBadgeProps extends TooltipWrapperProps {
  IconComponent: typeof SvgIcon;
  bgcolor: string;
  tooltipPlacement?: 'top' | 'bottom';
  inheritColorTooltip?: boolean;
  badgeDimension?: { width: number; height: number };
  sx?: SxProps<Theme>;
  size?: IconBadgeSize;
  withShadow?: boolean;
}

export const iconSizeProps: Record<IconBadgeSize, any> = {
  extraSmall: {
    size: 20,
    fontSize: 12,
  },
  small: {
    size: 28,
    fontSize: 18,
  },
  medium: {
    size: 40,
    fontSize: 18,
  },
  large: {
    size: 52,
    fontSize: 28,
  },
};

const iconShadow = '0px 7px 12px 0px rgba(0, 0, 0, 0.18), 0px 4px 22px 0px rgba(0, 0, 0, 0.06)';

export default function IconBadge({
  IconComponent,
  bgcolor,
  tooltipContent = '',
  inheritColorTooltip = false,
  tooltipPlacement = 'bottom',
  badgeDimension,
  size = 'medium',
  sx,
  withShadow = false,
}: IconBadgeProps) {
  return (
    <Tooltip
      customColor={inheritColorTooltip ? bgcolor : undefined}
      tooltipContent={tooltipContent}
      placement={tooltipPlacement}
    >
      <Stack
        bgcolor={bgcolor}
        direction="row"
        alignItems="center"
        justifyContent="center"
        width={iconSizeProps[size].size}
        height={iconSizeProps[size].size}
        borderRadius={10}
        sx={sx}
        {...badgeDimension}
        boxShadow={withShadow ? iconShadow : undefined}
      >
        <Icon
          component={IconComponent}
          sx={{ width: iconSizeProps[size].fontSize, height: iconSizeProps[size].fontSize, color: 'text.primary' }}
        />
      </Stack>
    </Tooltip>
  );
}
