import { getCoverageLinesText } from '@common/utils';
import { defaultTo } from 'lodash';
import { useMemo } from 'react';
import { messages } from 'i18n';
import { SubmissionMarketRequest } from 'types';
import { getDeclinationMetadataByType } from 'broker/configuration-mappers/declination-config';
import { informationRequestedConfig } from 'broker/configuration-mappers/information-request-config';
import { MarketingUpdateStatus } from 'broker/enums';
import useSubmissionsWorkspace from 'broker/pages/SubmissionWorkspacePage/store/useSubmissionWorkspace';
import { getCoverageLayerLabel } from 'broker/utils';
import { MarketingUpdateStatusMap } from './utils';

export interface MarketingUpdateItem {
  marketName: string;
  marketingUpdateStatus: MarketingUpdateStatus;
  details: string;
  coverageLines: string;
  additionalInfo: string;
}
const { admitted, nonAdmitted, limit, totalPremium } = messages.marketingUpdate.marketingUpdateItem;

const getQuotedDetails = (marketRequest: SubmissionMarketRequest) => {
  switch (MarketingUpdateStatusMap[marketRequest.status]) {
    case MarketingUpdateStatus.Quoted:
    case MarketingUpdateStatus.Bound:
      if (!marketRequest.insuranceProduct || !marketRequest.quote) {
        return '';
      }
      return `${marketRequest.insuranceProduct.isAdmitted ? admitted : nonAdmitted}, ${getCoverageLayerLabel(
        marketRequest.insuranceProduct.coverageLayer,
      )}${marketRequest.quote.limit ? `, ${limit}: $${marketRequest.quote.limit}` : ''}, ${totalPremium}: $${
        (marketRequest.quote.premium ?? 0) + (marketRequest.quote.triaPremium ?? 0)
      }`;
    default:
      return '';
  }
};

const getCoverageLines = (marketRequest: SubmissionMarketRequest) =>
  getCoverageLinesText(marketRequest.insuranceProduct?.coverageLines.map((item) => item.coverageLine));

const getDeclinationInfo = (marketRequest: SubmissionMarketRequest) =>
  defaultTo(marketRequest.declination?.declinationReasons, [])
    .map(
      (reason) => `${getDeclinationMetadataByType(reason.type)?.reason}${reason.details ? `: ${reason.details}` : ''}`,
    )
    .join(', ');

const getInformationRequestedInfo = (marketRequest: SubmissionMarketRequest) => {
  const informationRequestsConfig = informationRequestedConfig(marketRequest);
  return marketRequest.informationRequested
    .map((request) =>
      request.type === 'Other'
        ? request.details
        : `${informationRequestsConfig[request.type]}${request.details ? `: ${request.details}` : ''}`,
    )
    .join(', ');
};

const getAdditionalInfo = (marketRequest: SubmissionMarketRequest) => {
  switch (MarketingUpdateStatusMap[marketRequest.status]) {
    case MarketingUpdateStatus.Declined:
      return getDeclinationInfo(marketRequest);
    case MarketingUpdateStatus.InReview:
      return getInformationRequestedInfo(marketRequest);
    default:
      return '';
  }
};

export const useGetMarketingUpdateItems = () => {
  const { markets } = useSubmissionsWorkspace();

  const marketingUpdateItems = useMemo(() => {
    const items: MarketingUpdateItem[] = [];

    markets.forEach((market) => {
      market.marketRequests.forEach((marketRequest) => {
        items.push({
          marketName: market.marketName,
          marketingUpdateStatus: MarketingUpdateStatusMap[marketRequest.status],
          details: getQuotedDetails(marketRequest),
          coverageLines: getCoverageLines(marketRequest),
          additionalInfo: getAdditionalInfo(marketRequest),
        });
      });
    });

    return items.reduce<Record<MarketingUpdateStatus, MarketingUpdateItem[]>>((acc, item) => {
      if (!acc[item.marketingUpdateStatus]) {
        acc[item.marketingUpdateStatus] = [];
      }
      acc[item.marketingUpdateStatus].push(item);
      return acc;
    }, {} as Record<MarketingUpdateStatus, MarketingUpdateItem[]>);
  }, [markets]);

  return marketingUpdateItems;
};
