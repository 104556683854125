import { getCoverageLineConfig } from '@common/config';
import { Highlight, Stack, Typography } from '@common-components';
import { CoverageLine } from 'enums';

interface QuoteCoverageLinesProps {
  coverageLines: CoverageLine[];
  searchInput?: string;
}

export function QuoteCoverageLines({ coverageLines, searchInput = '' }: QuoteCoverageLinesProps) {
  return (
    <Stack alignItems="flex-start" textAlign="left" gap={0.5}>
      {coverageLines.map((value) => (
        <Typography key={value} variant="caption">
          <Highlight searchWords={[searchInput]} textToHighlight={getCoverageLineConfig(value).text} />
        </Typography>
      ))}
    </Stack>
  );
}
