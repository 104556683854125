import { ExtendedMarketRequest, PartialSubmission, SubmissionMarket } from 'types';

export function submissionMarketsToExtendedMarketRequests(
  submissionMarkets: SubmissionMarket[],
  submission?: PartialSubmission | null,
): ExtendedMarketRequest[] {
  return submissionMarkets
    .map((market) =>
      market.marketRequests.map((marketRequest) => ({
        ...marketRequest,
        market,
        submissionCoverageLines: submission?.coverageLines,
      })),
    )
    .flat();
}
