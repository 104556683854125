import { getCoverageLineConfig } from '@common/config';
import { intersectionBy, isEmpty, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { messages } from 'i18n';
import { SelectProduct } from 'types';
import FormAutocomplete from 'components/hookFormComponents/FormAutocomplete/FormAutocomplete';
import { AutocompleteOption } from 'components/hookFormComponents/FormAutocomplete/utils';
import { detailsFormFieldsConfig } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/config/detailsFieldsConfig';
import { DetailsFieldsNames } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/Quote/form-methods/enums';
import { getCoverageLayerLabel } from 'broker/utils';

interface ProductSelectProps {
  initialProductId?: string;
  selectProducts: SelectProduct[];
  isLoading: boolean;
  isDisabled: boolean;
}

type SelectProductOption = AutocompleteOption & { inSubmission: boolean };

const addQuoteMessages = messages.addQuotePage;

export default function ProductSelect({ initialProductId, selectProducts, isLoading, isDisabled }: ProductSelectProps) {
  const fieldName = detailsFormFieldsConfig[DetailsFieldsNames.InsuranceProductId].name;
  const { setValue } = useFormContext();

  const getSelectProductCategory = useCallback(
    (selectProduct: SelectProductOption) =>
      selectProduct.inSubmission
        ? addQuoteMessages.selectProductGroups.inSubmission
        : addQuoteMessages.selectMarketGroups.notInSubmission,
    [],
  );

  const getProductLabel = (selectProduct: SelectProduct) =>
    `${selectProduct.marketName} ${selectProduct.label ? `, ${selectProduct.label}` : ''}`;

  const getProductSubLabel = (selectProduct: SelectProduct) => {
    const productCoverageLines = selectProduct.coverageLines
      .map((productCoverageLine) => getCoverageLineConfig(productCoverageLine.coverageLine).text)
      .join(', ');
    const coverageLayerLabel = getCoverageLayerLabel(selectProduct.coverageLayer);
    const isAdmittedLabel = selectProduct.isAdmitted ? addQuoteMessages.admitted : addQuoteMessages.nonAdmitted;
    return `${
      !isEmpty(productCoverageLines) ? `${productCoverageLines} | ` : ''
    } ${coverageLayerLabel} | ${isAdmittedLabel}`;
  };

  useEffect(() => {
    if (initialProductId) {
      setValue(fieldName, initialProductId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProductId]);

  const productOptions = useMemo(() => {
    const initialProduct = initialProductId
      ? selectProducts.find((product) => product.id === initialProductId)
      : undefined;

    return sortBy(
      selectProducts
        // If there is already a selected product, show only products from the same market and that share coverage lines
        .filter(
          (product) =>
            !initialProduct ||
            (product.marketId === initialProduct.marketId &&
              intersectionBy(initialProduct.coverageLines, product.coverageLines, 'coverageLine').length > 0),
        )
        .map((selectProduct) => ({
          label: getProductLabel(selectProduct),
          subLabel: getProductSubLabel(selectProduct),
          value: selectProduct.id,
          inSubmission: selectProduct.inSubmission,
        })),
      [(product) => !product.inSubmission, 'label'],
    );
  }, [selectProducts, initialProductId]);

  return (
    <FormAutocomplete
      isLoading={isLoading}
      disabled={isDisabled}
      options={productOptions}
      groupBy={getSelectProductCategory}
      autoSelect={false}
      {...detailsFormFieldsConfig[DetailsFieldsNames.InsuranceProductId]}
    />
  );
}
