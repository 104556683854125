import { SubmissionStatus } from 'enums';
import { messages } from 'i18n';
import theme from 'themes';
import { SubmissionViewMode } from 'broker/components/SubmissionsView/utils';

export interface SubmissionStatusProps {
  color?: string;
  bgColor: string;
  borderColor?: string;
  text: (viewMode: SubmissionViewMode) => string;
  subtitle?: (isDisabled: boolean) => string;
  subtitleColor?: string;
  sortPriority: number;
  preflight: boolean;
  inProgressStatus?: boolean;
}

const statusMessages = messages.submission.status;

export const submissionStatusConfig: Record<SubmissionStatus, SubmissionStatusProps> = {
  [SubmissionStatus.Review]: {
    bgColor: theme.palette.grey[100],
    text: () => statusMessages.review,
    sortPriority: 2,
    preflight: true,
  },
  [SubmissionStatus.New]: {
    bgColor: theme.palette.indigo[200],
    text: () => statusMessages.new,
    sortPriority: 3,
    preflight: false,
  },
  [SubmissionStatus.Quoting]: {
    bgColor: theme.palette.lilac[200],
    text: () => statusMessages.quote,
    sortPriority: 4,
    preflight: false,
  },
  [SubmissionStatus.PendingClient]: {
    bgColor: theme.palette.peachOrange[100],
    text: (viewMode) =>
      viewMode === SubmissionViewMode.Retailer ? statusMessages.missingInformation : statusMessages.pendingClient,
    sortPriority: 5,
    preflight: false,
  },
  [SubmissionStatus.Quoted]: {
    bgColor: theme.palette.aquaGreen[100],
    text: () => statusMessages.quoted,
    sortPriority: 6,
    preflight: false,
  },
  [SubmissionStatus.Binding]: {
    bgColor: theme.palette.turquoise[100],
    text: () => statusMessages.binding,
    sortPriority: 7,
    preflight: false,
  },
  [SubmissionStatus.Issuing]: {
    bgColor: theme.palette.cornflowerBlue[100],
    text: () => statusMessages.issuing,
    sortPriority: 8,
    preflight: false,
  },
  [SubmissionStatus.Issued]: {
    bgColor: theme.palette.teal[200],
    text: () => statusMessages.issued,
    sortPriority: 9,
    preflight: false,
  },
  [SubmissionStatus.Closed]: {
    bgColor: theme.palette.coralRed[100],
    text: () => statusMessages.closed,
    sortPriority: 10,
    preflight: false,
  },
  [SubmissionStatus.Archived]: {
    bgColor: theme.palette.coralRed[100],
    text: () => statusMessages.archived,
    sortPriority: 11,
    preflight: false,
  },
  // This status is not really used in the UI, but it's here for completeness
  [SubmissionStatus.Processing]: {
    bgColor: theme.palette.grey[100],
    text: () => statusMessages.processing,
    sortPriority: 100,
    preflight: true,
    inProgressStatus: true,
    subtitle: (isDisabled) => (isDisabled ? '' : messages.submission.reviewSubtitle),
    subtitleColor: 'text.secondary',
  },
};

export const visibleSubmissionStatuses = Object.keys(SubmissionStatus)
  .map((statusName) => statusName as SubmissionStatus)
  .filter((status) => !submissionStatusConfig[status].inProgressStatus)
  .sort((a, b) => submissionStatusConfig[a].sortPriority - submissionStatusConfig[b].sortPriority);
