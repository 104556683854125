import { getCoverageLinesText } from '@common/utils';
import { Box, Divider, Stack, Typography } from '@common-components';
import { AirplanemodeActive } from '@icons';
import { useCurrentUser } from 'hooks';
import { messages } from 'i18n';
import { CopilotSteps, PartialSubmission, SubmissionMarket, UserDetails } from 'types';
import { DialogContent, DialogFooter, DialogHeader } from 'components/Dialog';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import { RouteKey } from 'broker/enums';
import { useNavigateToStep } from 'broker/hooks';
import AutonomousMarketsForm from './AdvancedContent/collapseContent/marketing/AutonomousMarketsForm';
import Disclaimer from './Disclaimer';
import { StepsRoutes } from './types';
import { useAutonomousMarketInfo } from './useAutonomousMarketInfo';

export interface BasicContentProps extends BaseRoutedDialogContentProps {
  activeStep: StepsRoutes;
  submission: PartialSubmission;
  submissionMarkets: SubmissionMarket[];
  selectedUser?: UserDetails;
  submitForm(): Promise<void>;
  value: number;
  setValue: (value: number) => void;
  isAutonomousFlowInProgress: boolean;
}

export default function BasicContent({
  onClose,
  ariaLabelId,
  selectedUser,
  submission,
  submissionMarkets,
  submitForm,
  isAutonomousFlowInProgress,
}: BasicContentProps) {
  const navigateToStep = useNavigateToStep();

  const { endUser } = useCurrentUser();

  const activeSteps = endUser?.organization.copilotSettings?.activeSteps;

  const modalMessages = messages.autonomousFlow.settingsModal;
  const stepsMessages = modalMessages.steps;
  const initSubmissionMarkets = useAutonomousMarketInfo(submissionMarkets);

  return (
    <>
      <DialogHeader
        id={ariaLabelId}
        onClose={onClose}
        title={
          submission.insuredName && submission.coverageLines
            ? modalMessages.basic.title(submission.insuredName, getCoverageLinesText(submission?.coverageLines))
            : ''
        }
      />
      <DialogContent disablePaddings>
        <Disclaimer />
        <Stack divider={<Divider sx={{ mb: 1 }} />} gap={2} p={2}>
          {activeSteps?.includes(CopilotSteps.PreMarketing) && (
            <Box>
              <Typography variant="subtitle2">{stepsMessages.preMarketing.title}</Typography>
              <Typography variant="caption" color="typography.secondary">
                {stepsMessages.preMarketing.subTitle}
              </Typography>
            </Box>
          )}
          {activeSteps?.includes(CopilotSteps.Marketing) && (
            <Box>
              <Typography variant="subtitle2">{stepsMessages.marketing.title}</Typography>
              <Typography variant="caption" color="typography.secondary">
                {stepsMessages.marketing.subTitle1}
              </Typography>
              <br />
              <br />
              <Stack bgcolor="blueGrey.50" p={1} borderRadius={1} gap={1}>
                <Typography variant="h6" color="typography.secondary">
                  {stepsMessages.marketing.title2}
                </Typography>
                <Typography variant="caption" color="typography.secondary">
                  {stepsMessages.marketing.form.title}
                </Typography>
                <Box mb={5}>
                  <AutonomousMarketsForm submission={submission} initSubmissionMarkets={initSubmissionMarkets} active />
                </Box>
              </Stack>
            </Box>
          )}
          {activeSteps?.includes(CopilotSteps.Proposal) && (
            <Box>
              <Typography variant="subtitle2">{stepsMessages.proposal.title}</Typography>
              <Typography variant="caption" color="typography.secondary">
                {stepsMessages.proposal.subTitle(`${selectedUser?.firstName || ''} ${selectedUser?.lastName || ''}`)}
              </Typography>
            </Box>
          )}
          {activeSteps?.includes(CopilotSteps.BindersAndPolicies) && (
            <Box>
              <Typography variant="subtitle2">{stepsMessages.binding.title}</Typography>
              <Typography variant="caption" color="typography.secondary">
                {stepsMessages.binding.subTitle}
              </Typography>
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogFooter
        proceedButton={{
          children: modalMessages.confirmButton,
          startIcon: <AirplanemodeActive />,
          onClick: submitForm,
          loading: isAutonomousFlowInProgress,
        }}
        secondaryButton={{
          children: modalMessages.viewButton,
          startIcon: <AirplanemodeActive />,
          onClick: () => {
            navigateToStep(`../${StepsRoutes.Advanced}`, {
              routeKey: RouteKey.SubmissionAutonomousCreation,
              state: undefined,
              replace: true,
            });
          },
        }}
        cancelButton={{
          children: messages.buttons.cancel,
          onClick: onClose,
          variant: 'text',
        }}
      />
    </>
  );
}
