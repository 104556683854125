// eslint-disable-next-line no-restricted-imports
import { IconButton as MuiIconButton } from '@mui/material';
import { forwardRef, MouseEvent } from 'react';
// eslint-disable-next-line import/no-internal-modules
import { useHotjar } from 'hooks/useHotjar';
import { HotjarEvents } from 'utils/hotjar-events';
import { Badge, CircularProgress, SvgIcon } from './mui-index';
import Tooltip from './Tooltip';
import { Color, Size, TooltipWrapperProps, Variant } from './types';

export interface IconButtonProps extends TooltipWrapperProps, IconProps {
  /** The icon to display in the button */
  icon: typeof SvgIcon;
  /** The variant to use */
  variant?: Exclude<Variant, 'outlined'>;
  /** The size of the button */
  size?: Size;
  /** The placement of the button */
  edge?: 'start' | 'end';
  /** The color of the button */
  color?: Color | 'inherit';
  /** The function to call when the button is clicked */
  onClick?: ((event?: MouseEvent<HTMLButtonElement>) => void) | ((event: MouseEvent<HTMLButtonElement>) => void);
  /** Whether the button is disabled */
  disabled?: boolean;
  /** Whether the button is active */
  active?: boolean;
  /** The hotjar event to report when the button is clicked */
  hotjarEvent?: HotjarEvents;
  /** Whether to show indicator on the button */
  indicator?: boolean;
  /** The color of the indicator */
  indicatorColor?: Color;
  /** Whether the button is loading */
  loading?: boolean;
  /** Custom background color */
  bgcolor?: string;
}

export interface IconProps {
  /** Rotation angle of the icon  */
  iconRotation?: number;
}

const variantColorProps: Record<Exclude<Variant, 'outlined'>, Record<Color | 'inherit', any>> = {
  contained: {
    inherit: {},
    primary: {
      color: 'common.white',
      bgcolor: 'primary.main',
      '&.active': {
        color: 'common.white',
        bgcolor: 'blue.500',
      },
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        color: 'common.white',
        bgcolor: 'blue.700',
      },
    },
    secondary: {
      color: 'text.primary',
      bgcolor: 'blueGrey.100',
      '&.active': {
        color: 'primary.main',
        bgcolor: 'blueGrey.100',
      },
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        color: 'text.primary',
        bgcolor: 'blueGrey.300',
      },
    },
    info: {
      color: 'common.white',
      bgcolor: 'purple.700',
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'blueGrey.200',
      },
      '&:hover': {
        bgcolor: 'purple.800',
      },
    },
    error: {},
    success: {},
    warning: {},
  },
  text: {
    inherit: {},
    primary: {
      '&.active': {
        color: 'blue.500',
      },
    },
    secondary: {
      color: 'text.primary',
      '&.active': {
        color: 'primary.main',
      },
      '&[disabled]': {
        color: 'text.disabled',
        bgcolor: 'transparent',
      },
      '&:hover': {
        color: 'text.primary',
        bgcolor: 'blueGrey.300',
      },
    },
    error: {},
    info: {},
    success: {},
    warning: {},
  },
};

export const buttonSizeProps: Record<Size, any> = {
  small: {
    p: '7px',
  },
  medium: {
    p: '12px',
  },
  large: {
    p: '16px',
  },
};

export const iconSizeProps: Record<Size, any> = {
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 16,
  },
  large: {
    fontSize: 20,
  },
};

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      variant = 'contained',
      color = 'primary',
      size = 'medium',
      disabled = false,
      active = false,
      tooltipDarkMode = false,
      indicator = false,
      edge,
      icon,
      onClick,
      tooltipContent,
      tooltipPlacement = 'top',
      disabledTooltipContent,
      isTooltipOpen,
      iconRotation,
      indicatorColor = 'success',
      disableTooltipPadding = false,
      loading = false,
      bgcolor,
      hotjarEvent,
      tooltipCustomColor,
      ...props
    },
    ref,
  ) => {
    const tooltipContentToUse = disabled ? disabledTooltipContent : tooltipContent;
    const activeToUse = disabled ? false : active;
    const Icon = icon;
    const isDisabled = disabled || loading;

    const { event: reportHotjarEvent } = useHotjar();

    function handleClick(clickEvent: MouseEvent<HTMLButtonElement>) {
      if (hotjarEvent) {
        reportHotjarEvent(hotjarEvent);
      }

      onClick?.(clickEvent);
    }

    return (
      <Tooltip
        customColor={tooltipCustomColor}
        tooltipContent={tooltipContentToUse ?? ''}
        placement={tooltipPlacement}
        arrow
        isOpen={isTooltipOpen}
        darkMode={tooltipDarkMode}
        disablePadding={disableTooltipPadding}
        {...props}
      >
        <Badge color={indicatorColor} variant="dot" overlap="circular" invisible={!indicator}>
          <MuiIconButton
            {...(activeToUse && { className: 'active' })}
            disabled={isDisabled}
            size={size}
            color={color}
            edge={edge}
            onClick={handleClick}
            ref={ref}
            sx={{
              ...variantColorProps[variant][color],
              ...buttonSizeProps[size],
              ...(bgcolor && {
                bgcolor,
                '&:hover': {
                  filter: 'brightness(0.9)',
                  bgcolor,
                },
              }),
              transition: (theme) =>
                theme.transitions.create(['background-color', 'color'], {
                  duration: theme.transitions.duration.short,
                }),
            }}
          >
            {loading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <Icon
                color={active ? 'inherit' : 'disabled'}
                sx={{
                  ...iconSizeProps[size],
                  color: 'inherit',
                  transform: `rotate(${iconRotation}turn)`,
                  transition: (theme) =>
                    theme.transitions.create(['rotation'], {
                      duration: theme.transitions.duration.short,
                    }),
                }}
              />
            )}
          </MuiIconButton>
        </Badge>
      </Tooltip>
    );
  },
);

export default IconButton;
