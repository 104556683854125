// Regular expression to validate domain
const domainRegex = /^(?!:\/\/)(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9_-]+(?:\.[a-zA-Z]+)+(?::\d+)?(?:\/\S*)?$/;

export function isValidDomain(value: string) {
    // Check if the value matches the domain regex
    return domainRegex.test(value);
}

// Regular expression to validate email
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export function isValidEmail(value: string) {
    // Check if the value matches the domain regex
    return emailRegex.test(value);
}
