import moment from 'moment';

export const DisplayDateFormat = 'MM/DD/YYYY';
export const DisplayYearFormat = 'YYYY';
export const DisplayDateFormatAlt = 'DD-MM-YYYY';
export const DisplayDateWithHoursFormat = 'MM/DD/YYYY hh:mm A';
export const DisplayHoursFormat = 'hh:mm A';
export const DisplayMonthAndDay = 'MMM Do';

// This function should only be used to format dates that are not timezone specific
// as they don't have a time component, only date. E.g. a date that was selected from a date picker
// Users in different timezones will see the same date
export function formatDateWithoutTime(date: string | Date, format: string): string {
    return moment.utc(date).format(format);
}

export function convertDateToCalenderFormat(date: string | Date): string {
    return formatDateWithoutTime(date, 'YYYY-MM-DD');
}

export function getTimezoneLessDisplayDate(date: string | Date): string {
    return formatDateWithoutTime(date, DisplayDateFormat);
}

export function parseTimezoneLessDate(
    date: string,
    format: string = DisplayDateFormat,
    fallbackToUnknownFormat = true,
): Date | undefined {
    let momentObj = moment.utc(date, format);
    if (momentObj.isValid()) {
        return momentObj.toDate();
    }
    if (fallbackToUnknownFormat) {
        momentObj = moment.utc(date);
        if (momentObj.isValid()) {
            return momentObj.toDate();
        }
    }
    return undefined;
}
