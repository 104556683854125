// enum for prompt result feature related to submission extraction: has its own enum as it also has a specific config
export enum SubmissionExtractionPromptResultFeature {
    SubmissionInsuredName = 'SubmissionInsuredName',
    SubmissionCoverageLines = 'SubmissionCoverageLines',
    SubmissionApplicantPrimaryAddress = 'SubmissionApplicantPrimaryAddress',
    SubmissionApplicantLocation = 'SubmissionApplicantLocation',
    SubmissionApplicantMailingAddress = 'SubmissionApplicantMailingAddress',
    SubmissionTotalPayroll = 'SubmissionTotalPayroll',
    SubmissionTotalAnnualRevenue = 'SubmissionTotalAnnualRevenue',
    SubmissionTotalAssets = 'SubmissionTotalAssets',
    SubmissionYearsOfOperation = 'SubmissionYearsOfOperation',
    SubmissionLegalEntity = 'SubmissionLegalEntity',
    SubmissionApplicationDomainName = 'SubmissionApplicationDomainName',
    SubmissionIndustryClassification = 'SubmissionIndustryClassification',
    SubmissionOrganizationsCorporateStructure = 'SubmissionOrganizationsCorporateStructure',
    SubmissionAttorneyNumber = 'SubmissionAttorneyNumber',
    SubmissionPhoneNumber = 'SubmissionPhoneNumber',
    SubmissionPartTimeEmployeesNumber = 'SubmissionPartTimeEmployeesNumber',
    SubmissionFullTimeEmployeesNumber = 'SubmissionFullTimeEmployeesNumber',
    SubmissionEmployeesNumber = 'SubmissionEmployeesNumber',
    SubmissionHasDomain = 'SubmissionHasDomain',
    SubmissionPlEffectiveDate = 'SubmissionPlEffectiveDate',
    SubmissionGlEffectiveDate = 'SubmissionGlEffectiveDate',
    SubmissionCyberEffectiveDate = 'SubmissionCyberEffectiveDate',
    SubmissionMlEffectiveDate = 'SubmissionMlEffectiveDate',
    SubmissionEffectiveDate = 'SubmissionEffectiveDate',
    SubmissionNeedByDate = 'SubmissionNeedByDate',
    SubmissionCyberLossRunIndicator = 'SubmissionCyberLossRunIndicator',
    SubmissionPLLossRunIndicator = 'SubmissionPLLossRunIndicator',
    SubmissionDataApplicantContactName = 'SubmissionDataApplicantContactName',
    SubmissionDataApplicantEmailAddress = 'SubmissionDataApplicantEmailAddress',
    SubmissionContactAgreedToInformationSecurity = 'SubmissionContactAgreedToInformationSecurity',
    SubmissionPubliclyTradedSecurities = 'SubmissionPubliclyTradedSecurities',
    SubmissionPrivatePlacementInvestment = 'SubmissionPrivatePlacementInvestment',
    SubmissionShareholderBoardRepresentation = 'SubmissionShareholderBoardRepresentation',
    SubmissionDebtCovenantBreach = 'SubmissionDebtCovenantBreach',
    SubmissionSecurityBreachNotification = 'SubmissionSecurityBreachNotification',
    SubmissionPendingLitigation = 'SubmissionPendingLitigation',
    SubmissionUnscheduledDowntimeLoss = 'SubmissionUnscheduledDowntimeLoss',
    SubmissionOwnershipType = 'SubmissionOwnershipType',
    SubmissionSensitiveCloudStorage = 'SubmissionSensitiveCloudStorage',
    SubmissionInfoSecTraining = 'SubmissionInfoSecTraining',
    SubmissionFranchiseStatus = 'SubmissionFranchiseStatus',
    SubmissionBusinessBrokerServices = 'SubmissionBusinessBrokerServices',
    SubmissionCapitalRaisingServices = 'SubmissionCapitalRaisingServices',
    SubmissionMergerAcquisitionServices = 'SubmissionMergerAcquisitionServices',
    SubmissionBackupSecurityMeasures = 'SubmissionBackupSecurityMeasures',
    SubmissionThirdPartySecurityAgreements = 'SubmissionThirdPartySecurityAgreements',
    SubmissionFailoverTesting = 'SubmissionFailoverTesting',
    SubmissionSystemSegregation = 'SubmissionSystemSegregation',
    SubmissionEncryption = 'SubmissionEncryption', // instead of "SubmissionDataEncryption"
    SubmissionUpdateFrequency = 'SubmissionUpdateFrequency',
    SubmissionIncidentResponsePlan = 'SubmissionIncidentResponsePlan',
    SubmissionSecurityTrainingImplementation = 'SubmissionSecurityTrainingImplementation',
    SubmissionNewHireBackgroundChecks = 'SubmissionNewHireBackgroundChecks',
    SubmissionBankAccountReconciliation = 'SubmissionBankAccountReconciliation',
    SubmissionFinancialControlSegregation = 'SubmissionFinancialControlSegregation',
    SubmissionPaymentInstructionVerificationPolicy = 'SubmissionPaymentInstructionVerificationPolicy',
    SubmissionInventoryPhysicalCount = 'SubmissionInventoryPhysicalCount',
    SubmissionHighValueInventoryExposure = 'SubmissionHighValueInventoryExposure',
    SubmissionCheckCountersignatureRequirement = 'SubmissionCheckCountersignatureRequirement',
    SubmissionDomesticLocations = 'SubmissionDomesticLocations',
    SubmissionBankingTransactionSeparation = 'SubmissionBankingTransactionSeparation',
    SubmissionExpectsDebtSoon = 'SubmissionExpectsDebtSoon',
    SubmissionNetIncome = 'SubmissionNetIncome',
    SubmissionCurrentAssets = 'SubmissionCurrentAssets',
    SubmissionEarningsBeforeInterestTaxes = 'SubmissionEarningsBeforeInterestTaxes',
    SubmissionCashOrCashEquivalentAssets = 'SubmissionCashOrCashEquivalentAssets',
    SubmissionCashFlowFromOperations = 'SubmissionCashFlowFromOperations',
    SubmissionCurrentLiabilities = 'SubmissionCurrentLiabilities',
    SubmissionLongTermDebt = 'SubmissionLongTermDebt',
    SubmissionAmountOfExpectedDebt = 'SubmissionAmountOfExpectedDebt',
    SubmissionPercentageSharesHeldByBoard = 'SubmissionPercentageSharesHeldByBoard',
    SubmissionHalfLongTermDebtMatures = 'SubmissionHalfLongTermDebtMatures',
    SubmissionTotalLiabilities = 'SubmissionTotalLiabilities',
    SubmissionTotalEntitiesOwnShares = 'SubmissionTotalEntitiesOwnShares',
    SubmissionPercentageHeldByTrusts = 'SubmissionPercentageHeldByTrusts',
    SubmissionEntityType = 'SubmissionEntityType',
    SubmissionYearsIndustryManagement = 'SubmissionYearsIndustryManagement',
    SubmissionTotalCashExposureOutside = 'SubmissionTotalCashExposureOutside',
    SubmissionTotalCashExposureInside = 'SubmissionTotalCashExposureInside',
}

export enum GeneralPromptResultFeature {
    QuoteInsight = 'QuoteInsight',
    AcknowledgmentEmail = 'AcknowledgmentEmail',
    QuotePremium = 'QuotePremium',
    QuoteExpiresAt = 'QuoteExpiresAt',
    QuoteTriaPremium = 'QuoteTriaPremium',
    QuoteAggregateLimit = 'QuoteAggregateLimit',
    PolicyExpirationDate = 'PolicyExpirationDate',
    QuoteCarrierFees = 'QuoteCarrierFees',
    RiskAssessment = 'RiskAssessment',
    MarketingUpdateEmail = 'MarketingUpdateEmail',
    FileClassification = 'FileClassification',
    MarketingEmail = 'MarketingEmail',
}

// usage for the enum as value
export const PromptResultFeature = {
    ...GeneralPromptResultFeature,
    ...SubmissionExtractionPromptResultFeature,
} as const;

// usage for the type of the merged enum
export type PromptResultFeatureType = (typeof PromptResultFeature)[keyof typeof PromptResultFeature];
