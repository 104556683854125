import { createContext, ReactNode, useContext, useMemo } from 'react';
import { EventEmitter } from 'utils/event-emitter';

export interface AppEvent {
  event: string;
  data: any;
}

export const eventEmitter = new EventEmitter<AppEvent>();

interface EventContextType {
  eventEmitter: EventEmitter<AppEvent>;
}

const AppPubSub = createContext<EventContextType | undefined>(undefined);

export const AppPubSubProvider = ({ children }: { children: ReactNode }) => {
  const eventContextValue = useMemo(() => ({ eventEmitter }), []);

  return <AppPubSub.Provider value={eventContextValue}>{children}</AppPubSub.Provider>;
};

export const useAppPubSub = () => {
  const context = useContext(AppPubSub);
  if (!context) {
    throw new Error('useAppPubSub must be used within an AppPubSubProvider');
  }
  return context.eventEmitter;
};
