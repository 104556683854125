import { messages } from 'i18n';
import FormFieldOverlay from './FormFieldOverlay';

interface FormFieldSuggestionProps {
  text: string;
  onClick: () => void;
  tooltipText?: string;
}

export default function FormFieldSuggestionOverlay({ text, onClick, tooltipText }: FormFieldSuggestionProps) {
  return (
    <FormFieldOverlay
      text={text}
      onClick={onClick}
      bgColor="green.50"
      labelBgColor="green.50"
      labelText={messages.overlayChip.suggested}
      labelTextColor="green.600"
      tooltipText={tooltipText}
      edge="end"
    />
  );
}
