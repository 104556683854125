import { isNil } from 'lodash';
import { ChangeEventHandler, FocusEvent, memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormFieldRequiredLabel,
  FormHelperText,
  OutlinedInput,
  outlinedInputClasses,
  OutlinedInputProps,
} from '@common-components';
import { messages } from 'i18n';
import theme, { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { formatInputNumber, normalizeError } from 'utils';
import DefaultOverlay from 'components/DefaultOverlay';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import CustomMoneyInput, { CustomMoneyInputProps } from './CustomMoneyInput';
import FormFieldValidationHelper from './FormFieldValidationHelper';
import InputLabel from './InputLabel';
import { FormHelperTextStyles } from './styles';
import { SuggestionProps, SuggestionValidation } from './types';

export interface FormTextFieldProps extends Omit<OutlinedInputProps, 'name' | 'onBlur'> {
  name: string;
  id: string;
  label?: string;
  optional?: boolean;
  isNumberFormat?: boolean;
  numberFormatProps?: Omit<CustomMoneyInputProps, 'name' | 'onChange'>;
  hideOptionalLabel?: boolean;
  hideBorder?: boolean;
  paddingBottom?: number;
  suggestion?: SuggestionProps;
  onBlur?: (e: FocusEvent<HTMLElement>) => boolean | undefined;
  helperText?: string;
  defaultValueOverlayText?: string;
  suggestionValidation?: SuggestionValidation;
  enhancedRequired?: boolean;
  stateOverride?: {
    value: string;
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  };
}

const FormTextField = memo(
  ({
    name,
    id,
    label = '',
    isNumberFormat,
    optional,
    numberFormatProps,
    hideOptionalLabel,
    hideBorder,
    inputProps,
    paddingBottom,
    suggestion,
    onBlur,
    helperText,
    defaultValueOverlayText,
    size = 'small',
    sx,
    disabled,
    enhancedRequired,
    stateOverride,
    suggestionValidation,
    ...props
  }: FormTextFieldProps) => {
    const {
      control,
      formState: { errors },
      // trigger,
    } = useFormContext();
    const errorMessage = normalizeError(errors, name);
    const formHelperText = errorMessage || helperText;
    const [ownSuggestion, setOwnSuggestion] = useState<SuggestionProps | undefined>(suggestion);
    const [ownDefaultOverlayText, setOwnDefaultOverlayText] = useState<string | undefined>(defaultValueOverlayText);

    useEffect(() => {
      setOwnSuggestion(suggestion);
    }, [suggestion]);

    const resetOwnSuggestion = () => {
      setOwnSuggestion(undefined);
      suggestionValidation?.suggestionValidationCallback?.();
    };

    const formattedSuggestion =
      isNumberFormat && numberFormatProps?.thousandSeparator !== false && ownSuggestion && !isNil(ownSuggestion?.value)
        ? formatInputNumber(ownSuggestion.value, numberFormatProps)
        : ownSuggestion?.value;

    return (
      <FormControl
        fullWidth
        sx={{
          ...(props.type === 'hidden' && { display: 'none' }),
          pb: paddingBottom ?? FormControlPaddingBottom,
          mb: formHelperText ? FormControlHelperTextMarginBottom : 0,
          height: 1,
          justifyContent: 'flex-start',
          '& .Mui-disabled': {
            color: 'inherit',
            WebkitTextFillColor: `${theme.palette.common.black} !important`,
            backgroundColor: theme.palette.grey[100],
          },
        }}
      >
        {label && (
          <InputLabel
            showSuggestionValidation={suggestionValidation?.showSuggestionValidation && !!ownSuggestion}
            suggestionValidationError={suggestionValidation?.suggestionValidationError}
            label={label}
            error={!!errorMessage}
            optional={optional}
            hideOptionalLabel={hideOptionalLabel}
            htmlFor={name}
          />
        )}
        <Controller
          defaultValue=""
          control={control}
          name={name}
          render={({ field }) => (
            <Box position="relative">
              <OutlinedInput
                size={size}
                sx={{
                  typography: 'body2',
                  border: 'none',
                  width: 1,
                  [`& .${outlinedInputClasses.disabled}`]: {
                    color: 'inherit',
                    WebkitTextFillColor: `${theme.palette.common.black} !important`,
                    backgroundColor: theme.palette.grey[100],
                  },
                  [`& .${outlinedInputClasses.notchedOutline}`]: {
                    ...(hideBorder && { border: 'none' }),
                  },
                  [`& .${outlinedInputClasses.input}`]: {
                    ...(hideBorder && { pr: 1 }),
                  },
                  ...sx,
                }}
                error={!!errorMessage}
                placeholder={messages.general.inputPlaceholder}
                inputComponent={isNumberFormat ? (CustomMoneyInput as any) : 'input'}
                inputProps={{ ...inputProps, ...numberFormatProps, name, id }}
                disabled={disabled}
                {...props}
                {...field}
                value={stateOverride ? stateOverride.value : field.value ?? ''}
                onChange={stateOverride?.onChange || field.onChange}
                onBlur={(e) => {
                  let allowPropagation: boolean = true;
                  if (onBlur) {
                    const response = onBlur(e);
                    if (response !== undefined) {
                      allowPropagation = response;
                    }
                  }
                  if (allowPropagation) {
                    field.onBlur();
                  }
                }}
              />
              {/* don't render default overlay if we have a suggestion */}
              {!disabled && !suggestion && ownDefaultOverlayText && (
                <DefaultOverlay
                  text={ownDefaultOverlayText}
                  onClick={() => {
                    setOwnDefaultOverlayText(undefined);
                  }}
                />
              )}

              {!disabled && !isNil(ownSuggestion?.value) && formattedSuggestion && (
                <FormFieldSuggestionOverlay
                  text={formattedSuggestion}
                  onClick={() => {
                    resetOwnSuggestion();
                  }}
                />
              )}

              {enhancedRequired && !optional && (isNil(field.value) || field.value === '') && (
                <FormFieldRequiredLabel />
              )}
            </Box>
          )}
        />
        {formHelperText && (
          <FormHelperText sx={FormHelperTextStyles} error={!!errorMessage}>
            {formHelperText}
          </FormHelperText>
        )}
        {ownSuggestion?.reason && (
          <FormFieldValidationHelper
            reason={ownSuggestion.reason}
            suggestionValidationCallback={() => {
              resetOwnSuggestion();
            }}
            showSuggestionValidation={suggestionValidation?.showSuggestionValidation}
          />
        )}
      </FormControl>
    );
  },
);

export default FormTextField;
