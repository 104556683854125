import { isEmpty } from 'lodash';
import { ExtractedAttribute, ExtractedData } from '../../types';

export type ExtractedDataField = Required<Omit<ExtractedData, 'fileClassification'>>;
export type ExtractedField<K extends keyof ExtractedDataField> = ExtractedDataField[K];

// the extracted data can come from two sources (document extraction, web extraction)
// document extraction takes precedence over web extraction. If no document extraction is available, the web extraction is used.
// Note that currently, the precedence logic is shared among all attributes
export function normalizeExtractedDataSources(extractedData: ExtractedData): ExtractedDataField {
    return Object.entries(extractedData).reduce((acc, [key, extractedAttribute]) => {
        if (key === 'fileClassification') {
            return acc;
        }
        if (extractedAttribute?.value) {
            acc[key as keyof ExtractedDataField] = extractedAttribute as ExtractedAttribute<any>;
        } else if (!isEmpty(extractedAttribute?.additionalResults)) {
            acc[key as keyof ExtractedDataField] = extractedAttribute
                ?.additionalResults?.[0] as ExtractedAttribute<any>;
        }
        return acc;
    }, {} as ExtractedDataField);
}
