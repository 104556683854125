import { getCoverageLineConfig } from '@common/config';
import { useState } from 'react';
import { Dialog, DialogContent } from '@common-components';
import { CoverageLine } from 'enums';
import { useMutateSubmission } from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { DialogFooter, DialogHeader } from 'components/Dialog';
import { useUpdateHeraldApplicationWithSubmissionCoverageLines } from 'broker/pages/SubmissionWorkspacePage/utils';

interface AddMissingCoverageLinesConfirmationDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  createProducts: () => Promise<void>;
  updatedCoverageLines: CoverageLine[];
  newCoverageLines: CoverageLine[];
  partialSubmission: PartialSubmission;
}

export default function AddMissingCoverageLinesConfirmationDialog({
  isOpen,
  closeDialog,
  createProducts,
  updatedCoverageLines,
  newCoverageLines,
  partialSubmission,
}: AddMissingCoverageLinesConfirmationDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { updateSubmission } = useMutateSubmission();

  const updateHeraldApplicationWithSubmissionCoverageLines = useUpdateHeraldApplicationWithSubmissionCoverageLines();
  const onCreateCoverageLineConfirmation = async () => {
    try {
      setIsLoading(true);
      await updateSubmission.mutateAsync({
        id: partialSubmission.id,
        data: {
          coverageLines: updatedCoverageLines,
        },
      });
      const heraldUpdateResponse = await updateHeraldApplicationWithSubmissionCoverageLines({
        oldCoverageLines: partialSubmission.coverageLines,
        updatedCoverageLines,
      });
      setIsLoading(false);
      closeDialog();
      // only if no error happened in the process of updating herald application with new coverage lines
      if (heraldUpdateResponse !== undefined) {
        await createProducts();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    closeDialog();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader
        title={messages.addMissingCoverageLines.title}
        id="add-missing-coverage-lines-dialog"
        onClose={onClose}
      />
      <DialogContent sx={{ minWidth: 400 }}>
        {messages.addMissingCoverageLines.subtitle(
          newCoverageLines.map((coverageLine) => getCoverageLineConfig(coverageLine as CoverageLine).text).join(','),
        )}
      </DialogContent>
      <DialogFooter
        disableElevation
        proceedButton={{
          children: messages.addMissingCoverageLines.submit,
          loading: isLoading,
          onClick: () => {
            onCreateCoverageLineConfirmation();
          },
        }}
        cancelButton={{ children: messages.buttons.cancel, onClick: onClose }}
      />
    </Dialog>
  );
}
