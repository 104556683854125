export type Listener<T> = (data: T) => void;

export class EventEmitter<T> {
  private listeners: Listener<T>[] = [];

  public addListener = (listener: Listener<T>) => {
    this.listeners.push(listener);
  };

  public removeListener = (listener: Listener<T>) => {
    this.listeners = this.listeners.filter((l) => l !== listener);
  };

  public emit = (data: T) => {
    this.listeners.forEach((listener) => listener(data));
  };
}
