import { HeraldApplication, HeraldIndexEntry, HeraldParameterBaseProps } from 'clients/types';
import { HeraldCategoryType } from 'enums';
import { PartialSubmission } from 'types';
import { DynamicInputType } from './enums';

type UsedParamsFromHerald = Pick<
  HeraldParameterBaseProps,
  'value' | 'schema' | 'section' | 'instance' | 'creates_array' | 'affects_conditions'
>;

export enum DynamicFormQuestionSource {
  Herald = 'Herald',
  Flow = 'Flow',
}

export interface HeraldNormalizedParameter extends UsedParamsFromHerald {
  parameter_id: string;
  fieldLabel: string;
  // if HeraldNormalizedParameter has arrayElements then don't look for HeraldNormalizedParameter.childValues and look for HeraldNormalizedParameter.arrayElements[i].childValues
  childValues?: HeraldNormalizedParameter[];
  heraldCategory: HeraldCategoryType;
  // for creates_array
  arrayElements?: HeraldNormalizedParameter[];
  // just for 'rsk_b3jm_2017_naics_index' parameter_id question
  industryDetails?: HeraldIndexEntry;
  isRequired: boolean;
  isHidden?: (heraldNormalizedParameters: HeraldNormalizedParameter[], submission: PartialSubmission) => boolean;
  additionalContent?: string;
  helperText?: string;
  // DynamicFormQuestionSource.Herald question isn't sent to api (herald) -  it is just used for storing data in our database
  source: DynamicFormQuestionSource;
  showAfterOneOfTheseQuestions?: string[];
  input_type: DynamicInputType;
}

export interface HeraldApplicationNormalized
  extends Omit<HeraldApplication, 'risk_values' | 'coverage_values' | 'child_risk_values'> {
  risk_values: HeraldNormalizedParameter[];
  coverage_values: HeraldNormalizedParameter[];
}

export enum FormStateBaseProp {
  Main = 'main',
  Children = 'children',
}

// each value in the form has this base type. and if the question is a repeater (creates_array) then the value in the state will be an array of FormStateBaseProps, as each repeated question can have his own child questions
export interface FormStateBaseProps {
  [FormStateBaseProp.Main]: any;
  [FormStateBaseProp.Children]?: Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;
}

export type HeraldFormState = Record<string, FormStateBaseProps | FormStateBaseProps[] | undefined>;

export const heraldIndustryQuestionParameterId = 'rsk_b3jm_2017_naics_index';
export type AnsweredHeraldParametersBySections = { [key in string]: { answered: number; total: number } };

export interface DynamicFormImperativeHandle {
  submitForm: () => Promise<void>;
}

export const heraldDueDateParameterIds = [
  'cvg_48oo_gl_effective_date',
  'cvg_o3mw_cyb_effective_date',
  'cvg_m18u_pl_effective_date',
  'cvg_0em0_ml_effective_date',
];
