import { uniq } from 'lodash';
import { CoverageLine } from 'enums';
import { FlowStaticQuestionId } from './flow-static-questions/flow-static-question-id';

export const baseExtractionValidationFieldsConfigList = [
  'rsk_m4p9_insured_name',
  'rsk_yor8_location',
  FlowStaticQuestionId.PrimaryAddress,
  'cvg_m18u_pl_effective_date',
  'cvg_48oo_gl_effective_date',
  'cvg_o3mw_cyb_effective_date',
  'cvg_0em0_ml_effective_date',
  FlowStaticQuestionId.NeedByDate,
];

const DnOFields = [
  'rsk_0ie7_number_of_fte',
  'rsk_cog2_total_assets',
  'rsk_vrb1_total_annual_revenue',
  'rsk_s7wq_corporate_structure',
];

export const getExtractionValidationFields = (coverageLine: CoverageLine[]) => {
  const extractionValidationFieldsByCoverageLine: Partial<Record<CoverageLine, string[]>> = {
    [CoverageLine.Cyber]: ['rsk_b3jm_2017_naics_index', 'rsk_vrb1_total_annual_revenue'],
    [CoverageLine.EPL]: ['rsk_b3jm_2017_naics_index', 'rsk_0ie7_number_of_fte'],
    [CoverageLine.PrivateDnO]: DnOFields,
    [CoverageLine.PublicDnO]: DnOFields,
    [CoverageLine.NonProfitDnO]: DnOFields,
    [CoverageLine.Crime]: ['rsk_b3jm_2017_naics_index', 'rsk_vrb1_total_annual_revenue', 'rsk_0ie7_number_of_fte'],
    [CoverageLine.LawyersProfessionalLiability]: [
      FlowStaticQuestionId.AreasOfPractice,
      FlowStaticQuestionId.AttorneyNumber,
      FlowStaticQuestionId.AttorneyHireYears,
    ],
    [CoverageLine.TechnologyEnO]: ['rsk_b3jm_2017_naics_index', 'rsk_vrb1_total_annual_revenue'],
    [CoverageLine.MiscellaneousProfessionalLiability]: ['rsk_b3jm_2017_naics_index', 'rsk_vrb1_total_annual_revenue'],
  };

  const coverageFields = coverageLine
    .map((line) => extractionValidationFieldsByCoverageLine[line])
    .filter((fields): fields is string[] => Array.isArray(fields));

  // Flatten and return final array
  return uniq([...baseExtractionValidationFieldsConfigList, ...coverageFields.flat()]);
};
