import { some } from 'lodash';
import { ReactNode, useState } from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@common-components';
import { useEnvironment, useMount } from './hooks';
import { messages } from './i18n';

const indicatorStyles = makeStyles({
  breathingAnimation: {
    animation: '$breathing 1s infinite',
  },
  '@keyframes breathing': {
    '0%': { transform: 'scale(1)', backgroundColor: '#e3958a', color: '#000' },
    '50%': { transform: 'scale(1.3)', backgroundColor: '#D93921', color: '#fff' },
    '100%': { transform: 'scale(1)', backgroundColor: '#e3958a', color: '#000' },
  },
});

const suppressedErrors = [
  'Error while reading CSS rules from',
  'Error loading remote stylesheet',
  'Error inlining remote css file',
  'The following props are not supported: `data-locatorjs-id`',
];

const highVisibilityMessages = [
  'Maximum update depth exceeded',
  'Deprecation warning: value provided is not in a recognized RFC2822 or ISO format',
];

function isHighVisibilityMessage(message: any) {
  return some(highVisibilityMessages, (highVisibilityError) => message?.startsWith?.(highVisibilityError));
}

export const DevIndicator = () => {
  const environment = useEnvironment();
  const classes = indicatorStyles();

  const [errorsCount, setErrorsCount] = useState(0);
  const [hasHighVisibilityError, setHasHighVisibilityError] = useState(false);

  useMount(() => {
    if (environment === 'prod') {
      return () => {};
    }

    // eslint-disable-next-line no-console
    const originalConsoleError = console.error;
    // eslint-disable-next-line no-console
    console.error = (message?: any, ...optionalParams: any[]) => {
      if (some(suppressedErrors, (suppressedError) => message?.startsWith?.(suppressedError))) {
        return;
      }

      if (isHighVisibilityMessage(message)) {
        setHasHighVisibilityError(true);
      }

      queueMicrotask(() => setErrorsCount((prevState) => prevState + 1));
      originalConsoleError(message, ...optionalParams);
    };

    // eslint-disable-next-line no-console
    const originalConsoleWarn = console.warn;
    // eslint-disable-next-line no-console
    console.warn = (message?: any, ...optionalParams: any[]) => {
      if (isHighVisibilityMessage(message)) {
        setHasHighVisibilityError(true);

        // Treat this high visibility warning as an error so the dev indicator will become red
        queueMicrotask(() => setErrorsCount((prevState) => prevState + 1));
      }

      originalConsoleWarn(message, ...optionalParams);
    };

    const unhandledRejectionHandler = () => setErrorsCount((prevState) => prevState + 1);
    const errorHandler = () => setErrorsCount((prevState) => prevState + 1);

    window.addEventListener('unhandledrejection', unhandledRejectionHandler);
    window.addEventListener('error', errorHandler);

    return () => {
      // eslint-disable-next-line no-console
      console.error = originalConsoleError;
      // eslint-disable-next-line no-console
      console.warn = originalConsoleWarn;
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler);
      window.removeEventListener('error', errorHandler);
    };
  });

  if (environment === 'prod') {
    return null;
  }

  function variable(label: string, value?: ReactNode) {
    return (
      <Typography variant="body2">
        {label}: <b>{value ?? messages.general.unknown}</b>
      </Typography>
    );
  }

  const hasErrors = errorsCount > 0;

  return (
    <Box
      sx={{
        zIndex: 9999,
        display: 'inline-flex',
        left: 0,
        bottom: hasHighVisibilityError ? 'auto' : 0,
        position: 'fixed',
        m: 1,
        cursor: 'default',
        opacity: 0.6,
        px: 1,
        py: 0.5,
        borderRadius: 2,
        border: 1,
        borderColor: hasErrors ? 'error.main' : 'green.300',
        bgcolor: hasErrors ? '#e3958a' : 'green.50',
        color: hasErrors ? 'common.black' : 'inherit',
        typography: 'body1',
        lineHeight: 1,
        width: hasHighVisibilityError ? 1 : 'auto',
        justifyContent: 'center',
        top: hasHighVisibilityError ? 0 : 'auto',
        transform: (theme) => `translateY(${theme.spacing(-0.25)})`,
      }}
      className={hasErrors ? classes.breathingAnimation : ''}
      onClick={() => {
        setErrorsCount(0);
      }}
    >
      <Tooltip
        arrow={false}
        tooltipContent={
          <Box textAlign="left">
            {variable('Backend', environment)}
            {variable('Frontend build', process.env.NODE_ENV)}
            {variable('Build number', process.env.REACT_APP_BUILD_NUMBER)}
            {variable('Git hash', process.env.REACT_APP_GIT_HASH)}
            {variable('Errors', <Box component="span">{errorsCount}</Box>)}
          </Box>
        }
      >
        <Box>{hasHighVisibilityError ? 'Please fix the error / warning in console' : environment}</Box>
      </Tooltip>
    </Box>
  );
};
