import { useCallback } from 'react';
import { CoverageLine } from 'enums';
import { useSearchInsuranceProduct } from 'hooks';

export function useGetApiProductsByCoverageLines() {
  const { refetch: fetchProducts } = useSearchInsuranceProduct({
    enabled: false,
  });

  const getApiProductsByCoverageLines = useCallback(
    async (coverageLines: CoverageLine[]) => {
      const { data } = await fetchProducts();
      const products = data?.items || [];

      return products
        .filter((product) =>
          coverageLines.some((submissionCoverageLine) =>
            product.coverageLines.some(
              (insuranceProductCoverageLine) => insuranceProductCoverageLine.coverageLine === submissionCoverageLine,
            ),
          ),
        )
        .filter((product) => !!product.externalProductId);
    },
    [fetchProducts],
  );

  return {
    getApiProductsByCoverageLines,
  };
}
