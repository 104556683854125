import { isEmpty, isNil, omit, uniq } from 'lodash';
import {
    isManagementLiabilityCoverage,
    ManagementLiabilityCoverageLines,
    managementLiabilityCoverageLineToHeraldCoveragesConfig,
} from '../../config';
import { CoverageLine, LegalEntity } from '../../enums';
import { ExtractedData, Submission, SubmissionAdditionalData } from '../../types';
import { CoverageValuesUpdate, HeraldFormParameterId, RiskValuesUpdate } from '../../types/herald/herald-types';
import { convertAddressToAddressDetails, MakeFieldsRequired } from '../../utils';
import { convertDateToCalenderFormat, getTimezoneLessDisplayDate } from '../../utils/date-utils';
import { SubmissionDataDestination } from '../enums';
import {
    normalizeAddressExtraction,
    normalizeCoverageLineExtraction,
    normalizeDateExtraction,
    normalizeDomainExtraction,
    normalizeEmailExtraction,
    normalizeMultipleOptionsExtraction,
    normalizeNumericExtraction,
    normalizeOptionExtraction,
} from './extraction-normalizers';
import { ExtractedDataField, ExtractedField, normalizeExtractedDataSources } from './utils';

type SubmissionDataField<K extends keyof ExtractedDataField, SKey extends keyof Submission> = {
    key: SKey;
    destination: SubmissionDataDestination.Submission;
    getValue: (extractedDataField: MakeFieldsRequired<ExtractedField<K>, 'value'>) => Submission[SKey];
};

type SubmissionAdditionalDataField<K extends keyof ExtractedDataField, SKey extends keyof SubmissionAdditionalData> = {
    key: SKey;
    destination: SubmissionDataDestination.SubmissionAdditionalData;
    getValue: (extractedDataField: MakeFieldsRequired<ExtractedField<K>, 'value'>) => SubmissionAdditionalData[SKey];
};

type HeraldDataField<K extends keyof ExtractedDataField> = {
    destination: SubmissionDataDestination.Herald;
    getValue: (
        extractedDataField: MakeFieldsRequired<ExtractedField<K>, 'value'>,
    ) => RiskValuesUpdate | CoverageValuesUpdate;
};

export type DataField<K extends keyof ExtractedDataField> =
    | SubmissionDataField<K, keyof Submission>
    | SubmissionAdditionalDataField<K, keyof SubmissionAdditionalData>
    | HeraldDataField<K>;

type SubmissionExtractedDataToSubmissionData = {
    [K in keyof ExtractedDataField]: {
        destinations: DataField<K>[];
        // return undefined if the field doesn't contain a valid value
        normalizer?: (extractedField: ExtractedField<K>) => ExtractedField<K> | undefined;
    };
};

/**
 * config that maps each property in the extracted data to the appropriate submission data field
 * normalizer: how to normalize the extracted data field (if the extraction field is expected to be a number in a string, we validate it is a numeric string and if not we clean that extraction field)
 * destination: SubmissionDataDestination.Submission: how to map the extracted data property to the way it is saved in our submission table (not including additional data)
 * destination: SubmissionDataDestination.SubmissionAdditionalData: how to map the extracted data property to the way it is saved in our submission_additional_data table
 * destination: SubmissionDataDestination.Herald: how to map the extracted data property to the way it is saved in herald api
 */
const submissionExtractedDataToSubmissionData: SubmissionExtractedDataToSubmissionData = {
    insuredName: {
        normalizer: (extractedField) => extractedField,
        destinations: [
            {
                key: 'insuredName',
                destination: SubmissionDataDestination.Submission,
                getValue: (insuredName) => insuredName.value,
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (insuredName) => ({
                    risk_parameter_id: 'rsk_m4p9_insured_name',
                    value: insuredName.value,
                }),
            },
        ],
    },
    coverageLines: {
        normalizer: (extractedField) => normalizeCoverageLineExtraction(extractedField),
        destinations: [
            {
                key: 'coverageLines',
                destination: SubmissionDataDestination.Submission,
                getValue: (coverageLines) => coverageLines.value,
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => {
                    const heraldManagementLiabilityCoverageLines = extractedField.value
                        ? uniq(
                              extractedField.value
                                  .filter((coverageLine) => isManagementLiabilityCoverage(coverageLine as CoverageLine))
                                  .map(
                                      (coverageLine) =>
                                          managementLiabilityCoverageLineToHeraldCoveragesConfig[
                                              coverageLine as ManagementLiabilityCoverageLines
                                          ],
                                  ),
                          )
                        : undefined;
                    return {
                        coverage_parameter_id: HeraldFormParameterId.SelectedCoverages,
                        value: !isEmpty(heraldManagementLiabilityCoverageLines)
                            ? heraldManagementLiabilityCoverageLines
                            : null,
                    };
                },
            },
        ],
    },
    partTimeEmployees: {
        normalizer: (extractedField) => extractedField,
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_16rg_number_of_pte',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    fullTimeEmployees: {
        normalizer: (extractedField) => extractedField,
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_0ie7_number_of_fte',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    employeesNumber: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_k39d_number_of_employees',
                    value: Number(extractedField.value),
                }),
            },
            {
                key: 'insuredEmployeeCount',
                destination: SubmissionDataDestination.Submission,
                getValue: (extractedField) => Number(extractedField.value),
            },
        ],
    },
    totalPayroll: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_a7he_total_payroll',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    totalAnnualRevenue: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_vrb1_total_annual_revenue',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    totalAssets: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_cog2_total_assets',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    yearsOfOperation: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_4b4x_years_of_operation',
                    value: Number(extractedField.value),
                }),
            },
        ],
    },
    applicationDomainName: {
        normalizer: (extractedField) => normalizeDomainExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_7ahp_has_domain',
                    value: extractedField.value ? 'yes' : 'no',
                }),
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (applicationDomainName) => ({
                    risk_parameter_id: 'rsk_dy7r_domain_names',
                    instance: 'domain_names_1',
                    value: applicationDomainName.value,
                }),
            },
        ],
    },
    industry: {
        normalizer: (extractedField) => extractedField,
        destinations: [
            {
                key: 'industry',
                destination: SubmissionDataDestination.Submission,
                getValue: (industry) =>
                    industry.value?.mappedIndexEntry?.[0]
                        ? {
                              heraldId: industry.value.mappedIndexEntry[0].heraldId,
                              naicsCode: industry.value.mappedIndexEntry[0].naicsCode,
                              description: industry.value.mappedIndexEntry[0].indexEntryDescription,
                          }
                        : undefined,
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (industry) => ({
                    risk_parameter_id: 'rsk_b3jm_2017_naics_index',
                    value: industry.value?.mappedIndexEntry?.[0]
                        ? industry.value.mappedIndexEntry[0].heraldId
                        : undefined,
                }),
            },
        ],
    },
    address: {
        normalizer: (extractedField) => normalizeAddressExtraction(extractedField),
        destinations: [
            {
                key: 'insuredPrimaryAddress',
                destination: SubmissionDataDestination.Submission,
                getValue: (address) =>
                    address.value?.primary ? convertAddressToAddressDetails(address.value.primary) : undefined,
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (address) => ({
                    risk_parameter_id: 'rsk_yor8_location',
                    instance: 'location_1',
                    value: address.value?.primary ? address.value.primary : null,
                }),
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (address) => ({
                    risk_parameter_id: 'rsk_jsy2_primary_address',
                    value: address.value?.primary ? address.value.primary : null,
                }),
            },
            {
                key: 'insuredMailingAddress',
                destination: SubmissionDataDestination.Submission,
                getValue: (address) =>
                    address.value?.mailing ? convertAddressToAddressDetails(address.value.mailing) : undefined,
            },
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (address) => ({
                    risk_parameter_id: 'rsk_tvm3_mailing_address',
                    value: address.value?.mailing ? address.value.mailing : null,
                }),
            },
        ],
    },
    legalEntity: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, Object.values(LegalEntity)),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (legalEntity) => ({
                    risk_parameter_id: 'rsk_837r_legal_entity',
                    value: legalEntity.value,
                }),
            },
        ],
    },
    organizationsCorporateStructure: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['Private', 'Not for profit']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_s7wq_corporate_structure',
                    value: extractedField.value,
                }),
            },
        ],
    },
    attorneyNumber: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                key: 'attorneyNumber',
                destination: SubmissionDataDestination.SubmissionAdditionalData,
                getValue: (attorneyNumber) => Number(attorneyNumber.value),
            },
        ],
    },
    phoneNumber: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_14kt_insured_contact_phone',
                    value: extractedField.value,
                }),
            },
        ],
    },
    lossRunIndicatorCyber: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_jb26_cyb_has_claims_history',
                    value: extractedField.value,
                }),
            },
        ],
    },
    lossRunIndicatorPL: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_2aep_pl_has_claim_history',
                    value: extractedField.value,
                }),
            },
        ],
    },
    effectiveDatePL: {
        normalizer: (extractedField) => normalizeDateExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    coverage_parameter_id: 'cvg_m18u_pl_effective_date',
                    value: convertDateToCalenderFormat(extractedField.value!),
                }),
            },
            {
                destination: SubmissionDataDestination.Submission,
                key: 'dueDate',
                getValue: (extractedField) => new Date(getTimezoneLessDisplayDate(extractedField.value!)),
            },
        ],
    },
    effectiveDateGL: {
        normalizer: (extractedField) => normalizeDateExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    coverage_parameter_id: 'cvg_48oo_gl_effective_date',
                    value: convertDateToCalenderFormat(extractedField.value!),
                }),
            },
            {
                destination: SubmissionDataDestination.Submission,
                key: 'dueDate',
                getValue: (extractedField) => new Date(getTimezoneLessDisplayDate(extractedField.value!)),
            },
        ],
    },
    effectiveDateCyber: {
        normalizer: (extractedField) => normalizeDateExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    coverage_parameter_id: 'cvg_o3mw_cyb_effective_date',
                    value: convertDateToCalenderFormat(extractedField.value!),
                }),
            },
            {
                destination: SubmissionDataDestination.Submission,
                key: 'dueDate',
                getValue: (extractedField) => new Date(getTimezoneLessDisplayDate(extractedField.value!)),
            },
        ],
    },
    effectiveDateML: {
        normalizer: (extractedField) => normalizeDateExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    coverage_parameter_id: 'cvg_0em0_ml_effective_date',
                    value: convertDateToCalenderFormat(extractedField.value!),
                }),
            },
            {
                destination: SubmissionDataDestination.Submission,
                key: 'dueDate',
                getValue: (extractedField) => new Date(getTimezoneLessDisplayDate(extractedField.value!)),
            },
        ],
    },
    needByDate: {
        normalizer: (extractedField) => normalizeDateExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Submission,
                key: 'needByDate',
                getValue: (extractedField) => new Date(getTimezoneLessDisplayDate(extractedField.value!)),
            },
        ],
    },
    applicantContactName: {
        normalizer: (extractedField) => extractedField,
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_t79b_insured_contact_name',
                    value: extractedField.value,
                }),
            },
        ],
    },
    applicantEmailAddress: {
        normalizer: (extractedField) => normalizeEmailExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (extractedField) => ({
                    risk_parameter_id: 'rsk_5p6w_insured_contact_email',
                    value: extractedField.value,
                }),
            },
        ],
    },
    contactAgreedToInformationSecurity: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (contactAgreedToInformationSecurity) => ({
                    risk_parameter_id: 'rsk_voe4_cyb_security_officer',
                    value: contactAgreedToInformationSecurity.value,
                }),
            },
        ],
    },
    publiclyTradedSecurities: {
        normalizer: (extractedField) =>
            normalizeMultipleOptionsExtraction(extractedField, [
                'In the past 13-24 months',
                'In the past 12 months',
                'In the next 12 months',
                'None of the above',
            ]),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (publiclyTradedSecurities) => ({
                    risk_parameter_id: 'rsk_yo5p_anticipates_or_completed_public_offerings',
                    value: publiclyTradedSecurities.value,
                }),
            },
        ],
    },
    privatePlacementInvestment: {
        normalizer: (extractedField) =>
            normalizeMultipleOptionsExtraction(extractedField, [
                'In the past 13-24 months',
                'In the past 12 months',
                'In the next 12 months',
                'None of the above',
            ]),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (privatePlacementInvestment) => ({
                    risk_parameter_id: 'rsk_sf46_anticipates_or_completed_private_placements',
                    value: privatePlacementInvestment.value,
                }),
            },
        ],
    },
    shareholderBoardRepresentation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (shareholderBoardRepresentation) => ({
                    risk_parameter_id: 'rsk_mur2_has_outside_individual_ownership',
                    value: shareholderBoardRepresentation.value,
                }),
            },
        ],
    },
    debtCovenantBreach: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (debtCovenantBreach) => ({
                    risk_parameter_id: 'rsk_e718_has_breached_debt_covenants',
                    value: debtCovenantBreach.value,
                }),
            },
        ],
    },
    securityBreachNotification: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (securityBreachNotification) => ({
                    risk_parameter_id: 'rsk_3oiz_has_security_breach_requiring_notification',
                    value: securityBreachNotification.value,
                }),
            },
        ],
    },
    pendingLitigation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (pendingLitigation) => ({
                    risk_parameter_id: 'rsk_sw1p_has_pending_litigation',
                    value: pendingLitigation.value,
                }),
            },
        ],
    },
    unscheduledDowntimeLoss: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (unscheduledDowntimeLoss) => ({
                    risk_parameter_id: 'rsk_p175_cyb_has_loss_in_unschedule_system_downtime',
                    value: unscheduledDowntimeLoss.value,
                }),
            },
        ],
    },
    ownershipType: {
        normalizer: (extractedField) =>
            normalizeOptionExtraction(extractedField, [
                'Public',
                'Private',
                'Non-Profit',
                'Public Sector',
                'Partnership',
                'Non-Corporates',
            ]),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (ownershipType) => ({
                    risk_parameter_id: 'rsk_2i59_ownership_type',
                    value: ownershipType.value,
                }),
            },
        ],
    },
    sensitiveCloudStorage: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (sensitiveCloudStorage) => ({
                    risk_parameter_id: 'rsk_5m1o_cyb_cloud_storage',
                    value: sensitiveCloudStorage.value,
                }),
            },
        ],
    },
    infoSecTraining: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (infoSecTraining) => ({
                    risk_parameter_id: 'rsk_6ril_cyb_security_training',
                    value: infoSecTraining.value,
                }),
            },
        ],
    },
    franchiseStatus: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (franchiseStatus) => ({
                    risk_parameter_id: 'rsk_s9i6_is_franchise',
                    value: franchiseStatus.value,
                }),
            },
        ],
    },
    businessBrokerServices: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (businessBrokerServices) => ({
                    risk_parameter_id: 'rsk_2nge_is_business_broker',
                    value: businessBrokerServices.value,
                }),
            },
        ],
    },
    capitalRaisingServices: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (capitalRaisingServices) => ({
                    risk_parameter_id: 'rsk_nbd2_is_investment_bank',
                    value: capitalRaisingServices.value,
                }),
            },
        ],
    },
    mergerAcquisitionServices: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (mergerAcquisitionServices) => ({
                    risk_parameter_id: 'rsk_2n3d_performs_merger_and_acquisition',
                    value: mergerAcquisitionServices.value,
                }),
            },
        ],
    },
    backupSecurityMeasures: {
        normalizer: (extractedField) =>
            normalizeMultipleOptionsExtraction(extractedField, [
                'MFA',
                'Segmentation',
                'Immutable',
                'Virus/Malware scanning',
                'Encryption',
                'Test',
                'Online or Designated Cloud Service',
                'None of the above',
            ]),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (backupSecurityMeasures) => ({
                    risk_parameter_id: 'rsk_4hz4_backup_security_measure_type',
                    value: backupSecurityMeasures.value,
                }),
            },
        ],
    },
    thirdPartySecurityAgreements: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (thirdPartySecurityAgreements) => ({
                    risk_parameter_id: 'rsk_9ljq_third_party_security_agreement',
                    value: thirdPartySecurityAgreements.value,
                }),
            },
        ],
    },
    failoverTesting: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (failoverTesting) => ({
                    risk_parameter_id: 'rsk_bet9_tested_full_failover',
                    value: failoverTesting.value,
                }),
            },
        ],
    },
    systemSegregation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (systemSegregation) => ({
                    risk_parameter_id: 'rsk_9ty9_has_dmz_separation',
                    value: systemSegregation.value,
                }),
            },
        ],
    },
    dataEncryption: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (systemSegregation) => ({
                    risk_parameter_id: 'rsk_rzc7_data_encryption_frequency',
                    value: systemSegregation.value,
                }),
            },
        ],
    },
    updateFrequency: {
        normalizer: (extractedField) =>
            normalizeOptionExtraction(extractedField, [
                `Weekly or more`,
                `Monthly`,
                `Quarterly`,
                `Biannually`,
                `At least annually`,
                `None of the above`,
            ]),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (updateFrequency) => ({
                    risk_parameter_id: 'rsk_331u_patch_frequency',
                    value: updateFrequency.value,
                }),
            },
        ],
    },
    incidentResponsePlan: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (incidentResponsePlan) => ({
                    risk_parameter_id: 'rsk_fzej_has_incident_response_plan',
                    value: incidentResponsePlan.value,
                }),
            },
        ],
    },
    securityTrainingImplementation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (securityTrainingImplementation) => ({
                    risk_parameter_id: 'rsk_8s6e_cyb_training_implementation',
                    value: securityTrainingImplementation.value,
                }),
            },
        ],
    },
    newHireBackgroundChecks: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (newHireBackgroundChecks) => ({
                    risk_parameter_id: 'rsk_na7l_perform_background_checks_employee',
                    value: newHireBackgroundChecks.value,
                }),
            },
        ],
    },
    bankAccountReconciliation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (bankAccountReconciliation) => ({
                    risk_parameter_id: 'rsk_ms88_bank_account_reconciliation',
                    value: bankAccountReconciliation.value,
                }),
            },
        ],
    },
    financialControlSegregation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (financialControlSegregation) => ({
                    risk_parameter_id: 'rsk_0o4l_wire_transfer_authorization',
                    value: financialControlSegregation.value,
                }),
            },
        ],
    },
    paymentInstructionVerificationPolicy: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (paymentInstructionVerificationPolicy) => ({
                    risk_parameter_id: 'rsk_77vv_vendor_verification_policy',
                    value: paymentInstructionVerificationPolicy.value,
                }),
            },
        ],
    },
    inventoryPhysicalCount: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (inventoryPhysicalCount) => ({
                    risk_parameter_id: 'rsk_u3u3_inventory_physical_count',
                    value: inventoryPhysicalCount.value,
                }),
            },
        ],
    },
    highValueInventoryExposure: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (highValueInventoryExposure) => ({
                    risk_parameter_id: 'rsk_ut7e_high_value_exposure',
                    value: highValueInventoryExposure.value,
                }),
            },
        ],
    },
    checkCountersignatureRequirement: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (checkCountersignatureRequirement) => ({
                    risk_parameter_id: 'rsk_3h4w_counter_signature_required',
                    value: checkCountersignatureRequirement.value,
                }),
            },
        ],
    },
    domesticLocations: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (domesticLocations) => ({
                    risk_parameter_id: 'rsk_u8u8_locations_abroad',
                    value: domesticLocations.value,
                }),
            },
        ],
    },
    bankingTransactionSeparation: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (bankingTransactionSeparation) => ({
                    risk_parameter_id: 'rsk_ff4b_banking_transaction_control',
                    value: bankingTransactionSeparation.value,
                }),
            },
        ],
    },
    expectsDebtSoon: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (expectsDebtSoon) => ({
                    risk_parameter_id: 'rsk_ize4_expects_debt_soon',
                    value: expectsDebtSoon.value,
                }),
            },
        ],
    },
    netIncome: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (netIncome) => ({
                    risk_parameter_id: 'rsk_0bi6_net_income',
                    value: Number(netIncome.value),
                }),
            },
        ],
    },
    currentAssets: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (currentAssets) => ({
                    risk_parameter_id: 'rsk_ymb6_current_assets',
                    value: Number(currentAssets.value),
                }),
            },
        ],
    },
    earningsBeforeInterestTaxes: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (earningsBeforeInterestTaxes) => ({
                    risk_parameter_id: 'rsk_5s10_earnings_before_interest_taxes',
                    value: Number(earningsBeforeInterestTaxes.value),
                }),
            },
        ],
    },
    cashOrCashEquivalentAssets: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (cashOrCashEquivalentAssets) => ({
                    risk_parameter_id: 'rsk_ff62_cash_or_cash_equivalent_assets',
                    value: Number(cashOrCashEquivalentAssets.value),
                }),
            },
        ],
    },
    cashFlowFromOperations: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (cashFlowFromOperations) => ({
                    risk_parameter_id: 'rsk_ep1m_cash_flow_from_operations',
                    value: Number(cashFlowFromOperations.value),
                }),
            },
        ],
    },
    currentLiabilities: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (currentLiabilities) => ({
                    risk_parameter_id: 'rsk_t0a4_current_liabilities',
                    value: Number(currentLiabilities.value),
                }),
            },
        ],
    },
    longTermDebt: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (longTermDebt) => ({
                    risk_parameter_id: 'rsk_5b5e_long_term_debt',
                    value: Number(longTermDebt.value),
                }),
            },
        ],
    },
    amountOfExpectedDebt: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (amountOfExpectedDebt) => ({
                    risk_parameter_id: 'rsk_d6iz_amount_of_expected_debt',
                    value: Number(amountOfExpectedDebt.value),
                }),
            },
        ],
    },
    percentageSharesHeldByBoard: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (percentageSharesHeldByBoard) => ({
                    risk_parameter_id: 'rsk_faf9_percentage_shares_held_by_board',
                    value: Number(percentageSharesHeldByBoard.value),
                }),
            },
        ],
    },
    halfLongTermDebtMatures: {
        normalizer: (extractedField) => normalizeOptionExtraction(extractedField, ['yes', 'no']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (halfLongTermDebtMatures) => ({
                    risk_parameter_id: 'rsk_gyj4_half_long_term_debt_matures',
                    value: halfLongTermDebtMatures.value,
                }),
            },
        ],
    },
    totalLiabilities: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (totalLiabilities) => ({
                    risk_parameter_id: 'rsk_phqr_total_liabilities',
                    value: Number(totalLiabilities.value),
                }),
            },
        ],
    },
    totalEntitiesOwnShares: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (totalEntitiesOwnShares) => ({
                    risk_parameter_id: 'rsk_0k1o_total_entities_own_shares',
                    value: Number(totalEntitiesOwnShares.value),
                }),
            },
        ],
    },
    percentageHeldByTrusts: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (percentageHeldByTrusts) => ({
                    risk_parameter_id: 'rsk_tu74_percentage_held_by_trusts',
                    value: Number(percentageHeldByTrusts.value),
                }),
            },
        ],
    },
    entityType: {
        normalizer: (extractedField) =>
            normalizeOptionExtraction(extractedField, ['Independent', 'Parent', 'Subsidiary']),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (entityType) => ({
                    risk_parameter_id: 'rsk_6onk_entity_type',
                    value: entityType.value,
                }),
            },
        ],
    },
    yearsIndustryManagement: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (yearsIndustryManagement) => ({
                    risk_parameter_id: 'rsk_9ux6_years_industry_management',
                    value: Number(yearsIndustryManagement.value),
                }),
            },
        ],
    },
    totalCashExposureOutside: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (totalCashExposureOutside) => ({
                    risk_parameter_id: 'rsk_tt0l_total_cash_exposure_outside',
                    value: Number(totalCashExposureOutside.value),
                }),
            },
        ],
    },
    totalCashExposureInside: {
        normalizer: (extractedField) => normalizeNumericExtraction(extractedField),
        destinations: [
            {
                destination: SubmissionDataDestination.Herald,
                getValue: (totalCashExposureInside) => ({
                    risk_parameter_id: 'rsk_tt8l_total_cash_exposure_inside',
                    value: Number(totalCashExposureInside.value),
                }),
            },
        ],
    },
};

export type ResolvedDataFields<K extends keyof ExtractedDataField> =
    | (Omit<SubmissionDataField<K, keyof Submission>, 'getValue'> & { value: Submission[keyof Submission] })
    | (Omit<SubmissionAdditionalDataField<K, keyof SubmissionAdditionalData>, 'getValue'> & {
          value: SubmissionAdditionalData[keyof SubmissionAdditionalData];
      })
    | (Omit<HeraldDataField<K>, 'getValue'> & { value: RiskValuesUpdate | CoverageValuesUpdate });

type ResolvedSubmissionExtractedDataToSubmissionData = {
    [K in keyof ExtractedDataField]: ResolvedDataFields<K>[];
};

export const submissionExtractedDataToSubmissionDataResolver = (
    extractedData: ExtractedData,
): ResolvedSubmissionExtractedDataToSubmissionData => {
    const normalizedData = normalizeExtractedDataSources(extractedData);

    return Object.entries(normalizedData).reduce((acc, [key, value]) => {
        // Ensure key type safety
        if (!(key in submissionExtractedDataToSubmissionData)) {
            return acc;
        }

        const field = submissionExtractedDataToSubmissionData[key as keyof ExtractedDataField];

        // Normalize the extracted data field
        // @ts-ignore - hard to tell typescript that were sending the right ExtractedField[field]
        const normalizedField = field.normalizer?.(value) ?? value;

        // don't call getValue if the normalize extracted value was undefined or null
        if (isNil(normalizedField?.value)) {
            return acc;
        }

        // @ts-ignore
        acc[key as keyof ExtractedDataField] = field.destinations.map((destination) => ({
            ...omit(destination, 'getValue'),
            // @ts-ignore - hard to tell typescript that were sending the right ExtractedField[field]
            value: destination.getValue(normalizedField),
        }));
        return acc;
    }, {} as ResolvedSubmissionExtractedDataToSubmissionData);
};
