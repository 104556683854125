import { noneOfTheAboveOption } from '@common/submission-data';
import { isArray } from 'lodash';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { BaseOption } from 'components/Autocomplete/OptionsList';
import {
  FormMultiSelectAutocomplete,
  FormMultiSelectAutocompleteProps,
} from 'components/hookFormComponents/FormMultiSelectAutocomplete';

export default function HeraldFormMultiSelectAutocomplete<T extends BaseOption>(
  props: FormMultiSelectAutocompleteProps<T>,
) {
  const { name } = props;
  const { watch, setValue } = useFormContext();
  const watchValue = watch(name) as string[] | undefined;

  useEffect(() => {
    if (isArray(watchValue) && watchValue.length > 1) {
      // find if watchValue contains string "None of the above", lowercase comparison
      const noneOfTheAboveIndex = watchValue.findIndex(
        (option) => option.toLowerCase() === noneOfTheAboveOption.toLowerCase(),
      );
      if (noneOfTheAboveIndex !== -1) {
        // check if "None of the above" is the first option, meaning the other options were added after it, so we want to remove none of the above
        if (noneOfTheAboveIndex === 0) {
          // remove "None of the above" from watchValue
          const updateValue = watchValue.filter(
            (option) => option.toLowerCase() !== noneOfTheAboveOption.toLowerCase(),
          );
          setValue(name, updateValue);
        }
        // "None of the above" is not the first option, meaning it was added after other options, so we want to remove all other options
        else {
          // remove all other options except "None of the above"
          setValue(name, [watchValue[noneOfTheAboveIndex]]);
        }
      }
    }
  }, [name, setValue, watchValue]);

  return <FormMultiSelectAutocomplete {...props} />;
}
