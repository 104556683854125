import { getCoverageLinesText } from '@common/utils';
import { IconButton, Stack, Typography } from '@common-components';
import { AirplanemodeActive, West } from '@icons';
import { messages } from 'i18n';
import { PartialSubmission, User, UserDetails } from 'types';
import { generateSubmissionPath } from 'broker/broker-routes';
import { BaseRoutedDialogContentProps } from 'broker/components/RoutedDialog';
import SubmissionAssigneeList, { OnAssigneeChange } from 'broker/components/SubmissionsView/SubmissionAssigneeList';
import { RouteKey } from 'broker/enums';
import { useNavigate } from 'broker/hooks';

export interface HeaderProps extends Pick<BaseRoutedDialogContentProps, 'onClose' | 'setDialogIsDirty'> {
  submission: PartialSubmission;
  users: User[];
  onAssigneeChange: OnAssigneeChange;
  selectedUser: UserDetails;
  setDialogIsDirty: (isDirty: boolean) => void;
}
export default function Header({
  onClose,
  submission,
  users,
  selectedUser,
  onAssigneeChange,
  setDialogIsDirty,
}: HeaderProps) {
  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center" p={3} gap={1}>
      <IconButton tooltipContent={messages.buttons.back} color="secondary" onClick={onClose} icon={West} />
      <Stack alignItems="center" direction="row" overflow="hidden" gap={1} px={2} flex={1}>
        <AirplanemodeActive />
        <Stack overflow="hidden">
          <Stack direction="row" gap={1}>
            <Typography
              flex="1 0 auto"
              noWrap
              variant="h4"
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                navigate(
                  {
                    pathname: generateSubmissionPath(submission.id),
                  },
                  {
                    routeKey: RouteKey.WorkspaceTab,
                    state: undefined,
                    replace: true,
                  },
                );
              }}
            >
              {submission.insuredName}
            </Typography>
            <Typography noWrap variant="h4">
              {` - ${getCoverageLinesText(submission?.coverageLines)}`}
            </Typography>
          </Stack>
          <Typography noWrap variant="body1" color="textSecondary" component="div">
            {messages.autonomousFlow.settingsModal.advanced.title}
          </Typography>
        </Stack>
      </Stack>

      <SubmissionAssigneeList
        prefix
        submissionId={submission.id}
        usersList={users}
        selectedUser={selectedUser}
        onAssigneeChange={async (submissionId, userId) => {
          await onAssigneeChange(submissionId, userId);
          setDialogIsDirty(true);
        }}
        selectorProps={{
          size: 'medium',
        }}
        disabled={submission.isCopilotEnabled}
      />
    </Stack>
  );
}
