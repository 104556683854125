import { isArray, isEmpty, keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormFieldRequiredLabel,
  FormFieldValidationHelper,
  FormHelperText,
} from '@common-components';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { normalizeError } from 'utils';
import {
  MultiSelectionAutocomplete,
  MultiSelectionAutocompleteProps,
} from 'components/Autocomplete/MultiSelectionAutocomplete';
import { BaseOption } from 'components/Autocomplete/OptionsList';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import InputLabel from 'components/hookFormComponents/InputLabel';
import { FormHelperTextStyles } from 'components/hookFormComponents/styles';
import { SuggestionProps, SuggestionValidation } from './types';

type MultiSelectAutocompleteSuggestion = Omit<SuggestionProps, 'value'> & { value: string[] };

export interface FormMultiSelectAutocompleteProps<T extends BaseOption>
  extends Omit<MultiSelectionAutocompleteProps<T>, 'selectedOptions' | 'setSelectedOptions'> {
  name: string;
  label: string;
  dropdownAfterTyping?: boolean;
  defaultValue?: string[];
  fullWidth?: boolean;
  optional?: boolean;
  hideOptionalLabel?: boolean;
  onBlur?: (e: any) => boolean | undefined;
  helperText?: string;
  defaultValueOverlayText?: string;
  suggestion?: MultiSelectAutocompleteSuggestion;
  enhancedRequired?: boolean;
  suggestionValidation?: SuggestionValidation;
}

export const MultiSelectFormControlClassName = 'MultiSelectFormControl';

export function FormMultiSelectAutocomplete<T extends BaseOption>({
  name,
  label,
  defaultValue,
  placeholder = messages.general.selectPlaceholder,
  fullWidth = true,
  optional = false,
  hideOptionalLabel = false,
  dropdownAfterTyping,
  options,
  onBlur,
  helperText,
  defaultValueOverlayText,
  suggestion,
  enhancedRequired,
  suggestionValidation,
  ...props
}: FormMultiSelectAutocompleteProps<T>) {
  const labelId = `${name}-label`;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = normalizeError(errors, name);
  const formHelperText = errorMessage || helperText;

  const optionValueToT = keyBy(options, (option) => option.value);
  const [ownSuggestion, setOwnSuggestion] = useState<MultiSelectAutocompleteSuggestion | undefined>(suggestion);

  useEffect(() => {
    setOwnSuggestion(suggestion);
  }, [suggestion]);

  const resetOwnSuggestion = () => {
    setOwnSuggestion(undefined);
    suggestionValidation?.suggestionValidationCallback?.();
  };

  const formattedSuggestion = isArray(ownSuggestion?.value) ? ownSuggestion?.value.join(', ') : '';

  return (
    <FormControl
      className={MultiSelectFormControlClassName}
      sx={{
        pb: FormControlPaddingBottom,
        mb: formHelperText ? FormControlHelperTextMarginBottom : 0,
        height: 1,
        justifyContent: 'space-between',
      }}
      fullWidth={fullWidth}
    >
      <InputLabel
        showSuggestionValidation={suggestionValidation?.showSuggestionValidation && !!ownSuggestion}
        suggestionValidationError={suggestionValidation?.suggestionValidationError}
        id={labelId}
        error={!!errorMessage}
        label={label}
        optional={optional}
        hideOptionalLabel={hideOptionalLabel}
        htmlFor={name}
      />
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? []}
        render={({ field: { onChange, value: stringValues, onBlur: fieldPropsOnBlur } }) => {
          // This is a bit confusing:
          // The form field value is an array of strings
          // The options for the autocomplete is an array of T
          // The provided default value is the form field value (array of strings)
          // So the value here is either on the provided options (which we try to find using the lookup)
          // or it was provided as a default value, in which case we construct a T out of each value
          const values =
            stringValues && Array.isArray(stringValues)
              ? stringValues.map((value: string) => optionValueToT[value] ?? { value, label: value })
              : [];
          return (
            <Box position="relative">
              <MultiSelectionAutocomplete
                defaultOverlayText={defaultValueOverlayText}
                hasErrors={!!errorMessage}
                selectedOptions={values ?? []}
                dropdownAfterTyping={dropdownAfterTyping}
                setSelectedOptions={(newValues) => {
                  onChange(newValues.map((newValue) => newValue.value));
                }}
                options={options}
                placeholder={placeholder}
                {...props}
                onBlur={(e) => {
                  let allowPropagation: boolean = true;
                  if (onBlur) {
                    const response = onBlur(e);
                    if (response !== undefined) {
                      allowPropagation = response;
                    }
                  }
                  if (allowPropagation) {
                    fieldPropsOnBlur();
                  }
                }}
              />
              {ownSuggestion?.value && formattedSuggestion && (
                <FormFieldSuggestionOverlay
                  text={formattedSuggestion}
                  onClick={() => {
                    resetOwnSuggestion();
                  }}
                />
              )}
              {enhancedRequired && !optional && !formattedSuggestion && isEmpty(values) && (
                <FormFieldRequiredLabel shiftTop={-6} />
              )}
            </Box>
          );
        }}
      />
      {formHelperText && (
        <FormHelperText error={!!errorMessage} sx={FormHelperTextStyles}>
          {formHelperText}
        </FormHelperText>
      )}
      {ownSuggestion?.reason && (
        <FormFieldValidationHelper
          reason={ownSuggestion.reason}
          suggestionValidationCallback={() => {
            resetOwnSuggestion();
          }}
          showSuggestionValidation={suggestionValidation?.showSuggestionValidation}
        />
      )}
    </FormControl>
  );
}
