import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { AppLoader, Box, CssBaseline, Theme } from '@common-components';
import { useAuthenticationContext } from 'contexts';
import { AppStoreProvider } from 'contexts/app-store/AppStoreProvider';
import { InformationTooltipProvider } from 'contexts/information-tooltip/informationTooltipProvider';
import { EndUserIndicator } from 'EndUserIndicator';
import {
  useCurrentUser,
  useHotjar,
  useIsOnline,
  useLoggerInitialization,
  useOnlineStatus,
  useScreenResolutionAlert,
} from 'hooks';
import Backdrop from './components/Backdrop';
import { FeatureGate } from './components/FeatureGate';
import RetailerTopBar from './components/layout/retailer-top-bar';
import SideBar from './components/layout/side-bar';
import Router from './components/Router';
import { AppPubSubProvider } from './contexts/app-pub-sub/AppPubSub';
import { DevIndicator } from './DevIndicator';
import './index.css';
import { PORTAL_SITE_ROLES, UserRole } from './enums';
import { GlobalErrorPage } from './GlobalErrorPage';
import routes from './routes';
import { useServerStreaming } from './server-streaming';
import { logger } from './utils';
import { Features } from './utils/features-config';
import { HotjarEvents } from './utils/hotjar-events';

enum AppLayoutParts {
  SideBarLayout = 'SideBar',
  MainLayout = 'Main',
  RetailerTopBarLayout = 'RetailerTopBar',
}

const AppWrapperStyles = ({ isRetailUser }: { isRetailUser?: boolean }) => ({
  height: '100vh',
  display: 'grid',
  gridTemplateColumns: isRetailUser ? '1fr' : (theme: Theme) => `${theme.spacing(9)} 1fr`,
  gridTemplateRows: isRetailUser ? 'auto 1fr' : '1fr',
  gridTemplateAreas: isRetailUser
    ? `"${AppLayoutParts.RetailerTopBarLayout}"
       "${AppLayoutParts.MainLayout}"`
    : `"${AppLayoutParts.SideBarLayout} ${AppLayoutParts.MainLayout}"`,
  backgroundColor: 'common.white',
});
// This debug tool is never available in production
setUseWhatChange({ active: process.env.NODE_ENV !== 'production' });

export default function App() {
  const hotjar = useHotjar();

  const isWebsocketOnline = useServerStreaming();
  const isOnline = useIsOnline();
  useOnlineStatus(isOnline, isWebsocketOnline);

  useLoggerInitialization(() => {
    hotjar.event(HotjarEvents.ErrorLogged);
  });

  const { isLoading: isLoadingAuth0User } = useAuthenticationContext();

  // This is the only usage that will actually trigger an api call to retrieve the logged in user,
  // (unless the react query cache was cleared or a window focus event happened)
  // The rest of the usages of useCurrentUser will use the cached value
  const {
    me,
    endUser,
    isInitialLoading: isLoadingMe,
  } = useCurrentUser({
    queryOptions: {
      onSuccess: (currentUser) => {
        if (currentUser) {
          logger.addCustomVariable('userEmail', currentUser.email);
          hotjar.identify(currentUser.email, { organization: currentUser.organization.name });
        }
      },
    },
  });

  useScreenResolutionAlert(!!me);

  const isLoadingUser = isLoadingAuth0User || isLoadingMe;
  const isAnonymous = !isLoadingUser && !me;
  const isRetailUser = !isAnonymous && PORTAL_SITE_ROLES.includes(endUser?.role as UserRole);

  return (
    <ErrorBoundary FallbackComponent={GlobalErrorPage}>
      <AppStoreProvider>
        <AppPubSubProvider>
          <InformationTooltipProvider>
            <CssBaseline />
            <FeatureGate feature={Features.EndUserIndicator}>
              <EndUserIndicator />
            </FeatureGate>
            <Box
              sx={AppWrapperStyles({
                isRetailUser,
              })}
            >
              {isRetailUser ? <RetailerTopBar /> : <SideBar sx={{ gridArea: AppLayoutParts.SideBarLayout }} />}
              <Box
                component="main"
                sx={{
                  gridArea: AppLayoutParts.MainLayout,
                  overflow: 'auto',
                }}
              >
                {!isLoadingUser ? <Router me={me} routes={routes} /> : null}
              </Box>
            </Box>
            <Backdrop open={isLoadingUser} sx={{ bgcolor: 'common.white', color: 'primary.main' }}>
              <AppLoader />
            </Backdrop>
            <ReactQueryDevtools position="bottom-right" />
            <DevIndicator />
          </InformationTooltipProvider>
        </AppPubSubProvider>
      </AppStoreProvider>
    </ErrorBoundary>
  );
}
