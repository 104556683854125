import { CoverageLine } from '../enums';

export const plCoverageLinesMapping = [
    CoverageLine.AccountantsPl,
    CoverageLine.EmploymentOrganizationsPl,
    CoverageLine.InsuranceAgentsBrokersEnO,
    CoverageLine.ManufacturersEnO,
    CoverageLine.LawyersProfessionalLiability,
    CoverageLine.FinancialAdvisorsInvestmentAdvisorsPl,
    CoverageLine.MediaMultimedia,
    CoverageLine.FranchisorsEnO,
    CoverageLine.Rnd,
    CoverageLine.MiscellaneousProfessionalLiability,
    CoverageLine.ArchitectEngineersPl,
    CoverageLine.ContractorsPollutionsLiability,
    CoverageLine.AlliedHealthcareProfessionalLiability,
];
