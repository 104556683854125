export const messages = {
    emailVariables: {
        continueInFlow: 'Continue in Flow',
        openQuoteInFlow: 'Open quote in Flow',
        here: 'here',
    },
    emailLabels: {
        Acknowledgment: 'Acknowledgment',
        Declination: 'Declination',
        InformationRequest: 'Information Request',
        Quote: 'Quote',
        Indication: 'Indication',
        LossRuns: 'Loss Runs',
        SubmissionBox: 'Submission Email',
        Policy: 'Policy',
        Binder: 'Binder',
        SurplusDocuments: 'Surplus Documents',
        Correspondence: 'Correspondence',
        SubmissionMarketing: 'Marketing',
        Proposal: 'Proposal',
        TowerUpdate: 'Tower Update',
        MarketingUpdate: 'Marketing Update',
        BinderRequested: 'Binder Order',
        PolicyRequested: 'Policy Requested',
        SubmissionUpdate: 'Market Submission Update',
        CustomerFollowup: 'Customer Follow-up',
        MarketFollowup: 'Market Follow-up',
        CannotPursue: 'Can Not Pursue',
        PositiveExpectation: 'Positive Expectation',
        AdditionalInfo: 'Additional Information',
        PaymentRequested: 'Payment Requested',
    },
    fileTypesLabels: {
        ExpiringProgram: 'Expiring Policy',
        Submission: 'Application',
        MarketQuote: 'Market Quote',
        CapitolaQuote: 'Flow Quote',
        MarketBinder: 'Market Binder',
        CapitolaBinder: 'Flow Binder',
        MarketingReport: 'Marketing Report',
        ConceptualTower: 'Conceptual Tower',
        Policy: 'Market Policy',
        FlowPolicy: 'Flow Policy',
        PolicyCoverLatter: 'Policy Cover Letter',
        CustomerEmail: 'Customer Email',
        MarketingEmail: 'Marketing Email',
        PastApplication: 'Application - Prior',
        LossRuns: 'Loss Runs',
        Supplement: 'Supporting documents',
        Proposal: 'Proposal',
        ReplyEmail: 'Reply Email',
        InboundEmail: 'Inbound Email',
        RansomwareApplication: 'Ransomware Application',
        MarketInvoice: 'Market Invoice',
        CapitolaInvoice: 'Flow Invoice',
        Financials: 'Financials',
        SignedApplication: 'Signed Application',
        DueDiligence: 'Affidavit / Due Diligence',
        Declination: 'Declination',
        OtherSurplusForms: 'Other Surplus Lines Forms',
        PolicySpecimen: 'Policy Specimen',
        Endorsement: 'Endorsement',
        BOR: 'BOR',
        QuoteComparison: 'Quote Comparison',
        Subjectivities: 'Subjectivity(s)',
        NoLossLetter: 'No Loss Letter',
        CarrierSurplusForm: 'Carrier Surplus Form',
        AOR: 'AOR',
        StateCompliance: 'State Compliance',
    },
    notifications: {
        type: {
            followUp: 'Follow up',
            proposal: 'Proposal',
            acknowledgment: 'Acknowledgement',
            marketingUpdate: 'Marketing Update',
        },
        assignee: {
            agent: 'Agent',
            underwriter: 'Underwriter',
        },
    },
    coverageLine: {
        publicDnO: 'Public Directors & Officers',
        privateDnO: 'Private Directors & Officers',
        nonProfitDnO: 'Non-Profit Directors & Officers',
        eno: 'Errors & Omissions',
        cyber: 'Cyber',
        epl: 'Employment Practices Liability',
        property: 'Property',
        casualty: 'Casualty',
        KidnapRansom: 'Kidnap & Ransom',
        Fiduciary: 'Fiduciary Liability',
        Crime: 'Crime',
        managementLiability: 'Management Liability',
        professionalLiability: 'Professional Liability',
        CommercialAutomobile: 'Commercial Automobile',
        ExcessCommercialAuto: 'Excess Commercial Auto',
        ExcessLiability: 'Excess Liability',
        ExcessUmbrella: 'Excess Umbrella',
        GeneralLiability: 'General Liability',
        GeneralLiabilityAndPollutionLiability: 'General Liability & Pollution Liability',
        GeneralLiabilityAndProfessionalLiability: 'General Liability & Professional Liability',
        ProductsLiability: 'Products Liability',
        ProductsRecallLiability: 'Products Recall Liability',
        Umbrella: 'Umbrella',
        WorkersCompOutOfState: "Workers' Compensation Out of State",
        WorkersCompensation: "Workers' Compensation",
        PollutionLiability: 'Pollution Liability',
        HiredNonOwnedAuto: 'Hired Non Owned Auto',
        MiscellaneousCommercial: 'Miscellaneous Commercial',
        BoilerAndMachinery: 'Boiler & Machinery',
        BuildersRiskInstallation: 'Builders Risk/Installation',
        Cargo: 'Cargo',
        DICDifferenceInCondition: 'Side A Difference in Conditions',
        EarthquakeCommercial: 'Earthquake-Commercial',
        EquipmentFloater: 'Equipment Floater',
        ExcessProperty: 'Excess Property',
        MotorTruckCargo: 'Motor Truck Cargo',
        OceanMarine: 'Ocean Marine',
        Terrorism: 'Terrorism',
        FloodCommercial: 'Flood Commercial',
        ExportImportNavigationTransitOfGoodsSPECIAL: 'Export/Import-navigation-transit of goods-SPECIAL',
        ForeignPackagePolicy: 'Foreign Package Policy',
        BusinessAndManagementPackage: 'Business & Management Package',
        DirectorsAndOfficersLiability: "Director's & Officer's Liability",
        ElectronicDataProcessing: 'Electronic Data Processing',
        ERISAOnCrimePolicyOrERISABond: 'ERISA On Crime Policy Or ERISA Bond',
        ExcessDirectorsAndOfficersLiability: 'Excess Directors & Officers Liability',
        BoatCommercial: 'Boat-Commercial',
        GarageAndDealers: 'Garage And Dealers',
        MarineLiability: 'Marine Liability',
        ExcessMarineLiability: 'Excess Marine Liability',
        CommercialPackage: 'Commercial Package',
        ExcessProfessionalLiability: 'Excess Professional Liability',
        Bond: 'Bond',
        BusinessOwnersPackage: 'Business Owners Package',
        EmployedLawyersLiability: 'Employed Lawyers Liability',
        MiscellaneousProfessionalLiability: 'Miscellaneous Professional Liability',
        AccountantsPl: 'Accountants PL (APL)',
        ArchitectEngineersPl: 'Architect & Engineers PL (A&E)',
        ContractorsProfessional: "Contractor's Professional",
        EmploymentOrganizationsPl: 'Employment Organizations PL',
        InsuranceAgentsBrokersEnO: 'Insurance Agents & Brokers E&O (IAB)',
        ManufacturersEnO: 'Manufacturers E&O',
        LawyersProfessionalLiability: 'Lawyers Professional Liability (LPL)',
        FinancialAdvisorsInvestmentAdvisorsPl: 'Financial Advisors / Investment Advisors PL',
        MediaMultimedia: 'Media/Multimedia',
        TechnologyEnO: 'Technology E&O',
        FranchisorsEnO: 'Francisors E&O',
        Rnd: 'R&D (Research & Development)',
        SpecifiedProfessionalLiability: 'Specified Professional Liability (Other)',
        PhysiciansMedicalMalpractice: 'Physicians Medical Malpractice',
        AlliedHealthcareProfessionalLiability: 'Allied Healthcare Professional Liability',
        ManagementLiability: 'Management Liability',
        PrivateEmploymentPracticesLiability: 'Private Employment Practices Liability',
        PrivateCrime: 'Private Crime',
        PrivateFiduciary: 'Private Fiduciary',
        PublicEmploymentPracticesLiability: 'Public Employment Practices Liability',
        PublicCrime: 'Public Crime',
        PublicFiduciary: 'Public Fiduciary',
        NonProfitEmploymentPracticesLiability: 'Non profit  Employment Practices Liability',
        NonProfitCrime: 'Non profit Crime',
        NonProfitFiduciary: 'Non profit Fiduciary',
        ExcessManagementLiability: 'Excess Management Liability',
        ExcessCyber: 'Excess Cyber',
        EnvironmentalLiability: 'Environmental Liability',
        EnvironmentalProfessionalsPackage: 'Environmental Professionals Package',
        ContractorsPollutionsLiability: 'Contractors Pollutions Liability',
        StorageTankPollutionLiability: 'Storage Tank Pollution Liability',
        SitePollutionLiability: 'Site Pollution Liability',
        UniqueOfferings: 'Unique Offerings',
        IntellectualProperty: 'Intellectual Property (IP)',
        SexualAbuseMolestation: 'Sexual Abuse & Molestation (SAM)',
        KidnapRansomExecutiveProtection: 'Kidnap & Ransom or Executive Protection (K&R)',
        TerrorismActiveAssailantShooter: 'Terrorism / Active Assailant/Shooter',
        AccountsReceivable: 'Accounts Receivable',
        RealEstateDevelopersPL: 'Real Estate Developers PL',
        TenantDiscrimination: 'Tenant Discrimination',
        EnvironmentalProfessionalsPL: 'Environmental Professionals PL',
        MedicalBillersEnO: 'Medical Billers E&O',
        LifeSciencesPL: 'Life Sciences PL',
    },
};
