import { isEmpty } from 'lodash';
import { matchSorter } from 'match-sorter';
import { HTMLAttributes, memo, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Autocomplete,
  Box,
  FormControl,
  FormFieldValidationHelper,
  FormHelperText,
  Stack,
  Typography,
} from '@common-components';
import { useMount } from 'hooks';
import { messages } from 'i18n';
import { FormControlHelperTextMarginBottom, FormControlPaddingBottom } from 'themes';
import { normalizeError } from 'utils';
import FormFieldSuggestionOverlay from 'components/FormFieldSuggestionOverlay';
import InputLabel from 'components/hookFormComponents/InputLabel';
import { AutocompleteStyles, FormHelperTextStyles } from 'components/hookFormComponents/styles';
import { SuggestionProps, SuggestionValidation } from 'components/hookFormComponents/types';
import { AutocompleteOption, ElevatedPaperComponent, getOptionLabel, getOptionValue, renderInput } from './utils';

export interface AutocompleteCreatableOption extends AutocompleteOption {
  inputValue?: string;
  isAddNewOption?: boolean;
}

export type InnerValueSetter = { setInnerValue: (value: string) => void };

export interface FormAutocompleteCreatableProps<T extends AutocompleteCreatableOption> {
  name: string;
  label: string;
  id?: string;
  isLoading?: boolean;
  // Default value is the value field of one of the provided options
  defaultValue?: string;
  placeholder?: string;
  withTooltip?: boolean;
  fullWidth?: boolean;
  validateOnChange?: boolean;
  disabled?: boolean;
  optional?: boolean;
  autoHighlight?: boolean;
  autoSelect?: boolean;
  options: T[];
  getOptionDisabled?: (option: T) => boolean;
  groupBy?: (option: T) => string;
  onChange?: (value?: string | null) => void;
  suggestion?: SuggestionProps;
  suggestionValidation?: SuggestionValidation;
}

// Note that 'option' refers to any of the options provided in the 'options' prop, and 'value' refers to the currently chosen option
export const isOptionEqualToValue = (option: AutocompleteCreatableOption, value: AutocompleteCreatableOption) =>
  option.value === value.value;

const FormAutocompleteCreatable = <T extends AutocompleteCreatableOption>({
  name,
  label = 'Select',
  id,
  isLoading,
  defaultValue,
  withTooltip,
  placeholder = messages.general.selectPlaceholder,
  fullWidth = true,
  optional = false,
  disabled = false,
  validateOnChange = false,
  autoHighlight,
  autoSelect = true,
  options,
  getOptionDisabled,
  groupBy,
  onChange,
  imperativeRef,
  suggestion,
  suggestionValidation,
  ...props
}: FormAutocompleteCreatableProps<T> & { imperativeRef?: Ref<InnerValueSetter> }) => {
  const labelId = `${name}-label`;
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useFormContext();
  const suggestedOption = suggestion?.value ? options.find((option) => option.value === suggestion.value) : undefined;
  const [ownSuggestion, setOwnSuggestion] = useState<SuggestionProps | undefined>(suggestion);
  const errorMessage = normalizeError(errors, name);

  const fieldValue = getValues(name);

  const [inputValue, setInputValue] = useState('');
  const [ownValue, setOwnValue] = useState<T | null>(null);

  useEffect(() => {
    setOwnSuggestion(suggestion);
  }, [suggestion]);

  const resetOwnSuggestion = () => {
    setOwnSuggestion(undefined);
    suggestionValidation?.suggestionValidationCallback?.();
  };

  const formattedSuggestion = ownSuggestion ? suggestedOption?.label || ownSuggestion?.value : undefined;

  const renderOption = (htmlProps: HTMLAttributes<HTMLElement>, option: T) => (
    <Stack
      {...htmlProps}
      style={{ padding: '8px 16px', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row' }}
    >
      <Typography
        color={option.isAddNewOption ? 'primary.main' : 'text.primary'}
        fontWeight={option.isAddNewOption ? 'bold' : 'regular'}
        variant="body2"
        mr={option.subLabel ? 1 : 0}
      >
        {option.label}
      </Typography>
      {option.subLabel && (
        <Typography variant="body2" color="text.secondary" noWrap>
          {option.subLabel}
        </Typography>
      )}
    </Stack>
  );

  const setInnerValue = (value: string) => {
    setOwnValue({
      label: value,
      value,
      inputValue: value,
    } as T);
  };

  // This is to set the default value of the form field
  // MUI Autocomplete has defaultValue prop, but it is respected only when the component is not controlled
  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  // checking that if there is a value in mount (meaning default value) then if it is not from the options list then update state that it is a creatable option
  useMount(() => {
    const optionMatch = options.find((option) => fieldValue === option.value);
    if (fieldValue && !optionMatch) {
      setOwnValue({
        label: fieldValue,
        value: fieldValue,
        inputValue: fieldValue,
      } as T);
    }
  });

  useImperativeHandle(imperativeRef, () => ({ setInnerValue }));
  return (
    <FormControl
      sx={{ pb: FormControlPaddingBottom, mb: errorMessage ? FormControlHelperTextMarginBottom : 0 }}
      fullWidth={fullWidth}
    >
      <InputLabel
        showSuggestionValidation={suggestionValidation?.showSuggestionValidation && !!ownSuggestion}
        suggestionValidationError={suggestionValidation?.suggestionValidationError}
        id={labelId}
        error={!!errorMessage}
        label={label}
        optional={optional}
        htmlFor={name}
      />
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange: formHookOnChange, value, ...fieldProps } }) => (
          <Box position="relative">
            <Autocomplete
              {...fieldProps}
              size="small"
              id={id}
              value={options.find((option) => getOptionValue(option) === value) || ownValue}
              onChange={(_event, newValue) => {
                // If a user types and presses tab/enter rather than selecting an option
                if (typeof newValue === 'string') {
                  // If the option already exists in a different capitalization we don't want to duplicate it
                  const optionMatchingInput = options.find(
                    (option) => inputValue.toLowerCase() === option.label.toLowerCase(),
                  );

                  if (optionMatchingInput) {
                    setOwnValue(optionMatchingInput);
                    formHookOnChange(getOptionValue(optionMatchingInput));
                  } else {
                    setOwnValue({
                      label: newValue,
                      value: newValue,
                      inputValue: newValue,
                    } as T);

                    formHookOnChange(newValue);
                    onChange?.(newValue);
                  }
                  // If a user selects a creatable option the dropdown
                } else if (newValue?.inputValue) {
                  // Create a new value from the user input
                  setOwnValue({
                    label: newValue.inputValue,
                    value: newValue.inputValue,
                    inputValue: newValue.inputValue,
                  } as T);

                  formHookOnChange(newValue.inputValue);
                  onChange?.(newValue.inputValue);
                } else {
                  // If the user selects a regular option from the dropdown
                  setOwnValue(newValue);
                  formHookOnChange(getOptionValue(newValue));
                  onChange?.(getOptionValue(newValue));
                }
                if (errorMessage || validateOnChange) {
                  trigger(name);
                }
              }}
              // eslint-disable-next-line @typescript-eslint/no-shadow
              filterOptions={(options) => {
                const filtered = matchSorter(options, inputValue, { keys: ['label'] });
                if (
                  inputValue.length > 2 &&
                  isEmpty(options.filter((option) => option.label === inputValue)) &&
                  options
                ) {
                  filtered.unshift({
                    label: messages.formAutoComplete.addItem(inputValue),
                    value: '',
                    isAddNewOption: true,
                    inputValue,
                  } as T);
                }
                return filtered;
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              autoSelect={autoSelect}
              freeSolo
              selectOnFocus
              clearOnBlur
              blurOnSelect
              openOnFocus
              handleHomeEndKeys
              autoHighlight={autoHighlight}
              sx={AutocompleteStyles}
              disabled={disabled}
              loading={isLoading}
              fullWidth={fullWidth}
              options={options}
              getOptionLabel={getOptionLabel}
              getOptionDisabled={getOptionDisabled}
              isOptionEqualToValue={isOptionEqualToValue}
              groupBy={groupBy}
              renderInput={(params) =>
                renderInput({ params: { ...params, error: !!errorMessage, placeholder }, withTooltip })
              }
              PaperComponent={ElevatedPaperComponent}
              renderOption={renderOption}
              {...props}
            />
            {ownSuggestion?.value && formattedSuggestion && (
              <FormFieldSuggestionOverlay
                text={formattedSuggestion}
                onClick={() => {
                  resetOwnSuggestion();
                }}
              />
            )}
          </Box>
        )}
      />
      {errorMessage && (
        <FormHelperText error sx={FormHelperTextStyles}>
          {errorMessage}
        </FormHelperText>
      )}

      {ownSuggestion?.reason && (
        <FormFieldValidationHelper
          reason={ownSuggestion.reason}
          suggestionValidationCallback={() => {
            resetOwnSuggestion();
          }}
          showSuggestionValidation={suggestionValidation?.showSuggestionValidation}
        />
      )}
    </FormControl>
  );
};

// This type casting is required due to memo not being able to forward generics type parameters
export default memo(FormAutocompleteCreatable) as typeof FormAutocompleteCreatable;
