import { isEmpty } from 'lodash';
import {
  Dispatch,
  FC,
  forwardRef,
  Fragment,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { Divider, LoaderOverlay, Stack, Typography } from '@common-components';
import { ForbiddenError } from 'clients/errors/ForbiddenError';
import { HeraldApiError, HeraldApplication, HeraldFormType, QuoteExits } from 'clients/types';
import { CoverageLine } from 'enums';
import {
  ExtractedDataFields,
  useBoolean,
  useFormProvider,
  useHeraldApplicationApi,
  useSearchInsuranceProduct,
  useToast,
} from 'hooks';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { logger, triggerCustomError } from 'utils';
import FormArrayFields from 'components/hookFormComponents/FormArrayFields';
import { FormArrayFieldProps } from 'components/hookFormComponents/FormArrayFields/FormArrayFields';
import { SuggestionProps } from 'components/hookFormComponents/types';
import FormWithStepper from 'broker/components/common/FormWithStepper';
import ScrollFormIndicator from 'broker/components/common/ScrollFormIndicator';
import SubmissionFooter from 'broker/components/common/SubmissionFooter';
import { MainAreaDimensionsState } from 'broker/components/common/VerticalFormStepper/types';
import { useScrollButtonVisibility } from 'broker/hooks';
import { AIReason } from 'broker/pages/SubmissionWorkspacePage/components/AIGeneratedContent/AIReason';
import { OnHeraldApplicationInternalUpdateParams } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/types';
import CoverageLinesChips from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/components/CoverageLinesChips';
import { SetNextStepsProps } from 'broker/pages/SubmissionWorkspacePage/components/NestedViews/EditSubmissionNew/useSetNextStep';
import HeraldFormArrayFields, {
  BaseHeraldFormArrayFieldsProps,
  BaseHeraldFormFieldsValue,
  defaultHeraldAddressArrayValue,
  defaultHeraldClaimEventArrayValue,
} from './components/HeraldFormArrayFields';
import mapFormStateToHerald from './mappers/map-form-state-to-herald';
import mapHeraldParameterToFormComponent from './mappers/map-herald-parameter-to-form-component';
import mapHeraldParametersToYupShape from './mappers/map-herald-parameters-to-yup-shape';
import {
  DynamicFormImperativeHandle,
  FormStateBaseProps,
  HeraldApplicationNormalized,
  HeraldFormState,
  HeraldNormalizedParameter,
} from './types';
import { useGetHeraldComputedData } from './useGetHeraldComputedData';
import { useGetSectionsIndicators } from './useGetSectionsIndicators';
import { getExtractionValidationFields } from './utils/extraction-validation-fields';
import {
  formValidationErrorExceptionMessage,
  getNormalizeHeraldFormStateValues,
  scrollToFirstSuggestion,
} from './utils/form-utils';
import { heraldInputTypesConfig } from './utils/herald-input-types-config';
import { useGetAnsweredHeraldParametersBySections } from './utils/useGetAnsweredHeraldParametersBySections';
import { getHeraldServerErrorFieldMessage } from './utils/validations-utils';

interface DynamicFormProps {
  onUpdate: (onHeraldApplicationUpdateParams: OnHeraldApplicationInternalUpdateParams) => Promise<void>;
  heraldApplication: HeraldApplication;
  heraldApplicationNormalized: HeraldApplicationNormalized;
  formType: HeraldFormType;
  setIsDirty?: (isDirty: boolean) => void;
  activeSection?: string;
  setActiveSection: (section?: string) => void;
  onClose: () => void;
  firstVisit: boolean;
  mainAreaDimensionsState?: MainAreaDimensionsState;
  setScrollPositionOnMount?: (number?: number) => void;
  scrollPositionOnMount?: number;
  setScrollPosition?: (number?: number) => void;
  scrollPosition?: number;
  isAcknowledgmentFlow: boolean;
  submissionExtractedData?: ExtractedDataFields;
  applySuggestionsOnEmptyFields?: boolean;
  sectionRefs: MutableRefObject<Record<string, HTMLDivElement | null>>; // New prop added here
  scrollableDivRef: MutableRefObject<HTMLDivElement | null>;
  setNextStep: (props?: SetNextStepsProps) => void;
  coverageLines?: CoverageLine[];
  quoteExits?: QuoteExits[];
  submission: PartialSubmission;
  extractionValidationFields: Record<string, boolean>;
  setExtractionValidationFields: Dispatch<SetStateAction<Record<string, boolean>>>;
}

export const DynamicForm = forwardRef<DynamicFormImperativeHandle, DynamicFormProps>(
  (
    {
      submissionExtractedData,
      heraldApplication,
      heraldApplicationNormalized,
      formType,
      onUpdate,
      setIsDirty,
      activeSection,
      setActiveSection,
      onClose,
      mainAreaDimensionsState,
      scrollPositionOnMount,
      setScrollPositionOnMount,
      scrollPosition,
      setScrollPosition,
      isAcknowledgmentFlow,
      firstVisit,
      applySuggestionsOnEmptyFields,
      sectionRefs,
      scrollableDivRef,
      quoteExits,
      setNextStep,
      coverageLines,
      submission,
      extractionValidationFields,
      setExtractionValidationFields,
    },
    ref,
  ) => {
    const { showToast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const buttonClickedRef = useRef(false);
    const { updateApplication } = useHeraldApplicationApi();

    const { items: products } = useSearchInsuranceProduct();

    const [highlightValidationRequired, { on: highlightValidationRequiredOn, off: highlightValidationRequiredOff }] =
      useBoolean(false);

    const { showScrollButton, handleScrollDown } = useScrollButtonVisibility({ ref: scrollableDivRef, sectionRefs });

    const { filteredHeraldParameters, defaultValues, heraldParametersBySections, sectionList } =
      useGetHeraldComputedData({ formType, heraldApplicationNormalized, submission });

    const { methods, appliedSuggestions } = useFormProvider({
      schema: mapHeraldParametersToYupShape(filteredHeraldParameters),
      defaultValues,
      setIsDirty,
      suggestedValues: submissionExtractedData,
      applySuggestionsOnEmptyFields,
    });

    const resolvedAppliedSuggestions = useMemo(
      () =>
        Object.entries(appliedSuggestions).reduce((acc, [key, value]) => {
          acc[key] = {
            ...value,
            reason: <AIReason suggestion={value} />,
          };
          return acc;
        }, {} as Record<string, SuggestionProps>),
      [appliedSuggestions],
    );

    // set extractionValidationFields to false for all the fields that are required and have not been answered
    useEffect(() => {
      if (!isAcknowledgmentFlow) {
        return;
      }

      const validationFields = getExtractionValidationFields(coverageLines || []).reduce(
        (acc, extractionValidationField) => {
          // check if extractionValidationField exist in resolvedAppliedSuggestions

          if (extractionValidationField in resolvedAppliedSuggestions) {
            acc[extractionValidationField] = false;
          }
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setExtractionValidationFields((prev) =>
        // build object with keys from validationFields, if the key exists in prev, use the value from prev, otherwise use the value from validationFields
        Object.keys(validationFields).reduce((acc, key) => {
          acc[key] = key in prev ? prev[key] : validationFields[key];
          return acc;
        }, {} as Record<string, boolean>),
      );
    }, [coverageLines, isAcknowledgmentFlow, resolvedAppliedSuggestions, setExtractionValidationFields]);

    const watchedFormValues = methods.watch();

    const answeredHeraldParametersBySections = useGetAnsweredHeraldParametersBySections({
      formValues: watchedFormValues,
      heraldParametersBySections,
    });

    const formValues = methods.getValues();

    const { sectionsWithErrors } = useGetSectionsIndicators({
      activeSection,
      methods,
      sectionList,
      answeredHeraldParametersBySections,
      filteredHeraldParameters,
      firstVisit,
    });

    useLayoutEffect(() => {
      if (typeof scrollPosition !== 'undefined') {
        // After submission is complete, scroll back to the stored position
        scrollableDivRef.current?.scrollTo({ top: scrollPosition, behavior: 'instant' });
        setScrollPosition?.(undefined);
      }
    }, [scrollPosition, scrollableDivRef, setScrollPosition]);

    useLayoutEffect(() => {
      if (typeof scrollPositionOnMount !== 'undefined') {
        // Scroll the div to the stored position
        scrollableDivRef.current?.scrollTo({ top: scrollPositionOnMount, behavior: 'instant' });
        // Reset the scroll position without triggering re-renders
        if (scrollPositionOnMount !== undefined) {
          setScrollPositionOnMount?.(undefined); // Ensure this line does not cause re-renders
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data: HeraldFormState, displayToast = true) => {
      setIsSubmitting(true);
      const currentPosition = scrollableDivRef.current?.scrollTop;
      setScrollPositionOnMount?.(currentPosition);
      if (setIsDirty) {
        setIsDirty(false);
      }

      // normalizing the form values was taken out of mapFormStateToHerald, so it can be used outside the onSubmit function (once the values are sent to the update submission)
      const normalizeHeraldFormStateValues = getNormalizeHeraldFormStateValues(data, filteredHeraldParameters);
      const formStateToHerald = mapFormStateToHerald(normalizeHeraldFormStateValues, filteredHeraldParameters);
      try {
        const response = await updateApplication.mutateAsync({
          applicationId: heraldApplication.id,
          heraldApplication: {
            products: heraldApplication.products,
            ...formStateToHerald,
          },
        });

        if (response?.application) {
          await onUpdate({
            app: response.application,
            displayToast,
            previousAppState: heraldApplicationNormalized,
            quoteExits: response.quote_exits,
            formState: normalizeHeraldFormStateValues,
          });

          setIsSubmitting(false);
        }
      } catch (e: any) {
        setIsSubmitting(false);
        if (setIsDirty) {
          setIsDirty(true);
        }
        if (e instanceof ForbiddenError) {
          showToast('error', { message: e.message });
        } else {
          const errorFieldMessage = getHeraldServerErrorFieldMessage(e as HeraldApiError, formStateToHerald);
          if (errorFieldMessage) {
            triggerCustomError(methods, errorFieldMessage.fieldName, errorFieldMessage.message);
          } else {
            showToast('error', { message: messages.editSubmission.heraldAPiError });
            logger.log('error', { message: messages.heraldForm.failedToUpdateApplication, error: e, data });
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
        throw e;
      }
    };

    const getFormArrayDefault = (heraldNormalizedParameter: HeraldNormalizedParameter): BaseHeraldFormFieldsValue => {
      const config = heraldInputTypesConfig[heraldNormalizedParameter.input_type];
      if (config.fieldType === 'Address') {
        return defaultHeraldAddressArrayValue;
      }
      if (config.fieldType === 'ClaimEvent') {
        return defaultHeraldClaimEventArrayValue;
      }
      return {
        main: '',
      };
    };

    const renderFormComponent = (
      heraldNormalizedParameter: HeraldNormalizedParameter,
      fieldName: string,
    ): JSX.Element => {
      const onBlur = () => {
        if (buttonClickedRef.current) {
          return false;
        }
        const hasErrors = !isEmpty(methods.formState.errors);
        if (heraldNormalizedParameter.affects_conditions && !hasErrors) {
          methods.handleSubmit(async (data) => {
            await onSubmit(data, false);
          })();
        }
        return true;
      };
      if (heraldNormalizedParameter.arrayElements) {
        return (
          <Stack key={heraldNormalizedParameter.parameter_id}>
            <FormArrayFields
              key={fieldName}
              name={fieldName}
              defaultValue={getFormArrayDefault(heraldNormalizedParameter)}
              FormArrayFieldComponent={HeraldFormArrayFields as FC<FormArrayFieldProps>}
              additionalProps={
                {
                  heraldNormalizedParameter,
                  extractionValidationFields,
                  setExtractionValidationFields,
                  highlightValidationRequired,
                  onBlur,
                  resolvedSuggestion: resolvedAppliedSuggestions[fieldName],
                } as BaseHeraldFormArrayFieldsProps
              }
            />
          </Stack>
        );
      }

      const jsx = mapHeraldParameterToFormComponent(
        heraldNormalizedParameter,
        formValues[fieldName] as FormStateBaseProps,
        {
          name: fieldName,
          label: heraldNormalizedParameter.fieldLabel,
        },
        extractionValidationFields,
        setExtractionValidationFields,
        highlightValidationRequired,
        onBlur,
        undefined,
        resolvedAppliedSuggestions[fieldName],
      );
      const childJsx = heraldNormalizedParameter.childValues?.map((childValue) =>
        renderFormComponent(childValue, `${fieldName}.children.${childValue.parameter_id}`),
      );

      return (
        <Fragment key={heraldNormalizedParameter.parameter_id}>
          {jsx}
          {childJsx || null}
        </Fragment>
      );
    };

    const buildSectionWithQuestionAnsweredSubTitle = useCallback(
      (section: string) => {
        // this function is called also with activeSection that can still contain a section that has been removed, until it resets in useEffect
        // anyway it is a good convention to check if the section is still present in the answeredHeraldParametersBySections
        if (!answeredHeraldParametersBySections[section]) {
          return '';
        }
        const unanswered =
          answeredHeraldParametersBySections[section].total - answeredHeraldParametersBySections[section].answered;

        if (unanswered === 0) {
          return '';
        }

        return `${messages.general.unanswered}: ${unanswered}`;
      },
      [answeredHeraldParametersBySections],
    );

    const sliderItems = useMemo(
      () =>
        sectionList.map((item) => {
          // Leverage the buildSectionWithQuestionAnsweredSubTitle to infer if a section has required fields
          const sectionSubtitle = buildSectionWithQuestionAnsweredSubTitle(item);
          const sectionHasRequired = sectionSubtitle !== ''; // If subtitle is not empty, there are unanswered required fields

          return {
            label: item,
            subLabel: sectionSubtitle, // This displays the unanswered required fields count
            id: item,
            hasError: sectionsWithErrors.includes(item), // Mark as error if there are form validation errors
            hasRequired: sectionHasRequired, // Mark as required if there are unanswered required fields
            isCompleted:
              answeredHeraldParametersBySections[item]?.answered === answeredHeraldParametersBySections[item]?.total, // Mark as completed if all required fields are answered
          };
        }),
      [answeredHeraldParametersBySections, buildSectionWithQuestionAnsweredSubTitle, sectionList, sectionsWithErrors],
    );

    const submitForm = async () => {
      buttonClickedRef.current = true;
      try {
        await new Promise((resolve, reject) => {
          methods.handleSubmit(
            async (data) => {
              try {
                const response = await onSubmit(data);
                resolve(response);
              } catch (e) {
                reject(e);
              }
            },
            () => {
              reject(new Error(formValidationErrorExceptionMessage));
            },
          )();
        });
      } finally {
        buttonClickedRef.current = false;
      }
    };

    useImperativeHandle(ref, () => ({ submitForm })); // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return (
      <FormProvider {...methods}>
        <CoverageLinesChips
          heraldProducts={heraldApplication.products}
          insuranceProducts={products}
          quoteExits={quoteExits}
          coverageLines={coverageLines!}
          setNextStep={setNextStep}
          sx={{
            px: 3,
            pt: 1,
          }}
        />
        <Stack overflow="hidden" height={1}>
          <FormWithStepper
            sectionProps={{
              activeSection,
              setActiveSection,
              sectionList: sliderItems,
            }}
            ref={scrollableDivRef}
            mainAreaDimensionsState={mainAreaDimensionsState}
            withPadding
          >
            {isSubmitting && <LoaderOverlay />}
            <Stack divider={<Divider sx={{ mt: 2, mb: 5 }} />}>
              {sectionList.map((section) => (
                <Stack
                  key={section}
                  ref={(el) => {
                    sectionRefs.current[section] = el as HTMLDivElement | null; // Type assertion for el
                  }} // Attach the ref for each section
                >
                  <Typography variant="subtitle2" mb={2}>
                    {section}
                  </Typography>
                  {heraldParametersBySections[section].map((filteredHeraldParameter) =>
                    renderFormComponent(filteredHeraldParameter, filteredHeraldParameter.parameter_id),
                  )}
                </Stack>
              ))}
            </Stack>
            <SubmissionFooter
              isSubmitting={methods.formState.isSubmitting}
              onSubmit={async () => {
                try {
                  // if extractionValidationFields has any false value, it means that the user has not answered all the required fields
                  if (Object.values(extractionValidationFields).some((value) => !value)) {
                    scrollToFirstSuggestion();
                    highlightValidationRequiredOn();
                    return;
                  }
                  highlightValidationRequiredOff();
                  await submitForm();
                  onClose();
                } catch (e: any) {
                  // just catch the error, so it doesn't log to the console form validation error
                  if (e.message !== formValidationErrorExceptionMessage) {
                    throw e;
                  }
                }
              }}
              active={activeSection}
              setActiveSection={setActiveSection}
              list={sectionList}
              submitButtonText={messages.general.nextAddRiskAssessment}
              isAcknowledgmentFlow={isAcknowledgmentFlow}
            />
          </FormWithStepper>
          {showScrollButton && <ScrollFormIndicator handleScrollDown={handleScrollDown} />}
        </Stack>
      </FormProvider>
    );
  },
);

export default DynamicForm;
