import { isValidDomain } from '@common/utils';
import { isString, toNumber } from 'lodash';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { TestContext } from 'yup';
import { MAX_INTEGER } from 'config';
import { messages } from 'i18n';
import { formatBigNumber, formatPrice } from './money-utils';
import { scrollToElement } from './ui-utils';

const customErrorType = 'customErrorType';

interface CustomYupValidateNumberOptions {
  fieldName: string;
  allowDecimal?: boolean;
  allowZero?: boolean;
  minNumber?: number;
  maxNumber?: number;
}

export function customYupValidateNumber({
  fieldName,
  allowDecimal = true,
  allowZero = false,
  maxNumber,
  minNumber,
}: CustomYupValidateNumberOptions) {
  return (value: string, context: TestContext) => {
    const { path, createError } = context;

    const number = Number(value);

    if (value && (number < 0 || (!allowZero && number === 0))) {
      return createError({
        path,
        message: messages.formMessages.positiveOnly(fieldName),
      });
    }

    if (value && typeof minNumber !== 'undefined' && toNumber(value) < minNumber) {
      return createError({
        path,
        message: messages.formMessages.minimumLimit(fieldName, formatBigNumber(minNumber)),
      });
    }

    if (value && typeof maxNumber !== 'undefined' && toNumber(value) > maxNumber) {
      return createError({
        path,
        message: messages.formMessages.maximumLimit(fieldName, formatBigNumber(maxNumber)),
      });
    }

    if (!allowDecimal) {
      if (!Number.isInteger(number)) {
        return createError({
          path,
          message: messages.formMessages.noDecimalAllowed,
        });
      }
    }

    if (number > MAX_INTEGER) {
      return createError({
        path,
        message: messages.formMessages.maxNumberAllowed(formatPrice(MAX_INTEGER)),
      });
    }
    return true;
  };
}

export function customYupValidateURL() {
  return (value: string, context: TestContext) => {
    const { path, createError } = context;
    const urlRegex = new RegExp(
      '^(http(s)?://)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$',
    );

    if (value && !urlRegex.test(value)) {
      return createError({
        path,
        message: messages.formMessages.invalidURL,
      });
    }

    return true;
  };
}

export function customYupValidateDomain(fieldName?: string) {
  return (value: string, context: TestContext) => {
    const { path, createError } = context;

    if (value && !isValidDomain(value)) {
      return createError({
        path: fieldName || path,
        message: messages.formMessages.invalidDomain,
      });
    }

    return true;
  };
}

export function customYupValidateFileName() {
  return (value: string, context: TestContext) => {
    const { path, createError } = context;

    const fileNameRegex = new RegExp(/^[^\\/]*$/);

    if (value === '') {
      return createError({
        path,
        message: messages.formMessages.noEmptyStringAllowedInFile,
      });
    }

    if (value && !fileNameRegex.test(value)) {
      return createError({
        path,
        message: messages.formMessages.noSlashAllowedInFile,
      });
    }

    return true;
  };
}

/** convertErrorsToStringRepresentation function expect errors as:

errors = {
  contacts: [
    {
      firstName: {
        message: 'This field is required',
        type: 'required',
        ref: {},
      },
    },
  ],
};

and output:  'contacts.0.firstName.message'

getFirstErrorId function return first error id as: 'contacts.0.firstName' by removing 'message' from the end of the string

*/

const convertErrorsToStringRepresentation = (
  errors: FieldErrors,
  prefix = '',
): Record<string, string | number | null> =>
  Object.entries(errors || {}).reduce((result, [key, value]) => {
    const currentKey = `${prefix}${key}`;

    if (typeof value === 'object' && value?.type === customErrorType) {
      // Custom error type handling
      result[currentKey] = isString(value.message) ? value.message : null;
    } else if (typeof value === 'object') {
      // Recursively process nested objects
      Object.assign(result, convertErrorsToStringRepresentation(value as FieldErrors, `${currentKey}.`));
    } else {
      // Fallback for other cases
      result[currentKey] = value ?? null;
    }

    return result;
  }, {} as Record<string, string | number | null>);

export const getFirstErrorId = (errors: FieldErrors) =>
  Object.keys(convertErrorsToStringRepresentation(errors))[0]?.split('.')?.slice(0, -1)?.join('.');

export function triggerCustomError(methods: UseFormReturn<any, object>, fieldName: string, errorMessage: string) {
  methods.setError(fieldName, {
    type: customErrorType,
    message: errorMessage,
  });
  scrollToElement(fieldName);
}
