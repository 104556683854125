import { Link } from '@common-components';
import { useAppPubSub } from 'contexts/app-pub-sub/AppPubSub';
import { EventsList } from 'contexts/app-pub-sub/events-list';
import { CitationType, isEmailCitationType } from 'enums';
import { activeTabUrlParamValues, BrokerUrlParams } from 'broker/broker-routes';
import { useUpdateQueryParam } from 'broker/hooks';
import useCitationLabel from 'broker/pages/SubmissionWorkspacePage/hooks/useCitationLabel';
import { useUiStoreActions } from 'broker/pages/SubmissionWorkspacePage/ui-store/uiStoreProvider';
import CitationTooltip from './CitationTooltip';

interface AICitationLinkProps {
  sourceId: string;
  sourceType: CitationType;
  title: string;
  quotedText?: string;
  quotedPage?: string;
}

export const AICitationLink = ({ sourceId, sourceType, title, quotedText, quotedPage }: AICitationLinkProps) => {
  const updateQueryParam = useUpdateQueryParam();
  const citationLabel = useCitationLabel(sourceType, sourceId);
  const { setFilesExplorer } = useUiStoreActions();

  const activeTab = isEmailCitationType(sourceType) ? activeTabUrlParamValues.EMAIL : activeTabUrlParamValues.FILES;
  const paramName = isEmailCitationType(sourceType) ? BrokerUrlParams.SELECTED_EMAIL : BrokerUrlParams.SELECTED_FILE;

  const eventEmitter = useAppPubSub();

  const onLinkClick = () => {
    const addParams = [
      { queryParam: BrokerUrlParams.ACTIVE_TAB, value: activeTab },
      { queryParam: paramName, value: sourceId },
    ];

    if (!isEmailCitationType(sourceType) && quotedPage) {
      setFilesExplorer({ selectedPage: Number(quotedPage) });

      eventEmitter.emit({
        event: EventsList.FilePageChanged,
        data: {
          page: quotedPage,
        },
      });
    }

    updateQueryParam({
      removeParams: [BrokerUrlParams.ACTIVE_TAB, BrokerUrlParams.SELECTED_EMAIL, BrokerUrlParams.SELECTED_FILE],
      addParams,
    });
  };

  return (
    <CitationTooltip title={title} content={quotedText} onLinkClick={onLinkClick}>
      <Link underline="hover" ml={0.5} sx={{ cursor: 'pointer' }} onClick={onLinkClick}>
        ({citationLabel})
      </Link>
    </CitationTooltip>
  );
};
