import { PromptResultFeature, PromptResultFeatureType } from '../enums';

export enum ReportType {
    // retool report named 'Extracted Data Report' is used for comparing fields extraction
    ContentQualityMeasurement = 'ContentQualityMeasurement',
    // retool report named 'Content Quality Measurement' is used for comparing generated content for editor
    ExtractedDataReport = 'ExtractedDataReport',
}

type PromptResultByReportType = Record<PromptResultFeatureType, ReportType>;

export const promptResultByReportTypeConfig: PromptResultByReportType = {
    [PromptResultFeature.SubmissionInsuredName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCoverageLines]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantPrimaryAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantLocation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicantMailingAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalPayroll]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalAnnualRevenue]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalAssets]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionYearsOfOperation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionLegalEntity]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionApplicationDomainName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionIndustryClassification]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionOrganizationsCorporateStructure]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPhoneNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPartTimeEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionFullTimeEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEmployeesNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionHasDomain]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionAttorneyNumber]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionGlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCyberEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionMlEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEffectiveDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionNeedByDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCyberLossRunIndicator]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPLLossRunIndicator]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteInsight]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.AcknowledgmentEmail]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.QuotePremium]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteExpiresAt]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteTriaPremium]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteAggregateLimit]: ReportType.ExtractedDataReport,
    [PromptResultFeature.PolicyExpirationDate]: ReportType.ExtractedDataReport,
    [PromptResultFeature.QuoteCarrierFees]: ReportType.ExtractedDataReport,
    [PromptResultFeature.RiskAssessment]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.MarketingUpdateEmail]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.SubmissionDataApplicantContactName]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionDataApplicantEmailAddress]: ReportType.ExtractedDataReport,
    [PromptResultFeature.FileClassification]: ReportType.ExtractedDataReport,
    [PromptResultFeature.MarketingEmail]: ReportType.ContentQualityMeasurement,
    [PromptResultFeature.SubmissionContactAgreedToInformationSecurity]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPubliclyTradedSecurities]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPrivatePlacementInvestment]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionShareholderBoardRepresentation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionDebtCovenantBreach]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionSecurityBreachNotification]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPendingLitigation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionUnscheduledDowntimeLoss]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionOwnershipType]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionSensitiveCloudStorage]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionInfoSecTraining]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionFranchiseStatus]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionBusinessBrokerServices]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCapitalRaisingServices]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionMergerAcquisitionServices]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionBackupSecurityMeasures]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionThirdPartySecurityAgreements]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionFailoverTesting]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionSystemSegregation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEncryption]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionUpdateFrequency]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionIncidentResponsePlan]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionSecurityTrainingImplementation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionNewHireBackgroundChecks]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionBankAccountReconciliation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionFinancialControlSegregation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPaymentInstructionVerificationPolicy]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionInventoryPhysicalCount]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionHighValueInventoryExposure]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCheckCountersignatureRequirement]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionDomesticLocations]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionBankingTransactionSeparation]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionExpectsDebtSoon]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionNetIncome]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCurrentAssets]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEarningsBeforeInterestTaxes]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCashOrCashEquivalentAssets]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCashFlowFromOperations]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionCurrentLiabilities]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionLongTermDebt]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionAmountOfExpectedDebt]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPercentageSharesHeldByBoard]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionHalfLongTermDebtMatures]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalLiabilities]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalEntitiesOwnShares]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionPercentageHeldByTrusts]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionEntityType]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionYearsIndustryManagement]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalCashExposureOutside]: ReportType.ExtractedDataReport,
    [PromptResultFeature.SubmissionTotalCashExposureInside]: ReportType.ExtractedDataReport,
};
