import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useState } from 'react';
import {
  Box,
  ListItem,
  ListItemText,
  Autocomplete as MuiAutocomplete,
  SvgIconProps,
  TextField,
} from '@common-components';
import { uniteStyles } from 'utils';
import HighlightedText from './HighlightedText';
import { StartIconStyles } from './styles';

export interface OptionType {
  title: string;
  subtitle?: string;
}

interface AutocompleteProps<T extends { id: string }> {
  multiple?: boolean;
  label?: string;
  options: T[];
  value?: T | T[] | null;
  onChange?: (value: T | T[] | null) => void;
  getDisplayValues: (value: T) => OptionType;
  placeholder: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
  disabled?: boolean;
  disableClearable?: boolean;
  size?: 'small' | 'medium';
}

export default function Autocomplete<T extends { id: string }>({
  value: ownValue,
  multiple = false,
  label,
  options,
  onChange,
  getDisplayValues,
  placeholder,
  Icon,
  disabled = false,
  disableClearable = false,
  size = 'small',
}: AutocompleteProps<T>) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Box sx={{ position: 'relative' }}>
      {Icon && <Icon sx={StartIconStyles} />}
      <MuiAutocomplete
        size={size}
        sx={{
          width: '100%',
          '& .MuiAutocomplete-inputRoot:before': {
            borderBottomColor: 'divider',
          },
        }}
        disabled={disabled}
        disableClearable={disableClearable}
        value={ownValue || null}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        onChange={(_, value) => {
          onChange?.(value);
        }}
        multiple={multiple}
        limitTags={2}
        id={(label || 'autocomplete').replace(/\s/g, '_')}
        options={options}
        getOptionLabel={(option) => getDisplayValues(option).title}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          const matches = match(getDisplayValues(option).title, inputValue, {
            findAllOccurrences: true,
            insideWords: true,
          });
          const parts = parse(getDisplayValues(option).title, matches);

          return (
            <ListItem component="li" {...props} key={option.id}>
              <ListItemText
                primary={<HighlightedText parts={parts} markText={false} boldText />}
                secondary={getDisplayValues(option).subtitle}
              />
            </ListItem>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size={size}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params?.InputProps,
              ...{ sx: uniteStyles({ pl: Icon ? 5 : 0 }) }, // Add padding for the icon
            }}
          />
        )}
      />
    </Box>
  );
}
