import { Submission, SubmissionAdditionalData } from '../types';
import { SubmissionDataDestination } from './enums';

export const noneOfTheAboveOption = 'None of the above';

type DynamicFormDefaultValue = string | string[] | number | number[];

interface SubmissionDataDefaultsConfig {
    herald?: {
        fieldName: string;
        // override form value
        overrideValue?: DynamicFormDefaultValue;
    };
    submission?: {
        fieldName: keyof Submission;
        // override form value
        overrideValue?: Submission[keyof Submission];
    };
    submissionAdditionalData?: {
        fieldName: keyof SubmissionAdditionalData;
        // override form value
        overrideValue?: SubmissionAdditionalData[keyof SubmissionAdditionalData];
    };
    // form field names that this default value relates to
    formFieldNames: string[];
    formValue: DynamicFormDefaultValue;
}

function createDefaultEntry(fieldName: string, defaultValue: any) {
    return {
        herald: {
            fieldName,
        },
        formFieldNames: [fieldName],
        formValue: defaultValue,
    };
}

const submissionDataDefaultsConfig: SubmissionDataDefaultsConfig[] = [
    createDefaultEntry('rsk_7ahp_has_domain', 'yes'),
    createDefaultEntry('rsk_h8oi_high_risk_industry_type', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_a18w_stored_records_type', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_da0x_dual_authenticate_banking_detail_update', 'yes'),
    createDefaultEntry('rsk_tw5r_dual_authentication', 'For all requests'),
    createDefaultEntry('rsk_d6el_secure_backup', 'yes'),
    createDefaultEntry('rsk_glo9_secure_backup_frequency', 'Weekly or more'),
    createDefaultEntry('rsk_y8ve_secure_backup_offline', 'yes'),
    createDefaultEntry('rsk_64p7_data_encryption', [
        'De-identify sensitive data at rest',
        'Encrypt sensitive data at rest (including on laptops, computers, and other portable media devices)',
        'Encrypt sensitive data in emails sent to external parties',
    ]),
    createDefaultEntry('rsk_un9n_network_authentication', [
        'All web-based email accounts',
        'All remote access to the network including any remote desktop protocol connections',
        'Local and remote access to privileged user/network administrator accounts',
        'Internal and external access to cloud-based back-ups',
        'Mission critical systems',
    ]),
    createDefaultEntry('rsk_9bjm_content_review', 'yes'),
    createDefaultEntry('rsk_2pt5_has_parent_entity', 'no'),
    createDefaultEntry('rsk_a94l_do_24_12_months', 'no'),
    createDefaultEntry('rsk_ryx6_has_experienced_merger_and_acquisition', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_wjbi_ml_past_future_material_changes', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_ljip_change_in_voting_control_36_months', 'no'),
    createDefaultEntry('rsk_hdcl_has_employee_stock_plan', 'no'),
    createDefaultEntry('rsk_yh15_has_revenue_from_government', 'no'),
    createDefaultEntry('rsk_dc0u_anticipates_or_experienced_layoffs', 'no'),
    createDefaultEntry('rsk_gzu6_receives_employee_application', 'yes'),
    createDefaultEntry('rsk_e62k_publishes_employee_handbook', 'yes'),
    createDefaultEntry('rsk_8rqb_has_hr_department', 'yes'),
    createDefaultEntry('rsk_jb26_cyb_has_claims_history', 'no'),
    createDefaultEntry('rsk_ggy8_cyb_warranty', 'no'),
    createDefaultEntry('rsk_2aep_pl_has_claim_history', 'no'),
    createDefaultEntry('rsk_rvyh_pl_has_warranty', 'no'),
    createDefaultEntry('rsk_h2x6_has_pl_coverage', 'no'),
    createDefaultEntry('rsk_ir30_has_prior_civil_suit', 'no'),
    createDefaultEntry('rsk_rh5y_ml_has_claims_history_within_five_years', 'no'),
    createDefaultEntry('rsk_7i0r_ml_warranty_claim', 'no'),
    createDefaultEntry('rsk_w8j4_prior_eeoc_or_admin_proceeding', 'no'),
    createDefaultEntry('rsk_o3q9_content_complaints_past_three_years', 'no'),
    createDefaultEntry('rsk_8di2_offers_additional_services', 'no'),
    createDefaultEntry('rsk_q9am_has_privacy_claims', 'no'),
    createDefaultEntry('rsk_22b1_prior_coverage_changed', 'no'),
    createDefaultEntry('rsk_l9ap_seeking_coverage_for_affiliate', 'no'),
    createDefaultEntry('rsk_1pab_has_continuing_eduction', 'no'),
    createDefaultEntry('rsk_6i3e_has_investigation_from_federal_agency', 'no'),
    createDefaultEntry('rsk_0h8t_engages_in_cryptocurrency', 'no'),
    createDefaultEntry('rsk_su3n_option_to_invest', 'no'),
    createDefaultEntry('rsk_ot4r_benefit_reduction', 'no'),
    createDefaultEntry('rsk_c2ui_erisa_compliance', 'no'),
    createDefaultEntry('rsk_5iyn_plan_violation', 'no'),
    createDefaultEntry('rsk_un8m_delinquent_plan', 'no'),
    createDefaultEntry('rsk_7qec_anticipates_or_experienced_financial_restructing', 'no'),
    createDefaultEntry('rsk_3e4y_cancelled_nonrenew_ml_policy', 'no'),
    createDefaultEntry('rsk_voe4_cyb_security_officer', 'yes'),
    createDefaultEntry('rsk_yo5p_anticipates_or_completed_public_offerings', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_sf46_anticipates_or_completed_private_placements', [noneOfTheAboveOption]),
    createDefaultEntry('rsk_faf9_percentage_shares_held_by_board', 100),
    createDefaultEntry('rsk_gyj4_half_long_term_debt_matures', 'no'),
    createDefaultEntry('rsk_mur2_has_outside_individual_ownership', 'no'),
    createDefaultEntry('rsk_e718_has_breached_debt_covenants', 'no'),
    createDefaultEntry('rsk_tu74_percentage_held_by_trusts', 0),
    createDefaultEntry('rsk_3oiz_has_security_breach_requiring_notification', 'no'),
    createDefaultEntry('rsk_sw1p_has_pending_litigation', 'no'),
    createDefaultEntry('rsk_p175_cyb_has_loss_in_unschedule_system_downtime', 'no'),
    createDefaultEntry('rsk_6onk_entity_type', 'Independent'),
    createDefaultEntry('rsk_2i59_ownership_type', 'Private'),
    createDefaultEntry('rsk_e73e_cyb_authenticating_fund_transfers', 'yes'),
    createDefaultEntry('rsk_zk4f_cyb_verifies_bank_accounts', 'yes'),
    createDefaultEntry('rsk_5m1o_cyb_cloud_storage', 'no'),
    createDefaultEntry('rsk_6ril_cyb_security_training', 'yes'),
    createDefaultEntry('rsk_s9i6_is_franchise', 'no'),
    createDefaultEntry('rsk_2nge_is_business_broker', 'no'),
    createDefaultEntry('rsk_nbd2_is_investment_bank', 'no'),
    createDefaultEntry('rsk_2n3d_performs_merger_and_acquisition', 'no'),
    createDefaultEntry('rsk_4hz4_backup_security_measure_type', [
        'MFA',
        'Segmentation',
        'Immutable',
        'Virus/Malware scanning',
        'Encryption',
        'Test',
        'Online or Designated Cloud Service',
    ]),
    createDefaultEntry('rsk_9ljq_third_party_security_agreement', 'yes'),
    createDefaultEntry('rsk_bet9_tested_full_failover', 'yes'),
    createDefaultEntry('rsk_9ty9_has_dmz_separation', 'yes'),
    createDefaultEntry('rsk_rzc7_data_encryption_frequency', 'yes'),
    createDefaultEntry('rsk_331u_patch_frequency', 'Monthly'),
    createDefaultEntry('rsk_fzej_has_incident_response_plan', 'yes'),
    createDefaultEntry('rsk_8s6e_cyb_training_implementation', 'yes'),
    createDefaultEntry('rsk_9ux6_years_industry_management', 10),
    createDefaultEntry('rsk_na7l_perform_background_checks_employee', 'yes'),
    createDefaultEntry('rsk_ms88_bank_account_reconciliation', 'yes'),
    createDefaultEntry('rsk_0o4l_wire_transfer_authorization', 'yes'),
    createDefaultEntry('rsk_77vv_vendor_verification_policy', 'yes'),
    createDefaultEntry('rsk_u3u3_inventory_physical_count', 'yes'),
    createDefaultEntry('rsk_ut7e_high_value_exposure', 'no'),
    createDefaultEntry('rsk_3h4w_counter_signature_required', 'yes'),
    createDefaultEntry('rsk_u8u8_locations_abroad', 'yes'),
    createDefaultEntry('rsk_ff4b_banking_transaction_control', 'yes'),
];

const getSubmissionDataDefaultsConfigByDestination = (
    destination: Exclude<SubmissionDataDestination, 'SubmissionForm'>,
    config: SubmissionDataDefaultsConfig,
) => {
    if (destination === SubmissionDataDestination.Herald) {
        return config.herald;
    }
    if (destination === SubmissionDataDestination.Submission) {
        return config.submission;
    }
    return config.submissionAdditionalData;
};

export const getSubmissionDataFromDefaultValuesByDestination = (destination: SubmissionDataDestination) =>
    submissionDataDefaultsConfig.reduce((acc, config) => {
        if (destination === SubmissionDataDestination.SubmissionForm) {
            config.formFieldNames.forEach((formFieldName) => {
                acc[formFieldName] = config.formValue;
            });
            return acc;
        }
        const destinationConfig = getSubmissionDataDefaultsConfigByDestination(destination, config);
        const fieldName = destinationConfig?.fieldName;
        if (fieldName) {
            acc[fieldName as string] = destinationConfig?.overrideValue ?? config.formValue;
        }
        return acc;
    }, {} as Record<string, any>);

// key:question id , value: default answer
export const dynamicFormDefaultValuesConfig: Record<string, DynamicFormDefaultValue> =
    getSubmissionDataFromDefaultValuesByDestination(SubmissionDataDestination.SubmissionForm);
