import { Button, Stack, Typography } from '@common-components';
import { FastForwardOutlined as FastForwardOutlinedIcon } from '@icons';
import { messages } from 'i18n';
import IconBadge from 'components/IconBadge';

interface FastTrackBannerProps {
  onBannerDismiss: () => void;
}

export default function FastTrackBanner({ onBannerDismiss }: FastTrackBannerProps) {
  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 35,
        left: '50%',
        zIndex: 1000,
        transform: 'translateX(-50%)',
        boxShadow: 4,
      }}
      direction="row"
      bgcolor="green.300"
      width={943}
      height={52}
      p={1}
      justifyContent="space-between"
      borderRadius={1}
    >
      <Stack direction="row" gap={1}>
        <IconBadge IconComponent={FastForwardOutlinedIcon} bgcolor="green.300" size="small" />
        <Stack>
          <Typography variant="body2Bold">{messages.fastTrack.banner.title}</Typography>
          <Typography variant="caption">{messages.fastTrack.banner.description}</Typography>
        </Stack>
      </Stack>

      <Stack justifyContent="center">
        <Stack bgcolor="typography.contrast" color="blue.50" borderRadius={1}>
          <Button variant="contained" color="inherit" size="small" onClick={onBannerDismiss}>
            {messages.fastTrack.banner.cta}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
