import { getCoverageLineConfig } from '@common/config';
import { renderToStaticMarkup } from 'react-dom/server';
import { messages } from 'i18n';
import { Quote } from 'types';
import { formatPrice } from 'utils';

const rowStyle = { height: '30px' };
const cellStyle = { height: '30px', padding: '0px', borderWidth: '0px' };
const listStyle = { margin: '16px 12px 16px 12px', paddingLeft: '16px' };
const tableStyle = {
  borderWidth: '0px',
  borderSpacing: '8px',
  width: '100%',
  maxWidth: '600px',
  border: 1,
};

function getProgramSummary(quote: Quote) {
  return (
    <table style={{ ...tableStyle, borderCollapse: 'collapse' }}>
      <colgroup>
        <col style={{ width: '40%' }} />
        <col style={{ width: '60%' }} />
      </colgroup>
      <tbody>
        <tr style={rowStyle}>
          <td style={cellStyle}>
            <ul style={listStyle}>
              <li>{messages.addQuotePage.fieldLabels.market.toUpperCase()}</li>
            </ul>
          </td>
          <td style={cellStyle}>
            <b>{quote.marketName}</b>
          </td>
        </tr>
        <tr style={rowStyle}>
          <td style={cellStyle}>
            <ul style={listStyle}>
              <li>{messages.addQuotePage.fieldLabels.coverageLines.toUpperCase()}</li>
            </ul>
          </td>
          <td style={cellStyle}>
            <b>
              {quote.coverageLines
                ? quote.coverageLines.map((coverageLine) => getCoverageLineConfig(coverageLine).text).join(', ')
                : ''}
            </b>
          </td>
        </tr>
        <tr style={rowStyle}>
          <td style={cellStyle}>
            <ul style={listStyle}>
              <li>{messages.addQuotePage.fieldLabels.premium.toUpperCase()}</li>
            </ul>
          </td>
          <td style={cellStyle}>
            <b>{quote.premium ? `$${formatPrice(quote.premium)}` : ''}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export function getQuote(quote: Quote) {
  const { comments } = quote;
  const programSummary = getProgramSummary(quote);

  return renderToStaticMarkup(
    <div>
      {/* Comments */}
      {comments && (
        <>
          <div dangerouslySetInnerHTML={{ __html: comments }} />
          <br />
        </>
      )}

      {/* Program Summary */}
      <b style={{ fontSize: '14px' }}>{messages.addQuotePage.fieldLabels.programSummary}</b>
      <br />
      {programSummary}
      <br />
    </div>,
  );
}
