import { compact, isEmpty } from 'lodash';
import moment from 'moment';
import { Address, ExtractedAddressValue, ExtractedAttribute } from '../../types';
import { convertCoverageLinesTextToEnum, isValidDomain, isValidEmail } from '../../utils';

function isNumeric(str: string): boolean {
    return (
        str.trim() !== '' && // Ensure it's not empty or whitespace-only
        !Number.isNaN(Number(str)) // Use Number() to parse the string and check if it's NaN
    );
}

export function normalizeNumericExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute.value && isNumeric(extractedAttribute.value)) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeDomainExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute.value && isValidDomain(extractedAttribute.value)) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeOptionExtraction(
    extractedAttribute: ExtractedAttribute<string>,
    options: string[],
): ExtractedAttribute<string> | undefined {
    const foundOption = options.find((option) => option.toLowerCase() === extractedAttribute?.value?.toLowerCase());
    if (foundOption) {
        extractedAttribute.value = foundOption;
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeMultipleOptionsExtraction(
    extractedAttribute: ExtractedAttribute<string[]>,
    options: string[],
): ExtractedAttribute<string[]> | undefined {
    extractedAttribute.value = compact(
        extractedAttribute.value?.map((value) =>
            options.find((option) => option.toLowerCase() === value.toLowerCase()),
        ),
    );
    if (isEmpty(extractedAttribute.value)) {
        return undefined;
    }
    return extractedAttribute;
}

export function normalizeDateExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute?.value && moment(extractedAttribute.value).isValid()) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeEmailExtraction(
    extractedAttribute: ExtractedAttribute<string>,
): ExtractedAttribute<string> | undefined {
    if (extractedAttribute.value && isValidEmail(extractedAttribute.value)) {
        return extractedAttribute;
    }
    return undefined;
}

export function normalizeCoverageLineExtraction(
    extractedAttribute: ExtractedAttribute<string[]>,
): ExtractedAttribute<string[]> | undefined {
    if (extractedAttribute?.value) {
        return {
            ...extractedAttribute,
            value: convertCoverageLinesTextToEnum(extractedAttribute.value),
        };
    }
    return undefined;
}

export const normalizeAddress = (address: Address) => {
    if (!isEmpty(address.line1) && !isEmpty(address.city) && !isEmpty(address.postal_code) && !isEmpty(address.state)) {
        return {
            line1: address.line1,
            line2: address.line2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            // country_code has only 1 option in herald form, no need to extract it (if the rest of the address is valid, the country is valid)
            country_code: 'USA',
        };
    }
    return undefined;
};

export function normalizeAddressExtraction(
    extractedAttribute: ExtractedAttribute<ExtractedAddressValue>,
): ExtractedAttribute<ExtractedAddressValue> | undefined {
    const addressValue = extractedAttribute.value;

    if (addressValue) {
        const normalizedPrimaryAddress = addressValue?.primary ? normalizeAddress(addressValue.primary) : undefined;
        const normalizedMailingAddress = addressValue?.mailing ? normalizeAddress(addressValue.mailing) : undefined;

        return {
            ...extractedAttribute,
            value: {
                primary: normalizedPrimaryAddress,
                mailing: normalizedMailingAddress,
            },
        };
    }

    return undefined;
}
