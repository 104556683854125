import { Address } from '@common/types';
import { HeraldSchemaFormat } from 'clients/types';
import { CoverageLine, HeraldCategoryType } from 'enums';
import { PartialSubmission } from 'types';
import { DynamicInputType } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/enums';
import {
  DynamicFormQuestionSource,
  heraldDueDateParameterIds,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';
import {
  buildDynamicFormAddressValue,
  heraldMailingAddressQuestionIds,
  heraldPrimaryAddressQuestionIds,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/address-utils';
import { DynamicFormAddressSchema } from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/utils/schema-utils';
import { FlowStaticQuestionId } from './flow-static-question-id';

function hideIfNotLPL(
  _heraldNormalizedParameters: HeraldNormalizedParameter[],
  submission: PartialSubmission,
): boolean {
  return !submission.coverageLines?.includes(CoverageLine.LawyersProfessionalLiability);
}

interface FlowStaticQuestionConfigProps extends Omit<HeraldNormalizedParameter, 'value'> {
  // value should be according to the schema, value can be an object which mean the schema is an object with properties (see address)
  getValue: (submission: PartialSubmission) => any;
}

export const flowStaticQuestionsConfig: FlowStaticQuestionConfigProps[] = [
  {
    getValue: (submission: PartialSubmission) => submission?.needByDate,
    parameter_id: FlowStaticQuestionId.NeedByDate,
    fieldLabel: 'Need By Date',
    section: 'Coverages',
    creates_array: false,
    affects_conditions: false,
    input_type: DynamicInputType.Date,
    heraldCategory: HeraldCategoryType.CoverageValues,
    isRequired: true,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'string',
      format: HeraldSchemaFormat.Date,
    },
    showAfterOneOfTheseQuestions: heraldDueDateParameterIds,
  },
  {
    getValue: (submission: PartialSubmission): Address | undefined =>
      submission?.insuredPrimaryAddress?.line1
        ? buildDynamicFormAddressValue(submission.insuredPrimaryAddress)
        : undefined,
    parameter_id: FlowStaticQuestionId.PrimaryAddress,
    fieldLabel: "Applicant's Primary Address",
    section: 'Basic Information',
    creates_array: false,
    affects_conditions: false,
    input_type: DynamicInputType.Address,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: true,
    isHidden: (heraldNormalizedParameters: HeraldNormalizedParameter[]) =>
      heraldNormalizedParameters.some((heraldNormalizedParameter) =>
        heraldPrimaryAddressQuestionIds.includes(heraldNormalizedParameter.parameter_id),
      ),
    source: DynamicFormQuestionSource.Flow,
    schema: DynamicFormAddressSchema,
    showAfterOneOfTheseQuestions: ['rsk_m4p9_insured_name'],
  },
  {
    getValue: (submission: PartialSubmission): Address | undefined =>
      submission?.insuredMailingAddress?.line1
        ? buildDynamicFormAddressValue(submission.insuredMailingAddress)
        : undefined,
    parameter_id: FlowStaticQuestionId.MailingAddress,
    fieldLabel: "Applicant's Mailing Address",
    section: 'Basic Information',
    creates_array: false,
    affects_conditions: false,
    input_type: DynamicInputType.Address,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: false,
    isHidden: (heraldNormalizedParameters: HeraldNormalizedParameter[]) =>
      heraldNormalizedParameters.some((heraldNormalizedParameter) =>
        heraldMailingAddressQuestionIds.includes(heraldNormalizedParameter.parameter_id),
      ),
    source: DynamicFormQuestionSource.Flow,
    schema: DynamicFormAddressSchema,
    showAfterOneOfTheseQuestions: ['rsk_m4p9_insured_name'],
  },
  {
    getValue: (submission: PartialSubmission) => submission?.additionalData?.attorneyNumber,
    parameter_id: FlowStaticQuestionId.AttorneyNumber,
    fieldLabel: 'Number of Attorneys',
    section: 'Basic Information',
    creates_array: false,
    affects_conditions: false,
    input_type: DynamicInputType.Integer,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: true,
    isHidden: hideIfNotLPL,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'integer',
      minimum: 0,
    },
    showAfterOneOfTheseQuestions: ['rsk_b3jm_2017_naics_index'],
  },
  {
    getValue: (submission) => submission?.additionalData?.attorneyHireYears?.map((date) => date.toString()),
    parameter_id: FlowStaticQuestionId.AttorneyHireYears,
    fieldLabel: 'Attorneys Hire Dates',
    section: 'Basic Information',
    creates_array: true,
    affects_conditions: false,
    input_type: DynamicInputType.Year,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: true,
    isHidden: hideIfNotLPL,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'string',
    },
    showAfterOneOfTheseQuestions: ['rsk_b3jm_2017_naics_index'],
  },
  {
    getValue: (submission) => submission?.additionalData?.areasOfPractice,
    parameter_id: FlowStaticQuestionId.AreasOfPractice,
    fieldLabel: 'Area of Practice',
    section: 'Basic Information',
    creates_array: true,
    affects_conditions: false,
    input_type: DynamicInputType.AreaOfPractice,
    heraldCategory: HeraldCategoryType.RiskValues,
    isRequired: true,
    isHidden: hideIfNotLPL,
    source: DynamicFormQuestionSource.Flow,
    schema: {
      title: '',
      type: 'object',
      properties: {
        area: {
          type: 'string',
          title: 'Area',
        },
        percentage: {
          type: 'number',
          title: 'Percentage',
          minimum: 1,
          maximum: 100,
        },
      },
      required: ['area', 'percentage'],
    },
    showAfterOneOfTheseQuestions: ['rsk_b3jm_2017_naics_index'],
  },
];
