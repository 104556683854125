import { getCoverageLineConfig } from '@common/config';
import { isEmpty } from 'lodash';
import { Highlight, Stack, Tooltip, Typography } from '@common-components';
import { messages } from 'i18n';
import { InsuranceProduct, SubmissionMarket } from 'types';
import { truncateMultiLineText } from 'utils';
import { getCoverageLayerLabel } from 'broker/utils';

export interface ProductDescriptionProps {
  product: InsuranceProduct;
  submissionMarket?: SubmissionMarket;
  searchInput: string;
}

const {
  isAdmitted: { admitted, nonAdmitted },
  incumbent,
} = messages.addProductsPage.additionalFilters;

export function ProductDescription({ product, submissionMarket, searchInput }: ProductDescriptionProps) {
  const { marketName, coverageLayer, isAdmitted, label, coverageLines } = product;
  const isIncumbent = submissionMarket && submissionMarket.incumbentInfo.length > 0;
  const getIncumbentTooltip = () => {
    if (isIncumbent) {
      return submissionMarket.incumbentInfo[0]?.isPrimary
        ? messages.addProductsPage.incumbentTooltip.primary
        : messages.addProductsPage.incumbentTooltip.excess;
    }
    return '';
  };

  const productCoverageLines = coverageLines
    .map((productCoverageLine) => getCoverageLineConfig(productCoverageLine.coverageLine).text)
    .join(', ');
  const coverageLayerLabel = getCoverageLayerLabel(coverageLayer);
  const isAdmittedLabel = isAdmitted ? admitted : nonAdmitted;

  const productDetails = `${
    !isEmpty(coverageLines) ? `${productCoverageLines} | ` : ''
  } ${coverageLayerLabel} | ${isAdmittedLabel}`;

  return (
    <Stack gap={0.5}>
      <Stack>
        <Stack direction="row" gap={0.5}>
          <Typography fontWeight="bold" variant="body2" sx={{ ...truncateMultiLineText(2) }}>
            <Highlight searchWords={[searchInput]} textToHighlight={marketName} />
          </Typography>
          {isIncumbent && (
            <Stack justifyContent="center">
              <Tooltip tooltipContent={getIncumbentTooltip()} placement="top">
                <Typography variant="caption" color="typography.nonEssential">
                  {`(${incumbent})`}
                </Typography>
              </Tooltip>
            </Stack>
          )}
        </Stack>
        {label && <Typography variant="captionBold">{label}</Typography>}
      </Stack>
      <Stack direction="row" gap={0.5} flexWrap="wrap">
        <Typography title={productDetails} sx={{ ...truncateMultiLineText(4) }} variant="caption">
          {productDetails}
        </Typography>
      </Stack>
    </Stack>
  );
}
