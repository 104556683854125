import { FastForwardOutlined as FastForwardOutlinedIcon } from '@icons';
import { messages } from 'i18n';
import IconBadge from 'components/IconBadge';

export default function FastTrackIconButton() {
  return (
    <IconBadge
      sx={{ minWidth: 30 }}
      tooltipContent={messages.fastTrack.iconButton.tooltip}
      inheritColorTooltip
      IconComponent={FastForwardOutlinedIcon}
      bgcolor="green.300"
      size="small"
    />
  );
}
