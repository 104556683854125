import { normalizeAddress } from '@common/submission-data/submission-extracted-data/extraction-normalizers';
import { Address, ExtractedAttribute } from '@common/types';
import { parseTimezoneLessDate } from '@common/utils/date-utils';
import { round, toNumber } from 'lodash';
import { date, number } from 'yup';

export function getValidNumberSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
  integerSchemaType = true,
): Pick<ExtractedAttribute<number>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute && number().isValidSync(extractedAttribute.value)) {
    const numberValue = toNumber(extractedAttribute.value);
    return {
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
      value: integerSchemaType ? round(numberValue) : numberValue,
    };
  }
  return undefined;
}

export function getValidDateSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute?.value && date().isValidSync(extractedAttribute.value)) {
    return {
      value: parseTimezoneLessDate(extractedAttribute.value)?.toISOString(),
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidOptionSuggestion<T extends string | string[]>(
  extractedAttribute: ExtractedAttribute<T>,
  options: string[],
): Pick<ExtractedAttribute<T>, 'value' | 'reason' | 'citations'> | undefined {
  let valid: string | string[] | undefined;

  if (typeof extractedAttribute.value === 'string') {
    const stringExtractedValue = extractedAttribute.value;
    valid = options.find((option) => option.toLowerCase() === stringExtractedValue.toLowerCase());
  } else if (Array.isArray(extractedAttribute.value)) {
    const validValues = extractedAttribute.value.filter((value) =>
      options.some((option) => option.toLowerCase() === value.toLowerCase()),
    );
    valid = validValues.length > 0 ? validValues : undefined;
  }

  return valid
    ? {
        value: valid as T,
        citations: extractedAttribute.citations,
        reason: extractedAttribute.reason,
      }
    : undefined;
}

export function getValidNumericStringSuggestion(
  extractedAttribute: ExtractedAttribute<string>,
): Pick<ExtractedAttribute<string>, 'value' | 'reason' | 'citations'> | undefined {
  if (extractedAttribute && number().isValidSync(extractedAttribute.value)) {
    return {
      value: extractedAttribute.value,
      citations: extractedAttribute.citations,
      reason: extractedAttribute.reason,
    };
  }
  return undefined;
}

export function getValidAddressSuggestion(
  extractedAttribute: ExtractedAttribute<Address>,
): Pick<ExtractedAttribute<Address>, 'value' | 'reason' | 'citations'> | undefined {
  const addressValue = extractedAttribute.value;
  const normalizedAddress = addressValue ? normalizeAddress(addressValue) : undefined;
  if (normalizedAddress) {
    return {
      reason: extractedAttribute.reason,
      citations: extractedAttribute.citations,
      value: normalizedAddress,
    };
  }
  return undefined;
}
