import { isEmpty, isNumber, toNumber } from 'lodash';
import { TestContext } from 'yup';
import { CoverageValuesUpdate, HeraldApiError, HeraldUpdateApplication, RiskValuesUpdate } from 'clients/types';
import { HeraldCategoryType } from 'enums';
import { messages } from 'i18n';
import { AnyObject } from 'types';
import {
  FormStateBaseProp,
  HeraldNormalizedParameter,
} from 'broker/pages/SubmissionWorkspacePage/components/HeraldForm/DynamicForm/types';

export function doesObjectContainNonEmptyValues(object: AnyObject) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.entries(object).some(([_key, val]) => !isEmpty(val));
}

export function testRequiredNestedObject(requiredFields: string[]) {
  return (value: AnyObject, context: TestContext) => {
    const { path, createError } = context;

    if (doesObjectContainNonEmptyValues(value)) {
      const fields = Object.keys(value);
      for (const field of fields) {
        if (requiredFields.includes(field) && isEmpty(value[field])) {
          return createError({
            path: `${path}.${field}`, // Specify the path for each specific field
            message: 'Required',
          });
        }
      }
    }

    return true;
  };
}

// ['risk_values',index] or ['coverage_values',index]
export function parseUpdateErrorInvalidFormatItem(str: string) {
  // Regular expression pattern to match "propertyName[index]"
  const regex = /(\w+)\[(\d+)\]/;

  // Match the string against the regular expression
  const match = str.match(regex);

  // If there's a match, return an array with property name and index
  if (match) {
    const [, propertyName, indexStr] = match;
    const index = parseInt(indexStr, 10);
    return [propertyName, index];
  }

  // If no match, return null or handle as needed
  return null;
}

export function getHeraldServerErrorFieldMessage(
  error: HeraldApiError,
  formStateToHerald: Omit<HeraldUpdateApplication, 'products'>,
) {
  if (error.errors?.[0].code === 'invalid_format') {
    const errorItem = error.errors[0];
    // e.g risk_values[0].value.date || risk_values[13].child_risk_values[0].value
    const path = errorItem.path.split('.');
    const suffix = path[path.length - 1] === 'value' ? '' : `.${path[path.length - 1]}`;
    const parsedParameter = parseUpdateErrorInvalidFormatItem(path[0]);
    let paramIdType: HeraldCategoryType | undefined;
    if (parsedParameter?.[0] === HeraldCategoryType.RiskValues) {
      paramIdType = HeraldCategoryType.RiskValues;
    } else if (parsedParameter?.[0] === HeraldCategoryType.CoverageValues) {
      paramIdType = HeraldCategoryType.CoverageValues;
    }
    if (paramIdType && parsedParameter && isNumber(parsedParameter?.[1])) {
      const paramValue = formStateToHerald[paramIdType]?.[parsedParameter[1]];
      if (paramValue) {
        let fieldName =
          paramIdType === HeraldCategoryType.RiskValues
            ? (paramValue as RiskValuesUpdate).risk_parameter_id
            : (paramValue as CoverageValuesUpdate).coverage_parameter_id;

        if (paramValue.instance) {
          const index = toNumber(paramValue.instance.split('_').pop()) - 1;
          fieldName += `[${index}]`;
        }

        if (path[1] === 'value') {
          return {
            fieldName: `${fieldName}.${FormStateBaseProp.Main}${suffix}`,
            message: errorItem.display_message,
          };
        }
        if (path[1].includes('child_')) {
          const parsedChildParameter = parseUpdateErrorInvalidFormatItem(path[1]);
          if (parsedChildParameter?.[0] && isNumber(parsedChildParameter?.[1])) {
            const childParamValue =
              paramIdType === HeraldCategoryType.RiskValues
                ? (paramValue as RiskValuesUpdate)?.child_risk_values?.[parsedChildParameter[1]]
                : (paramValue as CoverageValuesUpdate)?.child_coverage_values?.[parsedChildParameter[1]];

            const childParamFieldName =
              paramIdType === HeraldCategoryType.RiskValues
                ? (paramValue as RiskValuesUpdate)?.child_risk_values?.[parsedChildParameter[1]].risk_parameter_id
                : (paramValue as CoverageValuesUpdate)?.child_coverage_values?.[parsedChildParameter[1]]
                    .coverage_parameter_id;

            fieldName += `.${FormStateBaseProp.Children}.${childParamFieldName}`;

            if (childParamValue) {
              if (childParamValue.instance) {
                const index = toNumber(childParamValue.instance.split('_').pop()) - 1;
                fieldName += `[${index}]`;
              }
              return {
                fieldName: `${fieldName}.${FormStateBaseProp.Main}${suffix}`,
                message: errorItem.display_message,
              };
            }
          }
        }
      }
    }
  }
  return false;
}

export function isHeraldParameterNotAnswered(heraldNormalizedParameter: HeraldNormalizedParameter): boolean {
  if (heraldNormalizedParameter.arrayElements?.length) {
    return heraldNormalizedParameter.arrayElements.some((element) => isHeraldParameterNotAnswered(element));
  }

  if (heraldNormalizedParameter.childValues?.length) {
    return heraldNormalizedParameter.childValues.some((element) => isHeraldParameterNotAnswered(element));
  }

  // if heraldNormalizedParameter is optional then it is ok even of not answered
  return !heraldNormalizedParameter.isRequired || heraldNormalizedParameter.value !== null;
}

export const convertExactDigitsToReadableError = (pattern: string) => {
  const match = pattern.match(/^\^\\d\{(\d+)\}\$$/);
  if (match) {
    return messages.errors.patternTestExactDigits(match[1]);
  }
  return '';
};
