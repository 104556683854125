import { getCoverageLinesText } from '@common/utils';
import { Typography } from '@common-components';
import { messages } from 'i18n';
import { PartialSubmission } from 'types';
import { formatBigNumber, getTimezoneLessDisplayDate, isDefined } from 'utils';

export interface SubmissionNameProps {
  submission: PartialSubmission;
}

export default function SubmissionTitle({ submission }: SubmissionNameProps) {
  const limit = submission?.limit ? formatBigNumber(submission.limit) : null;
  const reachedLimit = formatBigNumber(submission?.reachedLimit || 0);
  const coverageLines = getCoverageLinesText(submission?.coverageLines);
  const formattedDate = submission?.needByDate
    ? `${messages.general.neededBy}: ${getTimezoneLessDisplayDate(submission.needByDate)}`
    : '';

  const parts = [
    submission.insuredName && `${submission.insuredName}`,
    reachedLimit && messages.submission.reachedLimitText(reachedLimit, limit),
    coverageLines && coverageLines,
    formattedDate && formattedDate,
  ]
    .filter(isDefined)
    .join(', ');

  return (
    <Typography variant="h3" component="h1" flex={1} color="typography.contrast">
      {parts}
    </Typography>
  );
}
