import { ContentPreview as BoxContentPreview } from 'box-ui-elements/es';
import { defaultTo, toNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { Stack } from '@common-components';
import { AppEvent, useAppPubSub } from 'contexts/app-pub-sub/AppPubSub';
import { EventsList } from 'contexts/app-pub-sub/events-list';
import { uniteStyles } from 'utils';
import { PreviewFilesMenu } from './PreviewFilesMenu';
import BoxStyles from './styles';
import useBoxMessages from './useBoxMessages';
import useBoxToken from './useBoxToken';
import { withBoxToken, WithBoxTokenProps } from './withBoxToken';

type FilesMenuProps = {
  collectionFileIds: string[];
  boxFolderId: string;
};

type ContentPreviewProps = WithBoxTokenProps & {
  fileId: string;
  hasHeader?: boolean;
  filesMenuProps?: FilesMenuProps;
  initialSelectedFilePage?: number;
};

function ContentPreview({
  sx,
  hasHeader = true,
  fileId,
  filesMenuProps,
  initialSelectedFilePage,
}: ContentPreviewProps) {
  const { getToken } = useBoxToken();
  const boxMessages = useBoxMessages();
  const [selectedFileId, setSelectedFileId] = useState<string>(fileId);
  const [selectedFilePage, setSelectedFilePage] = useState<number | undefined>(initialSelectedFilePage);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const { addListener, removeListener } = useAppPubSub();

  useEffect(() => {
    const onPageChange = (listener: AppEvent) => {
      if (listener.event !== EventsList.FilePageChanged) {
        return;
      }

      const newPage = defaultTo(toNumber(listener.data.page), 1);
      setSelectedFilePage(newPage);
      setForceUpdateKey((prevKey) => prevKey + 1);
    };

    addListener(onPageChange);
    return () => {
      removeListener(onPageChange);
    };
  }, [addListener, removeListener]);

  const fileOptions = {
    [selectedFileId]: {
      startAt: {
        unit: 'pages',
        value: selectedFilePage,
      },
    },
  };

  useEffect(() => {
    setSelectedFileId(fileId);
  }, [fileId]);

  return (
    <Stack sx={uniteStyles(BoxStyles, sx)}>
      {filesMenuProps && (
        <PreviewFilesMenu
          fileId={fileId}
          selectedFileId={selectedFileId}
          setSelectedFileId={setSelectedFileId}
          collectionFileIds={filesMenuProps.collectionFileIds}
          boxFolderId={filesMenuProps.boxFolderId}
        />
      )}
      <BoxContentPreview
        key={`box-content-preview-${selectedFileId}-${selectedFilePage}-${forceUpdateKey}`} // Ensure re-render
        className="box-content-preview"
        language="en-US"
        messages={boxMessages}
        token={getToken}
        hasHeader={hasHeader}
        collection={filesMenuProps?.collectionFileIds}
        fileId={selectedFileId}
        fileOptions={fileOptions}
      />
    </Stack>
  );
}

export default withBoxToken(ContentPreview);
