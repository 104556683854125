import { getCoverageLinesText } from '@common/utils';
import { defaultTo, forEach, groupBy, isEmpty } from 'lodash';
import { CoverageLine, ShareMenuAction, SubmissionMarketRequestStatus } from 'enums';
import { messages } from 'i18n';
import { Quote, SubmissionMarket, SubmissionMarketRequest } from 'types';
import { excessLayerTitle, formatBigNumber, formatPriceOrNA, marketRequestTypeConfig } from 'utils';
import { stringCompare } from 'utils/comparators';
import { getDeclinationMessage } from 'broker/configuration-mappers/declination-config';
import { getInformationRequestedMessage } from 'broker/configuration-mappers/information-request-config';
import { marketRequestsStatusConfig } from 'broker/pages/SubmissionWorkspacePage/market-request-status-config';

function primaryLayerInfo(quote: Quote): string {
  return `${quote.limit ? `$${formatBigNumber(quote.limit)}` : ''} ${messages.general.primary} @ ${formatPriceOrNA(
    quote.premium,
  )} ${messages.general.premium}`;
}

export enum MarketingReportNoteOverrideType {
  Declination = 'Declination',
  InformationRequested = 'InformationRequested',
}

export const getMarketingReportNoteOverrideType = (marketRequest: SubmissionMarketRequest) => {
  if (marketRequest.status === SubmissionMarketRequestStatus.Declined) {
    return MarketingReportNoteOverrideType.Declination;
  }
  if (marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer) {
    return MarketingReportNoteOverrideType.InformationRequested;
  }
  return undefined;
};

export const getAdditionalInfoText = (
  marketRequest: SubmissionMarketRequest,
  marketingReportNoteOverrideType?: MarketingReportNoteOverrideType,
) => {
  switch (marketingReportNoteOverrideType) {
    case MarketingReportNoteOverrideType.Declination:
      return `${messages.marketingReportPage.declinationAdditionalInfoPrefix}\n\n${getDeclinationMessage(
        defaultTo(marketRequest.declination?.declinationReasons, []),
      )}`;
    case MarketingReportNoteOverrideType.InformationRequested:
      return `${
        messages.marketingReportPage.informationRequestedAdditionalInfoPrefix
      }\n${getInformationRequestedMessage(marketRequest, (message) => `* ${message}\n`)}`;
    default:
      return undefined;
  }
};

export function buildLayerTitle(marketRequest: SubmissionMarketRequest) {
  const { quote } = marketRequest;

  if (!quote?.layer) {
    return '';
  }

  if (quote.layer.attachmentPoint === undefined) {
    return `${primaryLayerInfo(quote)}`;
  }

  return `${excessLayerTitle(quote.limit, quote.premium, quote.primaryQuote?.marketName, quote.layer.attachmentPoint)}`;
}

export function getMarketRequestPrimerySxText(marketRequest: SubmissionMarketRequest) {
  return marketRequest.type ? marketRequestTypeConfig[marketRequest.type].title : '';
}

export function getMarketIncumbentText(marketRequest: SubmissionMarketRequest) {
  return marketRequest.incumbentInfo.length > 0
    ? messages.market.status.getIncumbentText(!!marketRequest.incumbentInfo[0]?.isPrimary)
    : '';
}

export interface MarketingReportRow {
  id: string;
  marketName: string;
  status: string;
  coverageLines: string;
  additionalInfo: string;
  submissionMarket: SubmissionMarket;
}

export interface MarketingReportMarketRequest extends SubmissionMarketRequest {
  marketName: string;
  marketingReportNoteOverrideType?: MarketingReportNoteOverrideType;
  coverageLines?: CoverageLine[];
  additionalInfo: string;
  submissionMarket: SubmissionMarket;
}

export function submissionMarketsToMarketingReportItems(markets: SubmissionMarket[]) {
  const marketRequests: MarketingReportMarketRequest[] = [];

  markets.forEach((market) => {
    market.marketRequests.forEach((marketRequest) => {
      const marketingReportNoteOverrideType = getMarketingReportNoteOverrideType(marketRequest);
      marketRequests.push({
        ...marketRequest,
        marketName: market.marketName,
        marketingReportNoteOverrideType,
        additionalInfo: getAdditionalInfoText(marketRequest, marketingReportNoteOverrideType) || '',
        submissionMarket: market,
        coverageLines: market.quote?.coverageLines,
      });
    });
  });

  return marketRequests.sort((a, b) => a.marketName.localeCompare(b.marketName));
}

export function isMarketingReportEnabled(markets: SubmissionMarket[]) {
  return submissionMarketsToMarketingReportItems(markets).length > 0;
}

export function isInformationRequestsReportEnabled(markets: SubmissionMarket[]) {
  return submissionMarketsToMarketingReportItems(markets).some(
    (marketRequest) => marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer,
  );
}

export function getMarketReportsRows(markets: SubmissionMarket[], isInformationRequestsReport = false) {
  const marketRequests = submissionMarketsToMarketingReportItems(markets);

  const filteredSortedMarketRequests = marketRequests
    .filter(
      (marketRequest) =>
        marketRequest.isInMarketingReport &&
        (!isInformationRequestsReport || marketRequest.status === SubmissionMarketRequestStatus.PendingCustomer),
    )
    .sort((a, b) => stringCompare(a.marketName, b.marketName));

  const MarketsGroupByStatus = groupBy(
    filteredSortedMarketRequests,
    ({ status }) => marketRequestsStatusConfig[status].sortPriority,
  );

  const selectedMarketRequestsData: MarketingReportRow[] = [];
  forEach(MarketsGroupByStatus, (marketRequestsByStatus: MarketingReportMarketRequest[]) => {
    if (!isInformationRequestsReport) {
      const statusHeaderLine = {
        id: marketRequestsStatusConfig[marketRequestsByStatus[0].status].title,
        marketName: marketRequestsStatusConfig[marketRequestsByStatus[0].status].title,
        status: '',
        coverageLines: '',
        additionalInfo: '',
      } as MarketingReportRow;
      selectedMarketRequestsData.push(statusHeaderLine);
    }

    forEach(marketRequestsByStatus, (marketRequest) => {
      const layerInfo = buildLayerTitle(marketRequest);
      const marketRequestPrimaryXSText = getMarketRequestPrimerySxText(marketRequest);
      const marketRequestIncumbentText = getMarketIncumbentText(marketRequest);
      const marketRequestLine: MarketingReportRow = {
        id: marketRequest.id!,
        marketName: `${marketRequest.marketName}${
          !isEmpty(marketRequestPrimaryXSText) || !isEmpty(marketRequestIncumbentText) ? ' - ' : ''
        }${marketRequestIncumbentText ? `\n${marketRequestIncumbentText}` : ''}${
          marketRequestPrimaryXSText ? `\n${marketRequestPrimaryXSText}` : ''
        }`,
        status: `${marketRequestsStatusConfig[marketRequestsByStatus[0].status].title}${
          layerInfo ? `\n${layerInfo}` : ''
        }`,
        coverageLines: getCoverageLinesText(marketRequest.coverageLines),
        additionalInfo: marketRequest.additionalInfo,
        submissionMarket: marketRequest.submissionMarket,
      };
      selectedMarketRequestsData.push(marketRequestLine);
    });
  });

  return selectedMarketRequestsData;
}

export function getMarketingReportTitle(isSaveMode?: boolean, isInformationRequestsMode?: boolean) {
  if (isInformationRequestsMode) {
    if (isSaveMode) {
      return messages.marketingReportPage.informationRequests.editMode.title;
    }
    return messages.marketingReportPage.informationRequests.composeMode.title;
  }
  if (isSaveMode) {
    return messages.marketingReportPage.editMode.title;
  }
  return messages.marketingReportPage.composeMode.title;
}

export function getAvailableActions(isInformationRequestsMode?: boolean) {
  if (isInformationRequestsMode) {
    return [ShareMenuAction.Copy, ShareMenuAction.Email];
  }
  return [ShareMenuAction.Copy, ShareMenuAction.Email, ShareMenuAction.Download];
}
