import { messages } from '../messages';
import { CoverageLine } from '../enums';

export interface CoverageLineConfigProps {
    text: string;
    // coverage lines that were removed but still need to be displayed in the UI for backward compatibility
    isDeleted?: boolean;
}

type CoverageLineConfig = {
    [line in CoverageLine]: CoverageLineConfigProps;
};

// exposed by util function and the end of the file, we don't expose it directly because once we want to get all the coverage lines we want it to go through the function that filters the deleted coverage lines
const coverageLineConfig: CoverageLineConfig = {
    [CoverageLine.Property]: {
        text: messages.coverageLine.property,
    },
    [CoverageLine.Casualty]: {
        text: messages.coverageLine.casualty,
    },
    [CoverageLine.ProfessionalLiability]: {
        text: messages.coverageLine.professionalLiability,
        isDeleted: true,
    },
    [CoverageLine.PublicDnO]: {
        text: messages.coverageLine.publicDnO,
    },
    [CoverageLine.PrivateDnO]: {
        text: messages.coverageLine.privateDnO,
    },
    [CoverageLine.NonProfitDnO]: {
        text: messages.coverageLine.nonProfitDnO,
    },
    [CoverageLine.EnO]: {
        text: messages.coverageLine.eno,
        isDeleted: true,
    },
    [CoverageLine.Cyber]: {
        text: messages.coverageLine.cyber,
    },
    [CoverageLine.EPL]: {
        text: messages.coverageLine.epl,
    },
    [CoverageLine.KidnapRansom]: {
        text: messages.coverageLine.KidnapRansom,
        isDeleted: true,
    },
    [CoverageLine.Crime]: {
        text: messages.coverageLine.Crime,
    },
    [CoverageLine.Fiduciary]: {
        text: messages.coverageLine.Fiduciary,
    },
    [CoverageLine.CommercialAutomobile]: {
        text: messages.coverageLine.CommercialAutomobile,
        isDeleted: true,
    },
    [CoverageLine.ExcessCommercialAuto]: {
        text: messages.coverageLine.ExcessCommercialAuto,
        isDeleted: true,
    },
    [CoverageLine.ExcessLiability]: {
        text: messages.coverageLine.ExcessLiability,
        isDeleted: true,
    },
    [CoverageLine.ExcessUmbrella]: {
        text: messages.coverageLine.ExcessUmbrella,
        isDeleted: true,
    },
    [CoverageLine.GeneralLiability]: {
        text: messages.coverageLine.GeneralLiability,
    },
    [CoverageLine.GeneralLiabilityAndPollutionLiability]: {
        text: messages.coverageLine.GeneralLiabilityAndPollutionLiability,
        isDeleted: true,
    },
    [CoverageLine.GeneralLiabilityAndProfessionalLiability]: {
        text: messages.coverageLine.GeneralLiabilityAndProfessionalLiability,
        isDeleted: true,
    },
    [CoverageLine.ProductsLiability]: {
        text: messages.coverageLine.ProductsLiability,
    },
    [CoverageLine.ProductsRecallLiability]: {
        text: messages.coverageLine.ProductsRecallLiability,
    },
    [CoverageLine.Umbrella]: { text: messages.coverageLine.Umbrella },
    [CoverageLine.WorkersCompOutOfState]: {
        text: messages.coverageLine.WorkersCompOutOfState,
        isDeleted: true,
    },
    [CoverageLine.WorkersCompensation]: {
        text: messages.coverageLine.WorkersCompensation,
    },
    [CoverageLine.PollutionLiability]: {
        text: messages.coverageLine.PollutionLiability,
        isDeleted: true,
    },
    [CoverageLine.HiredNonOwnedAuto]: {
        text: messages.coverageLine.HiredNonOwnedAuto,
    },
    [CoverageLine.MiscellaneousCommercial]: {
        text: messages.coverageLine.MiscellaneousCommercial,
        isDeleted: true,
    },
    [CoverageLine.BoatCommercial]: {
        text: messages.coverageLine.BoatCommercial,
        isDeleted: true,
    },
    [CoverageLine.GarageAndDealers]: {
        text: messages.coverageLine.GarageAndDealers,
        isDeleted: true,
    },
    [CoverageLine.MarineLiability]: {
        text: messages.coverageLine.MarineLiability,
        isDeleted: true,
    },
    [CoverageLine.ExcessMarineLiability]: {
        text: messages.coverageLine.ExcessMarineLiability,
        isDeleted: true,
    },

    [CoverageLine.BoilerAndMachinery]: {
        text: messages.coverageLine.BoilerAndMachinery,
        isDeleted: true,
    },
    [CoverageLine.BuildersRiskInstallation]: {
        text: messages.coverageLine.BuildersRiskInstallation,
        isDeleted: true,
    },
    [CoverageLine.Cargo]: { text: messages.coverageLine.Cargo, isDeleted: true },
    [CoverageLine.DICDifferenceInCondition]: {
        text: messages.coverageLine.DICDifferenceInCondition,
    },
    [CoverageLine.EarthquakeCommercial]: {
        text: messages.coverageLine.EarthquakeCommercial,
        isDeleted: true,
    },
    [CoverageLine.EquipmentFloater]: {
        text: messages.coverageLine.EquipmentFloater,
        isDeleted: true,
    },
    [CoverageLine.ExcessProperty]: {
        text: messages.coverageLine.ExcessProperty,
        isDeleted: true,
    },
    [CoverageLine.MotorTruckCargo]: {
        text: messages.coverageLine.MotorTruckCargo,
        isDeleted: true,
    },
    [CoverageLine.OceanMarine]: {
        text: messages.coverageLine.OceanMarine,
        isDeleted: true,
    },
    [CoverageLine.Terrorism]: { text: messages.coverageLine.Terrorism },
    [CoverageLine.FloodCommercial]: {
        text: messages.coverageLine.FloodCommercial,
        isDeleted: true,
    },
    [CoverageLine.ExportImportNavigationTransitOfGoodsSPECIAL]: {
        text: messages.coverageLine.ExportImportNavigationTransitOfGoodsSPECIAL,
        isDeleted: true,
    },
    [CoverageLine.ForeignPackagePolicy]: {
        text: messages.coverageLine.ForeignPackagePolicy,
    },
    [CoverageLine.BusinessAndManagementPackage]: {
        text: messages.coverageLine.BusinessAndManagementPackage,
        isDeleted: true,
    },
    [CoverageLine.DirectorsAndOfficersLiability]: {
        text: messages.coverageLine.DirectorsAndOfficersLiability,
        isDeleted: true,
    },
    [CoverageLine.ElectronicDataProcessing]: {
        text: messages.coverageLine.ElectronicDataProcessing,
        isDeleted: true,
    },
    [CoverageLine.ERISAOnCrimePolicyOrERISABond]: {
        text: messages.coverageLine.ERISAOnCrimePolicyOrERISABond,
        isDeleted: true,
    },
    [CoverageLine.ExcessDirectorsAndOfficersLiability]: {
        text: messages.coverageLine.ExcessDirectorsAndOfficersLiability,
        isDeleted: true,
    },

    [CoverageLine.CommercialPackage]: {
        text: messages.coverageLine.CommercialPackage,
        isDeleted: true,
    },

    [CoverageLine.ExcessProfessionalLiability]: {
        text: messages.coverageLine.ExcessProfessionalLiability,
        isDeleted: true,
    },
    [CoverageLine.Bond]: {
        text: messages.coverageLine.Bond,
        isDeleted: true,
    },
    [CoverageLine.BusinessOwnersPackage]: {
        text: messages.coverageLine.BusinessOwnersPackage,
    },
    [CoverageLine.EmployedLawyersLiability]: {
        text: messages.coverageLine.EmployedLawyersLiability,
    },
    [CoverageLine.MiscellaneousProfessionalLiability]: {
        text: messages.coverageLine.MiscellaneousProfessionalLiability,
    },
    [CoverageLine.AccountantsPl]: {
        text: messages.coverageLine.AccountantsPl,
    },
    [CoverageLine.ArchitectEngineersPl]: {
        text: messages.coverageLine.ArchitectEngineersPl,
    },
    [CoverageLine.ContractorsProfessional]: {
        text: messages.coverageLine.ContractorsProfessional,
    },
    [CoverageLine.EmploymentOrganizationsPl]: {
        text: messages.coverageLine.EmploymentOrganizationsPl,
    },
    [CoverageLine.InsuranceAgentsBrokersEnO]: {
        text: messages.coverageLine.InsuranceAgentsBrokersEnO,
    },
    [CoverageLine.ManufacturersEnO]: {
        text: messages.coverageLine.ManufacturersEnO,
    },
    [CoverageLine.LawyersProfessionalLiability]: {
        text: messages.coverageLine.LawyersProfessionalLiability,
    },
    [CoverageLine.FinancialAdvisorsInvestmentAdvisorsPl]: {
        text: messages.coverageLine.FinancialAdvisorsInvestmentAdvisorsPl,
    },
    [CoverageLine.MediaMultimedia]: {
        text: messages.coverageLine.MediaMultimedia,
    },
    [CoverageLine.TechnologyEnO]: {
        text: messages.coverageLine.TechnologyEnO,
    },
    [CoverageLine.FranchisorsEnO]: {
        text: messages.coverageLine.FranchisorsEnO,
    },
    [CoverageLine.Rnd]: {
        text: messages.coverageLine.Rnd,
    },
    [CoverageLine.SpecifiedProfessionalLiability]: {
        text: messages.coverageLine.SpecifiedProfessionalLiability,
        isDeleted: true,
    },
    [CoverageLine.PhysiciansMedicalMalpractice]: {
        text: messages.coverageLine.PhysiciansMedicalMalpractice,
    },
    [CoverageLine.AlliedHealthcareProfessionalLiability]: {
        text: messages.coverageLine.AlliedHealthcareProfessionalLiability,
    },
    [CoverageLine.ManagementLiability]: {
        text: messages.coverageLine.ManagementLiability,
        isDeleted: true,
    },
    [CoverageLine.PrivateEmploymentPracticesLiability]: {
        text: messages.coverageLine.PrivateEmploymentPracticesLiability,
    },
    [CoverageLine.PrivateCrime]: {
        text: messages.coverageLine.PrivateCrime,
    },
    [CoverageLine.PrivateFiduciary]: {
        text: messages.coverageLine.PrivateFiduciary,
    },
    [CoverageLine.PublicEmploymentPracticesLiability]: {
        text: messages.coverageLine.PublicEmploymentPracticesLiability,
    },
    [CoverageLine.PublicCrime]: {
        text: messages.coverageLine.PublicCrime,
    },
    [CoverageLine.PublicFiduciary]: {
        text: messages.coverageLine.PublicFiduciary,
    },
    [CoverageLine.NonProfitEmploymentPracticesLiability]: {
        text: messages.coverageLine.NonProfitEmploymentPracticesLiability,
    },
    [CoverageLine.NonProfitCrime]: {
        text: messages.coverageLine.NonProfitCrime,
    },
    [CoverageLine.NonProfitFiduciary]: {
        text: messages.coverageLine.NonProfitFiduciary,
    },
    [CoverageLine.ExcessManagementLiability]: {
        text: messages.coverageLine.ExcessManagementLiability,
        isDeleted: true,
    },
    [CoverageLine.ExcessCyber]: {
        text: messages.coverageLine.ExcessCyber,
        isDeleted: true,
    },
    [CoverageLine.EnvironmentalLiability]: {
        text: messages.coverageLine.EnvironmentalLiability,
        isDeleted: true,
    },
    [CoverageLine.EnvironmentalProfessionalsPackage]: {
        text: messages.coverageLine.EnvironmentalProfessionalsPackage,
        isDeleted: true,
    },
    [CoverageLine.ContractorsPollutionsLiability]: {
        text: messages.coverageLine.ContractorsPollutionsLiability,
    },
    [CoverageLine.StorageTankPollutionLiability]: {
        text: messages.coverageLine.StorageTankPollutionLiability,
    },
    [CoverageLine.SitePollutionLiability]: {
        text: messages.coverageLine.SitePollutionLiability,
    },
    [CoverageLine.UniqueOfferings]: {
        text: messages.coverageLine.UniqueOfferings,
        isDeleted: true,
    },
    [CoverageLine.IntellectualProperty]: {
        text: messages.coverageLine.IntellectualProperty,
    },
    [CoverageLine.SexualAbuseMolestation]: {
        text: messages.coverageLine.SexualAbuseMolestation,
    },
    [CoverageLine.KidnapRansomExecutiveProtection]: {
        text: messages.coverageLine.KidnapRansomExecutiveProtection,
    },
    [CoverageLine.TerrorismActiveAssailantShooter]: {
        text: messages.coverageLine.TerrorismActiveAssailantShooter,
    },
    [CoverageLine.AccountsReceivable]: {
        text: messages.coverageLine.AccountsReceivable,
    },
    [CoverageLine.RealEstateDevelopersPL]: {
        text: messages.coverageLine.RealEstateDevelopersPL,
    },
    [CoverageLine.TenantDiscrimination]: {
        text: messages.coverageLine.TenantDiscrimination,
    },
    [CoverageLine.EnvironmentalProfessionalsPL]: {
        text: messages.coverageLine.EnvironmentalProfessionalsPL,
    },
    [CoverageLine.MedicalBillersEnO]: {
        text: messages.coverageLine.MedicalBillersEnO,
    },
    [CoverageLine.LifeSciencesPL]: {
        text: messages.coverageLine.LifeSciencesPL,
    },
};

/**
 *
 * @param includeEvenIfDeleted - optional parameter specifying that these coverage lines should be included even if they are deleted.
 *
 */
export const getCoverageLinesConfig = (includeEvenIfDeleted?: CoverageLine[]) => {
    const coverageLines = Object.keys(coverageLineConfig).filter(
        (coverageLine) =>
            !coverageLineConfig[coverageLine as CoverageLine].isDeleted ||
            (includeEvenIfDeleted && includeEvenIfDeleted.includes(coverageLine as CoverageLine)),
    );

    return coverageLines.reduce((acc, line) => {
        acc[line as CoverageLine] = coverageLineConfig[line as CoverageLine];
        return acc;
    }, {} as CoverageLineConfig);
};

export const getCoverageLineConfig = (coverageLine: CoverageLine) => coverageLineConfig[coverageLine];
