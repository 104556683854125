import { ReactNode } from 'react';
import { ThumbUpOutlined } from '@icons';
import IconButton from 'components/IconButton';
import { Box, FormHelperText, Stack } from 'components/mui-index';

interface FormFieldValidationHelperProps {
  reason: ReactNode;
  suggestionValidationCallback?: () => void;
  showSuggestionValidation?: boolean;
}

export default function FormFieldValidationHelper({
  reason,
  suggestionValidationCallback,
  showSuggestionValidation,
}: FormFieldValidationHelperProps) {
  return (
    <Stack direction="row" justifyContent="space-between" gap={1}>
      <FormHelperText sx={{ mx: 0 }}>{reason}</FormHelperText>
      {showSuggestionValidation && (
        <Box className="suggestion-validation-button" pt={0.5}>
          <IconButton
            icon={ThumbUpOutlined}
            aria-label="suggestion-validation"
            onClick={suggestionValidationCallback}
            edge="end"
            color="secondary"
            bgcolor="green.300"
            variant="contained"
            size="small"
          />
        </Box>
      )}
    </Stack>
  );
}
