import { Stack, Tooltip, Typography } from '@common-components';
import Chip from 'components/Chip';

interface FormFieldOverlayProps {
  text: string;
  onClick: () => void;
  tooltipText?: string;
  bgColor: string;
  labelBgColor: string;
  labelText: string;
  labelTextColor: string;
  edge?: 'end';
}

export default function FormFieldOverlay({
  text,
  onClick,
  tooltipText,
  labelText,
  bgColor,
  labelBgColor,
  labelTextColor,
  edge,
}: FormFieldOverlayProps) {
  return (
    <Stack
      direction="row"
      p={2}
      pr={edge === 'end' ? 1 : 2}
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      right={0}
      top={0}
      left={0}
      bottom={0}
      bgcolor={bgColor}
      borderRadius={1}
      border={1}
      borderColor="divider"
      onClick={onClick}
    >
      <Typography variant="body1" color="inherit" noWrap>
        {text}
      </Typography>
      <Stack direction="row" gap={1} alignItems="center">
        <Tooltip tooltipContent={tooltipText ?? ''} enabled={!!tooltipText} placement="top">
          <Chip label={labelText} size="small" color={labelBgColor} textColor={labelTextColor} />
        </Tooltip>
      </Stack>
    </Stack>
  );
}
